import IProdutoCard from "../../interfaces/produto/produtoCardColacril"
import ProdutoCard from "./produto-card"
import React from "react";


function ProductList(props: {produtos:IProdutoCard[]}) {
    var produtos = props.produtos;

    return (
        <div className="product-list">
            {produtos.map((item, index) => {
                return (
                    <ProdutoCard
                        idProduto={item.idProduto}
                        key={index}
                        codigo={item.codigo}
                        nome={item.nome}
                        forma={item.forma}
                        imagens={item.imagens}
                        items={item.items}
                        carreira={item.carreira}
                        dimensao={item.dimensao}
                        espessura={item.espessura}
                    />
                )
            })}
        </div>
    )
}

export default ProductList