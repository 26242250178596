import React, { useState } from 'react';
import { Button, Checkbox, DialogProps, FormControl, FormControlLabel, FormGroup, Grid, InputAdornment, MenuItem, Select, TextField } from '@mui/material';
import { CloudUploadOutlined } from '@mui/icons-material';
import { useConfigContext } from '../../context/configContext';
import InputMask from "react-input-mask";
import { IFormTrabalheConosco } from '../../interfaces/form/form-trabalhe-conosco';
import { Trans, useTranslation } from 'react-i18next';
import ModalMensagensGerais from './modalMensagensGerais';
import PoliticaDePrivacidade from './politicaDePrivacidade';
import contatoService from '../../services/contatoService';


// TODO: Verificar values com a arquitetura

{/*Array dos valores que serão apresentados em Área*/ }
const opcoes = [
	{
		value: 1,
		label: 'Administração',
	}, {
		value: 2,
		label: 'Administração de vendas',
	}, {
		value: 3,
		label: 'Almoxarifado',
	}, {
		value: 4,
		label: 'Assistência técnica',
	}, {
		value: 5,
		label: 'Atendimento ao cliente',
	}, {
		value: 6,
		label: 'Comercial',
	}, {
		value: 7,
		label: 'Comércio exterior',
	}, {
		value: 8,
		label: 'Compras',
	}, {
		value: 9,
		label: 'Controladora',
	}, {
		value: 10,
		label: 'Controle de qualidade',
	}, {
		value: 11,
		label: 'Expedição',
	}, {
		value: 12,
		label: 'Faturamento',
	}, {
		value: 13,
		label: 'Financeiro',
	}, {
		value: 14,
		label: 'Fiscal',
	}, {
		value: 15,
		label: 'Industrial',
	}, {
		value: 16,
		label: 'Jurídico',
	}, {
		value: 17,
		label: 'Laboratório',
	}, {
		value: 18,
		label: 'Logística',
	}, {
		value: 19,
		label: 'Manutenção',
	}, {
		value: 20,
		label: 'Marketing',
	}, {
		value: 21,
		label: 'PCP',
	}, {
		value: 22,
		label: 'Produção',
	}, {
		value: 23,
		label: 'Recebimento',
	}, {
		value: 24,
		label: 'Recepção',
	}, {
		value: 25,
		label: 'RH',
	}, {
		value: 26,
		label: 'Segurança do trabalho',
	}, {
		value: 27,
		label: 'TI',
	}
];


function TrabalheConosco(props: { modifyActiveComponent: Function }) {
	// TODO: Receber dados corretos
	const [fileName, setFileName] = useState<string>();
	const [validEmail, setValidEmail] = useState<boolean>(true);
	const [validTelefone, setValidTelefone] = useState<boolean>(true);

	const clientConfig = useConfigContext();

	const [open, setOpen] = React.useState(false);
	const [openTP, setOpenTP] = React.useState(false);

	const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

	const handleOpen = () => setOpen(true);
	const handleClose = () => setOpen(false);

	const handleOpenTP = () => setOpenTP(true);
	const handleCloseTP = () => setOpenTP(false);

	const [scroll, setScroll] = React.useState<DialogProps['scroll']>('paper');

	const { t } = useTranslation('contato')

	const [form, setForm] = useState<IFormTrabalheConosco>({
		nome: '',
		email: '',
		telefone: '',
		pais: '',
		estado: '',
		cidade: '',
		curriculo: undefined,
		mensagem: '',
		checkboxTermos: false,
		checkboxContatoTelefone: false,
		checkboxContatoEmail: false,
		area: '',
	})

	const [errors, setErrors] = useState({
		area: false,
		nome: false,
		estado: false,
		cidade: false,
		mensagem: false,
		pais: false,
		curriculo: false
	})

	function validateFields() {
		let errorExists = false;

		setErrors({
			area: false,
			nome: false,
			estado: false,
			cidade: false,
			mensagem: false,
			pais: false,
			curriculo: false
		})

		if (!form.area) {
			setErrors(prevErrors => ({ ...prevErrors, area: true }))
			errorExists = true;
		}

		if (!form.nome) {
			setErrors(prevErrors => ({ ...prevErrors, nome: true }))
			errorExists = true;
		}

		if (!form.estado) {
			setErrors(prevErrors => ({ ...prevErrors, estado: true }))
			errorExists = true;
		}

		if (!form.cidade) {
			setErrors(prevErrors => ({ ...prevErrors, cidade: true }))
			errorExists = true;
		}

		if (!form.pais) {
			setErrors(prevErrors => ({ ...prevErrors, pais: true }))
			errorExists = true;
		}

		if (!form.mensagem) {
			setErrors(prevErrors => ({ ...prevErrors, mensagem: true }))
			errorExists = true;
		}

		if (!form.curriculo) {
			setErrors(prevErrors => ({ ...prevErrors, curriculo: true }))
			errorExists = true;
		}

		return errorExists;
	}

	function eventoSubmit(e: React.FormEvent<HTMLFormElement>) {
		e.preventDefault()
		var errorExists = validateFields()
		if (!errorExists) {
			setIsSubmitting(true)
			contatoService.submitTrabalheConosco(form, clientConfig.apiUrl).then((resp) => {
				setIsSubmitting(false)
				props.modifyActiveComponent("submitedTc")
			}).catch((err) => {
			})

		}
	}


	function validation(fieldName: string, value: string) {
		if (fieldName == 'email') {
			//Responsável por verificar se o e-mail é válido
			var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

			if (value.match(validRegex)) {
				setValidEmail(true)
				return true;
			} else {
				setValidEmail(false)
				return false;
			}
		} else if (fieldName == 'telefone') {
			//Responsável por verificar se o telefone é válido
			var validRegex = /(?:\(([1-9][0-9])\)?\s?)?(?:((?:9\d|[2-9])\d{3})\-?(\d{4}))$/;

			if (value.match(validRegex)) {
				setValidTelefone(true)
				return true;
			} else {
				setValidTelefone(false)
				return false;
			}
		}
	}


	const handleInputChange = (e: any) => {
		setForm({ ...form, [e.target.name]: e.target.value });
	};

	const handleCheckboxChange = (e: any) => {
		setForm({ ...form, [e.target.name]: e.target.checked });
	};

	function fileEvent(fileInput: HTMLInputElement) {
		if (fileInput.files !== null) {
			let file = fileInput.files[0]
			setFileName(file.name);
			setForm({ ...form, [fileInput.name]: file });
		}
	}



	return (

		<div className='form-contato-container'>

			{/*Parágrafo principal*/}
			<p className='form-contato-initial-paragraph'>
				{t("Tem interesse em trabalhar conosco? Faça parte você também do nosso time de colaboradores. Preencha o formulário com seus dados e experiência profissional  para que a área responsável possa avaliar sua candidatura.")}
			</p>

			<form onSubmit={(e) => eventoSubmit(e)}>
				{/*Início dos inputs do formulário*/}
				<Grid container spacing={2}>
					{/*Área com map de arrays para exibir os valores*/}
					<Grid item xs={12}>
						<FormControl fullWidth>
							{/* <InputLabel id="area-input-label">Área em que deseja trabalhar:</InputLabel> */}
							<Select
								value={form.area}
								id="area"
								//label="Área em que deseja trabalhar: "
								name='area'
								onChange={handleInputChange}
								className="form-contato-select"
								MenuProps={{
									disablePortal: true,
								}}
								error={errors.area}
								startAdornment={
									<InputAdornment
										position='start'
										disablePointerEvents
										className="trabalhe-conosco-area-adornment">
										<div>{t("Área em que deseja trabalhar: ")}</div>
									</InputAdornment>
								}
							>
								{opcoes.map((option) => (
									<MenuItem key={option.value} value={option.value}>
										{t(`${option.label}`)}
									</MenuItem>
								))}
							</Select>
						</FormControl>
					</Grid>

					{/*Nome*/}
					<Grid item xs={12}>
						<TextField
							fullWidth
							id="nome"
							type="text"
							name="nome"
							aria-label="Nome: "
							variant="outlined"
							onChange={handleInputChange}
							error={errors.nome}
							InputProps={{
								startAdornment: (
									<InputAdornment position="start" disablePointerEvents>
										<span className='form-label'>
											{t("Nome: ")}
										</span>

									</InputAdornment>
								),
							}}
						/>
					</Grid>

					{/*E-mail e telefone*/}
					<Grid item container spacing={1}>
						<Grid item xs={12} sm={8}>
							<TextField
								fullWidth
								id="email"
								type="email"
								name="email"
								aria-label="E-mail: "
								variant="outlined"

								onChange={handleInputChange}
								error={!validEmail} onBlur={(e) => validation("email", form.email)}
								InputProps={{
									startAdornment: (
										<InputAdornment position="start" disablePointerEvents>
											<span className='form-label'>
												{t("E-mail: ")}
											</span>

										</InputAdornment>
									),
								}}
							/>
						</Grid>

						<Grid item xs={12} sm={4}>
							<InputMask
								mask={"(99) 99999-9999"}
								onBlur={(e) => validation("telefone", form.telefone)}
								onChange={handleInputChange}
							>
								{() => (
									<TextField
										fullWidth
										id="telefone"
										type="tel"
										name="telefone"
										aria-label="Telefone: "
										variant="outlined"
										error={!validTelefone}
										InputProps={{
											startAdornment: (
												<InputAdornment position="start" disablePointerEvents>
													<span className='form-label'>
														{t("Telefone: ")}
													</span>

												</InputAdornment>
											),
										}}
									/>
								)}
							</InputMask>
						</Grid>
					</Grid>


					{/*País, estado e cidade --> Grid*/}
					<Grid item container spacing={1}>
						<Grid item xs={12} sm={4}>
							<TextField
								fullWidth
								id="pais"
								type="text"
								name="pais"
								aria-label="País: "
								variant="outlined"
								onChange={handleInputChange}
								error={errors.pais}
								InputProps={{
									startAdornment: (
										<InputAdornment position="start" disablePointerEvents>
											<span className='form-label'>
												{t("País: ")}
											</span>

										</InputAdornment>
									),
								}}
							/>
						</Grid>

						<Grid item xs={12} sm={4}>
							<TextField
								fullWidth
								id="estado"
								type="text"
								name="estado"
								aria-label="Estado: "
								variant="outlined"
								onChange={handleInputChange}
								error={errors.estado}
								InputProps={{
									startAdornment: (
										<InputAdornment position="start" disablePointerEvents>
											<span className='form-label'>
												{t("Estado: ")}
											</span>

										</InputAdornment>
									),
								}}
							/>
						</Grid>

						<Grid item xs={12} sm={4}>
							<TextField
								fullWidth
								id="cidade"
								type="text"
								name="cidade"
								aria-label="Cidade: "
								variant="outlined"
								onChange={handleInputChange}
								error={errors.cidade}
								InputProps={{
									startAdornment: (
										<InputAdornment position="start" disablePointerEvents>
											<span className='form-label'>
												{t("Cidade: ")}
											</span>

										</InputAdornment>
									),
								}}
							/>
						</Grid>
					</Grid>


					{/*Anexar currículo - file input */}
					<Grid item container>
						<input
							accept=".doc, .docx, .pdf, .odt"
							id="contained-button-file"
							type="file"
							onChange={(e) => fileEvent(e.target)}
							name='curriculo'
							className='trabalhe-conosco-input-curriculo'
						/>

						<Grid item xs={12} sm={7} md={8}>
							<TextField
								className='enviar-formulario-input'
								disabled
								helperText='Formatos .doc, .docx, .pdf ou .odt - máx 2mb.'
								value={fileName}
								// placeholder={t("Anexar currículo: ")}
								error={errors.curriculo}
								InputProps={{
									startAdornment: (
										<InputAdornment position="start" disablePointerEvents>
											<span className='form-label'>
												{t("Anexar currículo: ")}
											</span>

										</InputAdornment>
									),
								}}
								fullWidth
							/>
						</Grid>

						<Grid item xs={12} sm={5} md={4}>
							<label htmlFor="contained-button-file">
								<Button
									className='enviar-formulario-button btn-contained-secondary'
									variant="contained"
									component="span"
									endIcon={<CloudUploadOutlined />}
									fullWidth
									disableElevation
								>
									{t("Procurar")}
								</Button>
							</label>
						</Grid>
					</Grid>

					{/*Mensagem*/}
					<Grid item xs={12}>
						<TextField
							fullWidth
							id="mensagem"
							type="text"
							name="mensagem"
							aria-label="Mensagem: "
							variant="outlined"
							multiline
							rows={6}
							error={errors.mensagem}
							onChange={handleInputChange}
							className="form-contato-text-area"
							InputProps={{
								startAdornment: (
									<InputAdornment
										position='start'
										disablePointerEvents
										className="trabalhe-conosco-mensagem-adornment">

										<div>{t("Mensagem: ")}</div>

									</InputAdornment>
								),
							}}
						/>
					</Grid>

					{/*Final dos inputs do formulário*/}
				</Grid>

				{/*Botões para entrar em contato*/}
				<FormGroup className='form-contato-options'>
					<p className='form-contato-options_paragraph'>{t("Podemos entrar em contato com você por: ")}</p>

					<FormControlLabel
						control={
							<Checkbox
								value='telefone'
								name='checkboxContatoTelefone'
								onChange={handleCheckboxChange}
							/>
						}
						label={
							<Trans t={t} i18nKey="Telefone">
								Telefone
							</Trans>
						}
					/>

					<FormControlLabel
						control={
							<Checkbox
								value='email'
								name='checkboxContatoEmail'
								onChange={handleCheckboxChange}
							/>
						}
						label={
							<Trans t={t} i18nKey="E-mail">
								E-mail
							</Trans>
						}
					/>
				</FormGroup>

				{/*Botões para aceitar os termos do site e política de privacidade*/}
				<div>
					<FormGroup>
						<FormControlLabel
							control={
								<Checkbox
									required
									className='fc-final-checkbox'
									name='checkboxTermos'
									onChange={handleCheckboxChange}
								/>
							}
							label={""}
						/>
					</FormGroup>

					<Trans t={t} i18nKey="Declaro aceitar os termos do site e a política de privacidade.">
						<p className='fc-final-checkbox-label'>Declaro aceitar os <b className="contact-modal-pointer" onClick={handleOpenTP}> termos do site e a política de privacidade </b>.</p>
					</Trans>
				</div>

				{/* Botão para aceitar o termo de consentimento */}
				<div>
					<FormGroup>
						<FormControlLabel
							control={
								<Checkbox
									required
									className='fc-final-checkbox'
									name='checkboxTermos'
									onChange={handleCheckboxChange}
								/>
							}
							label={""}
						/>
					</FormGroup>

					<Trans t={t} i18nKey="Declaro aceitar os termos de consentimento para tratamento dos dados.">
						<p className='fc-final-checkbox-label'>Declaro aceitar os <b className="contact-modal-pointer" onClick={handleOpen}>termos de consentimento</b> para tratamento dos dados.</p>
					</Trans>
				</div>

				{/*Submit button*/}
				<div className='div-form-contato-submit-button'>
					<Button
						type="submit"
						variant="outlined"
						className='btn btn-outlined-secondary form-contato-submit-button'
						disabled={isSubmitting}
					>
						{isSubmitting ? 'Enviando...' : t("Enviar")}
					</Button>
				</div>
			</form>

			<ModalMensagensGerais
				scroll={scroll}
				handleClose={handleClose}
				open={open}
			/>

			<PoliticaDePrivacidade
				scroll={scroll}
				handleClose={handleCloseTP}
				open={openTP}
			/>
		</div>
	);
}

export default TrabalheConosco;