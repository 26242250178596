import React from 'react'
import { createContext, useContext } from 'react';
import ConfigContextData from './configContextData';

export interface ConfigContextProvider {
    config: ConfigContextData;
    update: (data: ConfigContextData) => any;
    
}

const ConfigContext = createContext<ConfigContextData>({
    apiUrl: '',
    cspHeader: '',
    appVersion: ''
});

export const useConfigContext = () => useContext<ConfigContextData>(ConfigContext);

export interface ConfigContextProps {
    config: ConfigContextData
    children: React.ReactElement
}

const ConfigContextProvider: React.FunctionComponent<ConfigContextProps> = (props) => {
    return <ConfigContext.Provider value={props.config}>{props.children}</ConfigContext.Provider>;
};

export default ConfigContextProvider;
