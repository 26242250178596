import { Fonts, useBoardContext, useKeysCombinationHandler, usePropertyContext } from "@beontag/tageditor";
import { IconButton, MenuItem, Select, Stack, TextField } from "@mui/material";
import { useEffect } from "react";

import BoldIcon from '@mui/icons-material/FormatBold';
import ItalicIcon from '@mui/icons-material/FormatItalic';
import UnderlineIcon from '@mui/icons-material/FormatUnderlined';
import OverlineIcon from '@mui/icons-material/FormatOverline';
import StrikeIcon from '@mui/icons-material/StrikethroughS';

import OpenWithIcon from '@mui/icons-material/OpenWith';
import CloseIcon from '@mui/icons-material/Close';
import React from "react";

const Numeration: React.FunctionComponent = () => {
  // @ts-ignore
  const { properties, setProperty } = usePropertyContext<fabric.SequentialText>();

  const keysPressCountBold = useKeysCombinationHandler(["Control", "b"]);

  useEffect(() => {
    if (keysPressCountBold) {
      fontWeightToggle();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keysPressCountBold]);

  const keysPressCountItalic = useKeysCombinationHandler(["Control", "i"]);
  useEffect(() => {
    if (keysPressCountItalic) {
      fontStyleToggle();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keysPressCountItalic]);

  const fontWeightToggle = () => {
    setProperty({ fontWeight: properties.fontWeight === "normal" ? "bold" : "normal" });
  }

  const fontStyleToggle = () => {
    setProperty({ fontStyle: properties.fontStyle === "normal" ? "italic" : "normal" });
  }

  const handleOnChange = (key: string, rawValue: string | boolean, type?: 'int') => {
    let value: string | boolean | number = rawValue;

    if (type === 'int' && typeof rawValue === 'string') {
      value = Number.parseInt(rawValue);
    }

    setProperty({ [key]: value });
  }

  const { board } = useBoardContext()

    const closeWindow = () => {
		board?.setActiveObjects()
	}


  return (
    <>
      <div className="property-bar-title handle">
                            Propriedades do objeto
                            <OpenWithIcon className="icon left" />
                            <IconButton onClick={() => closeWindow()} className="icon right not-draggable">
                                <CloseIcon />
                            </IconButton>
                        </div>

      <div className="property-bar-itens">
        <label htmlFor="texto-cor-do-objeto" className="property-bar-pointer-label">Cor do Objeto</label>
        <input
          id="texto-cor-do-objeto"
          className="property-bar-color-input"
          type="color"
          value={properties.fill?.toString() || "#000"}
          onChange={(e) => handleOnChange("fill", e.currentTarget.value)}
        ></input>
      </div>

      <div className="property-bar-itens">
        <label htmlFor="texto-cor-da-borda" className="property-bar-pointer-label">Cor da Borda</label>
        <input
          id="texto-cor-da-borda"
          className="property-bar-color-input"
          type="color"
          value={properties.stroke?.toString() || "#000"}
          onChange={(e) => handleOnChange("stroke", e.currentTarget.value)}
        ></input>
      </div>


      <div className="property-bar-itens">
        <span>Inicio:</span>
        <TextField

          className="input-main-width"
          type="number"
          InputProps={{ inputProps: { step: 1 } }}
          value={properties.sequenceStarts}
          onChange={(e) => handleOnChange("sequenceStarts", e.currentTarget.value, 'int')}
          variant="outlined"
        />

      </div>
      <div className="property-bar-itens">
        <span>Fim:</span>
        <TextField

          className="input-main-width"
          type="number"
          InputProps={{ inputProps: { step: 1 } }}
          value={properties.sequenceEnds}
          onChange={(e) => handleOnChange("sequenceEnds", e.currentTarget.value, 'int')}
          variant="outlined"
        />

      </div>
      <div className="property-bar-itens">
        <span>Incremento:</span>
        <TextField

          className="input-main-width"
          type="number"
          InputProps={{ inputProps: { step: 1 } }}
          value={properties.sequenceIncrement}
          onChange={(e) => handleOnChange("sequenceIncrement", e.currentTarget.value, 'int')}
          variant="outlined"
        />

      </div>
      <div className="property-bar-itens">
        <span>Prefixo:</span>
        <TextField

          className="input-main-width"
          type="text"
          value={properties.sequencePrefix}
          onChange={(e) => handleOnChange("sequencePrefix", e.currentTarget.value)}
          variant="outlined"
        />

      </div>
      <div className="property-bar-itens">
        <span>Sufixo:</span>
        <TextField

          className="input-main-width"
          type="text"
          value={properties.sequenceSuffix}
          onChange={(e) => handleOnChange("sequenceSuffix", e.currentTarget.value)}
          variant="outlined"
        />

      </div>
      <div className="property-bar-itens">
        <span>Fonte</span>
        <Select
          value={properties.fontFamily || "Times New Roman"}
          onChange={(e) => handleOnChange("fontFamily", e.target.value)}
        >
          {
            Fonts.getList().map((font, index) => {
              return <MenuItem value={font} key={index}> {font} </MenuItem>
            })
          }
        </Select>
      </div>
      <div className="property-bar-itens">
        <span>Espessura da borda</span>
        <TextField
          fullWidth
          className="property-box-input-width"
          type="number"
          InputProps={{ inputProps: { min: 0, step: 1 } }}
          value={properties.strokeWidth?.toString() || 0}
          // @ts-ignore
          onChange={(e) => handleOnChange("strokeWidth", +e.currentTarget.value)}
          variant="outlined"
        />

      </div>

      <div className="property-bar-itens">
        <span>Tamanho da fonte</span>
        <TextField
          fullWidth
          className="property-box-input-width"
          type="number"
          InputProps={{ inputProps: { min: 0, step: 1 } }}
          value={properties.fontSize || 15}
          onChange={(e) => handleOnChange("fontSize", e.currentTarget.value)}
          variant="outlined"
        />

      </div>

      <Stack direction='row' className="property-bar-text-options">
        <IconButton
          aria-label="Negrito"
          size="large"
          onClick={fontWeightToggle}
          className={`property-bar-text-options-button ${properties.fontWeight == 'bold' ? 'active' : ''}`}
        >
          <BoldIcon />
        </IconButton>

        <IconButton
          aria-label="Itálico"
          size="large"
          onClick={fontStyleToggle}
          className={`property-bar-text-options-button ${properties.fontStyle == 'italic' ? 'active' : ''}`}
        >
          <ItalicIcon />
        </IconButton>

        <IconButton
          aria-label="Sublinhado"
          size="large"
          onClick={() => {
            if (properties.underline) {
              handleOnChange("underline", false);
            } else {
              handleOnChange("underline", true)
            }
          }
          }
          className={`property-bar-text-options-button ${properties.underline ? 'active' : ''}`}>
          <UnderlineIcon />
        </IconButton>

        <IconButton
          aria-label="Sobrelinha"
          size="large"
          onClick={() => {
            if (properties.overline) {
              handleOnChange("overline", false);
            } else {
              handleOnChange("overline", true)
            }
          }}
          className={`property-bar-text-options-button ${properties.overline ? 'active' : ''}`}>
          <OverlineIcon />
        </IconButton>

        <IconButton
          aria-label="Tachado"
          size="large"
          onClick={() => {
            if (properties.linethrough) {
              handleOnChange("linethrough", false);
            } else {
              handleOnChange("linethrough", true)
            }
          }}
          className={`property-bar-text-options-button ${properties.linethrough ? 'active' : ''}`}>
          <StrikeIcon />
        </IconButton>
      </Stack>
    </>
  );
};

export default Numeration;
