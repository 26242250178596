import ContentHeaderImage from '../../../components/common/content-header-image'
import CardOverCard from '../../../components/common/card-over-card';

import download_catalogo from '../../../assets/Download_catalogo.webp'
import download_gabarito from '../../../assets/Download_gabarito.webp'

import bg_image from '../../../assets/pattern_colacril_downloads.webp'
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import React from 'react';

function Downloads() {

  const { t } = useTranslation('downloads')

  const location = useLocation()


  return (
      <>
        <div className='downloads-header'>
          <ContentHeaderImage
            titulo={t("Downloads")}
            subtitulo={t("Aqui, você encontra materiais para saber mais sobre os nossos produtos e software,  de acordo com o que você precisa.")}
            image={bg_image}
          >
            <div className="shape-divider-downloads-header-bottom">
              <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                  <path d="M 741 116.23 C 291 117.43 0 27.57 -3 33 V 120 H 1200 V 59 C 1200 27.93 1186.4 119.83 741 116.23 Z" className="shape-fill"></path>
              </svg>
            </div>
          </ContentHeaderImage>
        </div>

        <CardOverCard
          imageLast={true}
          image={download_gabarito}
          text={t("Para facilitar a impressão de suas etiquetas, disponibilizamos os parametros de impressão.")}
          header={t("Parâmetros de impressão")}
          // TODO: REFATORAR NOME DE ESTILOS
          styleType='downloads'
          buttonText={t("Baixe aqui") || ""}
          buttonActionLink={`/documents/xls/parametros_colacril_office.xls`}
        />



        <CardOverCard
          imageLast={false}
          image={download_catalogo}
          text={t("Buscando o melhor produto para sua necessidade? Baixe nosso catálogo, conheça nossas categorias e encontre o produto ideal para você!")}
          header={t("Catálogo de produtos")}
          // TODO: REFATORAR NOME DE ESTILOS
          styleType='landing-page'
          buttonText={t("Baixe agora") || ""}
          buttonActionLink={`/documents/pdf/catalogo_colacril_office.pdf`}
        />
      </>  
  );
}

export default Downloads;