import { Trans, useTranslation } from "react-i18next";
import AltText from "../../components/common/altText";
import React from "react";

export default function TelaAgradecimentoTc() {
    const { t } = useTranslation('contato')

    return (
        <div className="tela-agradecimento-container">
            <Trans t={t} i18nKey="Sua mensagem foi enviada com sucesso!">
                <h1 className="tela-agradecimento-titulo">
                    Sua <AltText>mensagem</AltText> foi enviada com sucesso!
                </h1>
            </Trans>
            
            <p className="tela-agradecimento-text">
                Agradecemos sua mensagem! Iremos analisar as informações enviadas  e entraremos em contato caso corresponda a alguma oportunidade.
            </p>
        </div>
    )
}