import { Trans, useTranslation } from 'react-i18next';

import header_image from '../../../../assets/header_resp_socioamb.webp'
import hand_bulb from '../../../../assets/hand_holding_resp_socioamb.webp'
import CardOverCard from '../../../../components/common/card-over-card';
import AltText from '../../../../components/common/altText';

import React from 'react';



function RespSocioamb() {
    const { t } = useTranslation('aEmpresa')

    return (
        <>
            <div className='content-header-container'>
                <img alt="" src={header_image} className="content-header-image" />

                <div className='content-header-image-text content-header-resp-socioamb'>
                    <Trans t={t} i18nKey="Responsabilidade Socioambiental">
                        <h1> Responsabilidade <AltText>Socioambiental</AltText> </h1>
                    </Trans>
                </div>

                <div className="shape-divider-bottom-header-contato">
                    <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                        <path d="M1200 120L0 16.48 0 0 1200 0 1200 120z" className="shape-fill"></path>
                    </svg>
                </div>
            </div>

            <h1 className="page-title"></h1>

            <div className='page-content empresa-content'>
                {/* Imagem aqui */}

                <p className='page-paragraph'>
                    {t("Na Colacril, todas as etapas do processo produtivo levam em consideração aspectos sociais e ambientais. Isso significa que tão importante quanto a entrega de um produto de alta qualidade para o seu projeto é a responsabilidade que assumimos com quem contribui direta e/ou indiretamente para a nossa presença no mercado. Do incentivo a projetos de desenvolvimento sustentável, passando por ações em comunidades locais.")}
                </p>

                <p className='page-paragraph'>
                    {t("A Colacril além de um controle robusto de emissão de poluentes, consumo de água, gás e energia elétrica, também mantém uma agenda anual na qual trata temas de extrema relevância, tais como: ")}
                </p>

                <ul>
                    <li>
                            {t("Dia Mundial da Água, onde conscientizamos nossos colaboradores para um consumo racional e sem desperdícios e também incentivamos a doação de água mineral para hospitais e/ou instituições de caridade;")}
                    </li>
                    <li>
                            {t("Dia Nacional da Conservação do Solo, onde conscientizamos nossos colaboradores para a conservação do solo, evitando queimadas e quaisquer ações que cause a degradação do mesmo, causando possíveis erosões devido ação das chuvas;")}
                    </li>
                    <li>
                            {t("Semana do Meio Ambiente, onde passamos a semana inteira conscientizando nossos colaboradores para preservação e proteção do meio ambiente, promovendo palestras e discussões diárias sobre os diversos temas relacionados ao meio ambiente;")}
                    </li>
                    <li>
                            {t("Dia da Árvore, onde incentivamos nossos colaboradores no plantio e cultivo de árvores, visando o bem estar de todos seres vivos e contribuindo de forma significativa para redução do aquecimento global;")}
                    </li>
                    <li>
                            {t("Dia Mundial da Qualidade, onde passamos uma semana inteira conscientizando nossos colaboradores para manutenção da qualidade dos nossos produtos, promovendo palestras e discussões diária sobre os diversos temas relacionados a qualidade;")}
                    </li>
                    <li>
                            {t("Dia do Cliente, onde conscientizamos nossos colaboradores sobre a importância de atender bem nossos clientes, garantindo assim o crescimento dos nossos negócios gerando renda para comunidade local, mantendo e criando empregos diretos e indiretos.")}
                    </li>
                    <li>
                            {t("Outras campanhas também são realizadas, tais como arrecadação de alimentos, água de coco, produtos de limpeza e campanha de agasalhos que são distribuídos para hospitais, comunidade local e/ou instituições de caridade.")}
                    </li>
                    <li>
                            {t("No ano de 2020 no início da pior crise sanitária e humanitária que nossa geração já presenciou a Beontag contribuiu de forma significativa com valores em espécie para ampliação de novos leitos na cidade, salvando vidas e amenizando o sofrimento da comunidade local.")}
                    </li>
                    <li>
                            {t("Nossos processos de redução, reutilização e reciclagem são efetivos, garantindo a sustentabilidade dos nossos negócios, mantendo a saúde financeira da nossa empresa, gerando valor para nossos clientes, emprego e renda para comunidade local onde atuamos.")}
                    </li>
                </ul>
            </div>

            <CardOverCard
                imageLast={true}
                image={hand_bulb}
                text={t("'A organização busca permanentemente a estabilidade dos processos e produtos, através de indicadores de desempenho, que norteiam nosso caminho e nos dão direção para o aperfeiçoamento dos nossos processos de melhoria contínua, qualidade, segurança, atendimento, responsabilidade ambiental, responsabilidade social, produtividade e custos, para isso é estratégia da organização manter e assegurar o cumprimento das seguintes diretrizes': ")}
                header={t("POLÍTICA DA GESTÃO INTEGRADA")}
                // TODO: REFATORAR NOME DE ESTILOS
                styleType='downloads'
                buttonActionLink='#'
            />

            <div className='page-content empresa-content'>
                <ul>
                    <li>
                        {t("Promover melhoria contínua nos processos produtivos e de qualidade, mediante ao uso de indicadores de desempenho;")}
                    </li>

                    <li>
                        {t("Reduzir os riscos na realização das operações com ações voltadas a prevenção de acidentes, objetivando a preservação da saúde e da integridade física dos nossos colaboradores;")}
                    </li>

                    <li>
                        {t("Satisfazer nossos clientes, bem como os clientes dos nossos clientes;")}
                    </li>

                    <li>
                        {t("Atender à legislação aplicável e outros requisitos pertinentes;")}
                    </li>

                    <li>
                        {t("Gerenciar os aspectos e impactos ambientais e reduzir os resíduos gerados, visando à proteção do meio ambiente;")}
                    </li>

                    <li>
                        {t("Promover ações sociais que fortaleçam nossa relação com a comunidade e partes interessadas;")}
                    </li>

                    <li>
                        {t("Gerenciar e reduzir os desperdícios dos processos, afim de garantir o resultado econômico financeiro da companhia.")}
                    </li>
                </ul>
            </div>

            <div className='empresa-missao-valores-container'>
                <div className='page-content empresa-content'>
                    <h2 className='sub-page-title'><AltText>{t("NOSSOS PILARES")}</AltText></h2>

                    <div className='empresa-missao-valores-group-container'>
                        <p className='page-paragraph'>
                            {t("Sem Fronteiras")}
                        </p>

                        <p className='page-paragraph'>
                            {t("Fazemos acontecer")}
                        </p>

                        <p className='page-paragraph'>
                            {t("Juntos")}
                        </p>
                    </div>
                    

                    <h2 className='sub-page-title'><AltText>{t("NOSSO PROPÓSITO")}</AltText></h2>

                    <p className='page-paragraph'>
                        {t("Empoderar empresas, marcas e pessoas para que imprimam no mundo sua identidade única.")}
                    </p>

                    <h2 className='sub-page-title'><AltText>{t("NOSSA VISÃO")}</AltText></h2>

                    <p className='page-paragraph'>
                        {t("Seremos líderes no mercado de identificação, atuando sem fronteiras, com pensamento crítico e calor humano.")}
                    </p>

                    <div className="shape-divider-empresa-missao-valores">
                        <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                            <path d="M0,0V6c0,21.6,291,111.46,741,110.26,445.39,3.6,459-88.3,459-110.26V0Z" className="shape-fill"></path>
                        </svg>
                    </div>
                </div>
            </div>

            <div className='page-content empresa-content'>
                <h2 className='sub-page-title'>{t("ESCOPO DO SISTEMA DE GESTÃO DA QUALIDADE E AMBIENTAL")}</h2>

                <p className='page-paragraph'>
                    {t("Desenvolvimento, Produção e Comercialização de Papéis, Filmes, Etiquetas Auto Adesivas, Soluções de Identificação por Radiofrequência – RFID, Antena Inlay para cartões Dual Interface e Encapsulamento de Chip.")}
                </p>

                <p className='page-paragraph'>
                    {t("Rev.: 01 Data: 16/12/2021.")}
                </p>
            </div>
        </>
    );
}

export default RespSocioamb;
