import { Alert, Dialog, DialogContent, DialogTitle, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import React from 'react';

const PWAInstall = (props: {
  open: boolean,
  handleCloseModal: Function,
  buttonRef: React.MutableRefObject<null>,
  children: JSX.Element
}) => {

  let isChromium = false;

  if (typeof window !== 'undefined' && navigator && navigator.userAgent && navigator.userAgent.indexOf("Chrome") != -1 ) {
    isChromium = true;
  }

  return (
    <>
      <Dialog
        open={props.open}
        onClose={() => props.handleCloseModal()}
        scroll='paper'
        maxWidth={'md'}
        >
          <DialogTitle>
            <IconButton onClick={() => props.handleCloseModal()}>
                    <CloseIcon />
                </IconButton>
          </DialogTitle>
          <DialogContent dividers>
            <h3>Instale o Editor Colacril no seu computador</h3>

            {!isChromium && 
              <Alert
                severity='warning'
                variant="filled"
              >
                Atenção! Este navegador não possui suporte para a instalação da aplicação Editor Colacril. Por favor, utilize o <strong>Google Chrome</strong> ou <strong>Microsoft Edge</strong>
              </Alert>
              }
            <p>
            Para instalar o Editor Colacril no seu computador:
            <ol>
              <li>
                Clique no botão "Instalar" abaixo
              </li>
              <li>
                Na telinha que abrirá para confirmar a instalação com a pergunta "Instalar app?", confirme escolhendo a opção "Instalar"
              </li>
              <li>
                O seu computador automaticamente irá abrir a versão instalada
              </li>
            </ol>
            </p>

            {props.children}
          </DialogContent>
        </Dialog>
    </>
  );
}

export default PWAInstall;