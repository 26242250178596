import { Grid, TextField, InputAdornment, Button } from "@mui/material";
import { useState } from "react";

import sUsuario from '../../../services/usuarioService';
import { handleFormErrors } from '../../../helpers/formErrorHandler'
import { useMessage } from "../../../context/alertContext/context";
import ShowPasswordInputAdornment from "./showPasswordInputAdornment";

function NewPassword(props: { modifyActiveComponent: Function }) {

    const [inputPasswordVisible, setInputPasswordVisible] = useState<boolean>(false);

    const { showAlertApp } = useMessage();

    const [formData, setFormData] = useState({
        oldPassword: '',
        newPassword: '',
        newPasswordConfirmation: ''
    })

    const [errors, setErrors] = useState({
        oldPassword: false,
        newPassword: false,
        newPasswordConfirmation: false
    })

    const handleInputChange = (e: any) => {
		setFormData({ ...formData, [e.target.name]: e.target.value });
	};

    const handleUpdatePassword = async (e: any) => {
        
        e.preventDefault();
        if (handleFormErrors(formData, errors, setErrors)) {
            return;
        }

        await sUsuario.updatePassword(formData).then((resp) => {
            props.modifyActiveComponent('login')
            showAlertApp('Senha atualizada com sucesso, faça login para utilizar o sistema', 'success', 5000)
        })
        .catch((err) => {
            showAlertApp("Houve um erro ao atualizar a senha, tente novamente mais tarde", 'error', 5000)
        })

    }

    return (

                <div className="editor-password-container">

                    <div className="editor-login-title">
                        <h2 className="editor-login-text">Esqueci minha senha</h2>
                    </div>

                    <p className="editor-login-secondary-text">Informe o seu e-mail para solicitar uma nova senha provisória.</p>

                    <Grid container rowSpacing={2} className="editor-login-authentication">
                    <Grid item xs={12}>
                        <h1 className="editor-login-authentication-text">Criar nova senha</h1>
                    </Grid>

                    <Grid item xs={12}>
                        <TextField
                            //className="editor-login-authentication-fields"
                            id="oldPassword"
                            type={inputPasswordVisible ? 'text' : 'password'}
                            name="oldPassword"
                            aria-label="Senha antiga: "
                            variant="outlined"
                            value={formData.oldPassword}
                            onChange={handleInputChange}
                            onPaste={(e) =>  {
                                e.preventDefault();
                                setFormData({ ...formData, oldPassword: e.clipboardData.getData('Text').trim() } )
                            }}
                            error={!errors.oldPassword}
                            fullWidth
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start" disablePointerEvents >
                                        <span className='form-label'>
                                            Senha antiga
                                        </span>
                                    </InputAdornment>
                                ),
                                endAdornment:
                                    <ShowPasswordInputAdornment
                                        inputVisible={inputPasswordVisible}
                                        setInputVisible={setInputPasswordVisible}
                                    />
                            }}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <TextField
                            //className="editor-login-authentication-fields"
                            id="newPassword"
                            type={inputPasswordVisible ? 'text' : 'password'}
                            name="newPassword"
                            aria-label="Senha nova: "
                            variant="outlined"
                            value={formData.newPassword}
                            onChange={handleInputChange}
                            error={!errors.newPassword}
                            fullWidth
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start" disablePointerEvents >
                                        <span className='form-label'>
                                            Nova senha
                                        </span>
                                    </InputAdornment>
                                ),
                                endAdornment:
                                    <ShowPasswordInputAdornment
                                        inputVisible={inputPasswordVisible}
                                        setInputVisible={setInputPasswordVisible}
                                    />
                            }}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <TextField
                            //className="editor-login-authentication-fields"
                            id="newPasswordConfirmation"
                            type={inputPasswordVisible ? 'text' : 'password'}
                            name="newPasswordConfirmation"
                            aria-label="Confirmar nova senha: "
                            variant="outlined"
                            value={formData.newPasswordConfirmation}
                            onChange={handleInputChange}
                            error={!errors.newPasswordConfirmation}
                            fullWidth
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start" disablePointerEvents>
                                        <span className='form-label'>
                                            Confirme senha
                                        </span>
                                    </InputAdornment>
                                ),
                                endAdornment:
                                    <ShowPasswordInputAdornment
                                        inputVisible={inputPasswordVisible}
                                        setInputVisible={setInputPasswordVisible}
                                    />
                            }}
                        />
                    </Grid>

                    <Grid item xs={12}>

                        <Button
                            type='submit'
                            variant="outlined"
                            className='btn btn-outlined-secondary'
                            disableElevation
                            onClick={handleUpdatePassword}
                        >
                            Enviar
                        </Button>

                        <br/>
                        <br/>


                    </Grid>
                </Grid>
                 
            </div>    

    );
}

export default NewPassword;