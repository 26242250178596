import { BarCodes, useBoardContext, usePropertyContext } from '@beontag/tageditor';
import { Checkbox, FormControlLabel, FormHelperText, IconButton, MenuItem, Select, TextField, Tooltip } from '@mui/material';
import { fabric } from 'fabric';
import InfoIcon from '@mui/icons-material/Info';
import OpenWithIcon from '@mui/icons-material/OpenWith';
import CloseIcon from '@mui/icons-material/Close';
import React from 'react';

const BarCode: React.FunctionComponent = () => {
  const { properties, setProperty } = usePropertyContext<fabric.BarCode>();

  const handleOnChange = (key: string, rawValue: string) => {
    setProperty({ [key]: rawValue });
  }

  const handleOnChangeBooleanProp = (key: string, rawValue: boolean) => {
    setProperty({ [key]: rawValue });
  }

  const { board } = useBoardContext()

    const closeWindow = () => {
		board?.setActiveObjects()
	}

  return (
    <>
    <div className="property-bar-title handle">
                            Propriedades do objeto
                            <OpenWithIcon className="icon left" />
                            <IconButton onClick={() => closeWindow()} className="icon right not-draggable">
                                <CloseIcon />
                            </IconButton>
                        </div>

      <div className="property-bar-itens">
        <span>Escala do Objeto</span>
        <TextField
          fullWidth
          className="property-box-input-width"
          type="number"
          InputProps={{ inputProps: { min: 0.1, max: 1, step: 0.1 } }}
          value={properties.scaleY || 1}
          onChange={(e) => {
            const value = e.currentTarget.value;
            handleOnChange("scaleX", value);
            handleOnChange("scaleY", value);
          }}
          variant="outlined"
        />
      </div>

      <div className="property-bar-itens">
        <span>Transparência do Objeto</span>
        <TextField
          fullWidth
          className="property-box-input-width"
          type="number"
          InputProps={{ inputProps: { min: 0.1, max: 1, step: 0.1 } }}
          value={properties.opacity || 1}
          onChange={(e) => handleOnChange("opacity", e.currentTarget.value)}
          variant="outlined"
        />
      </div>

      <div className="property-bar-barcode-select">
        <label>Endereço do codigo</label>
        <TextField
          type="text"
          fullWidth
          className='properties-itens-margin'
          onChange={(e) => handleOnChange("barCodeText", e.currentTarget.value)}
          value={properties.barCodeText ?? ''}
        />
      </div>

      <div className="property-bar-barcode-select">
        <span>Modelo de código</span>
        <Select
          fullWidth
          className='properties-itens-margin'
          value={properties.barCodeType || "QR Code"}
          onChange={(e) => handleOnChange("barCodeType", e.target.value)}
        >
          {
            BarCodes.getList().map((barCode, index) => {
              return <MenuItem value={barCode.value} key={index}>{barCode.name}</MenuItem>
            })
          }
        </Select>
        { properties.error &&
						<FormHelperText error className='qr-bar-code-error-text'>
							Ocorreu um erro com esta etiqueta
							<Tooltip title={properties.error}>
								<InfoIcon />
							</Tooltip>
						</FormHelperText>
					}
      </div>


      <div className="property-bar-itens">
        <span>Mostrar legenda</span>
        <FormControlLabel
          control={<Checkbox
            onChange={(e) => handleOnChangeBooleanProp("showSubtitle", e.currentTarget.checked)}
            checked={Boolean(properties.showSubtitle)}
          />}
          label={""}
        />
      </div>

      <div className="property-bar-itens">
        <span>Alinhar legenda</span>
        <Select
          fullWidth
          className='properties-alinhar-legenda'
          onChange={(e) => handleOnChange("subtitleAlign", e.target.value)}
          value={properties.subtitleAlign || 'center'}
        >
          {
            ["left", "center", "right", "offleft", "offright", "justify"].map((align, index) => {

              let value = "";

              if (align == "left") {
                value = "Esquerda";
              }
              if (align == "center") {
                value = "Centro";
              }
              if (align == "right") {
                value = "Direita";
              }
              if (align == "offleft") {
                value = "À esquerda";
              }
              if (align == "offright") {
                value = "À direita";
              }
              if (align == "justify") {
                value = "Justificado";
              }


              return <MenuItem value={align} key={index}>{value}</MenuItem>
            })
          }
        </Select>
      </div>
    </>
  );
};

export default BarCode;
