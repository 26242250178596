import Grid from '@mui/material/Grid'
import { useTranslation } from 'react-i18next'

import colorida from '../../assets/assistant-colacril/colorida-icon.webp'
import branca from '../../assets/assistant-colacril/branco_step1.webp'
import redonda from '../../assets/assistant-colacril/multiuso_step2.webp'
import quadrado from '../../assets/assistant-colacril/quadrado_step3.webp'
import cd_dvd from '../../assets/assistant-colacril/cd_step3.webp'
import outros from '../../assets/assistant-colacril/outros_step3.webp'
import React from 'react'

export default function Step3(props: {handleSelect:Function }) {
	const { t } = useTranslation('assistenteBusca')

    return (
        <>
            <h2>{t("Escolha a forma desejada: ")}</h2>
            
            <Grid container className='assistant-grid-container' spacing={1}>
                <Grid item xs={12} sm={6} md={3}>
                <button type="submit">
                    <div className='assistant-grid-item' onClick={() => props.handleSelect("Retangular")}>
                        <img alt="" src={branca} />
                        <p className="assistant-grid-text">{t("RETANGULAR")}</p>
                    </div> 
                    </button>               
                </Grid>
                
                <Grid item xs={12} sm={6} md={3}>
                <button type="submit">
                    <div className='assistant-grid-item' onClick={() => props.handleSelect("OvalRedondo")}>
                        <img alt="" src={redonda} />
                        <p className="assistant-grid-text">{t("OVAL OU REDONDO")}</p>
                       
                    </div>
                    </button>
                </Grid>
                
                <Grid item xs={12} sm={6} md={3}>
                <button type="submit">
                    <div className='assistant-grid-item' onClick={() => props.handleSelect("Quadrado")}>
                        <img alt="" src={quadrado} />
                        <p className="assistant-grid-text">{t("QUADRADO")}</p>
                        
                    </div>
                    </button>
                </Grid>
                
                <Grid item xs={12} sm={12} md={3}>
                    <button type="submit">
                        <div className='assistant-grid-item' onClick={() => props.handleSelect("Outros")}>
                            <img alt="" src={outros} />
                            <p className="assistant-grid-text">{t("OUTROS FORMATOS")}</p>
                            
                        </div>
                    </button>
                </Grid>
            </Grid>
        </>
    )
}