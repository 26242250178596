import { useCallback, useState } from "react";
import { useBoardContext } from "@beontag/tageditor";
import { Button, Dialog, DialogContent, DialogTitle, IconButton, InputAdornment, ListItemIcon, Menu, MenuItem, TextField } from "@mui/material";
import { Save as SaveIcon, FileDownload, CloudUpload, Close, Send } from "@mui/icons-material";
import { useMessage } from "../../../context/alertContext/context";
import { useEditorContext } from "../../../context/EditorContext";

import sEtiquetasUsuario from "../../../services/etiquetasUsuarioService";

const Save = () => {
	const { boardFile } = useBoardContext();
	const { produtoSelecionado, etiquetaUsuario, setEtiquetaUsuario, fileExtension } = useEditorContext();
	const { showAlertApp } = useMessage();


	const download = useCallback(() => {
		if (boardFile) {
			const name = produtoSelecionado?.codigo || 'etiqueta-colacril';
			const content = boardFile.export({
				tagData: {
					idProduto: produtoSelecionado?.idProduto
				}
			});

			const anchorEl = document.createElement("a");
			anchorEl.href = `data:text/json;charset=utf-8,${encodeURIComponent(content)}`;
			anchorEl.download = `${name}${fileExtension}`;
			document.body.appendChild(anchorEl); // required for firefox
			anchorEl.click();
			anchorEl.remove();
		}
	}, [boardFile]);

	const [openModalName, setOpenModal] = useState<boolean>(false);
	const [newDescriptionName, setNewDescriptionName] = useState<string>('');

	const saveToCloud = () => {

		if (boardFile) {

			const content = boardFile.export();

			if (etiquetaUsuario?.idUsuarioEtiqueta) {
				let updatedEtiqueta = etiquetaUsuario;
				updatedEtiqueta.dadosEtiqueta = content;
				setEtiquetaUsuario(updatedEtiqueta)
				showAlertApp("Salvando...", 'info')
				sEtiquetasUsuario.updateEtiqueta(updatedEtiqueta).then((resp) => {
					showAlertApp('Salvo com sucesso!', 'success', 5000)
				}).catch((err) => {
					showAlertApp('Houve um problema ao salvar a etiqueta, salve a sua edição no seu computador para não perder seu progresso', 'error', 5000)
				})
				return
			} else {
				setOpenModal(true);
			}

		}
	}

	const saveNew = () => {

		if (boardFile) {

			if (etiquetaUsuario?.idUsuarioEtiqueta) {
				saveToCloud()
				return
			}

			const dadosEtiqueta = boardFile.export()



			showAlertApp("Salvando...", 'info')
			sEtiquetasUsuario.saveEtiqueta({
				IdProduto: produtoSelecionado?.idProduto || '',
				DadosEtiqueta: dadosEtiqueta,
				Descricao: newDescriptionName
			}).then((resp) => {
				setEtiquetaUsuario({
					idProduto: produtoSelecionado?.idProduto || 0,
					descricao: newDescriptionName,
					dadosEtiqueta: dadosEtiqueta,
					idUsuarioEtiqueta: resp.data,
				})
				showAlertApp('Salvo com sucesso!', 'success', 5000)
				setOpenModal(false)
			}).catch((err) => {
				showAlertApp('Houve um problema ao salvar a etiqueta, salve a sua edição no seu computador para não perder seu progresso', 'error', 5000)
			})
		}

	}

	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const open = Boolean(anchorEl);
	const handleClick = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget);
	};

	return (
		<>
			<Button
				className='btn'
				startIcon={
					<SaveIcon fontSize='inherit' />
				}
				onClick={handleClick}
				aria-controls={open ? 'account-menu' : undefined}
				aria-haspopup="true"
				aria-expanded={open ? 'true' : undefined}
			>
				Salvar
			</Button>
			<Menu
				elevation={1}
				anchorEl={anchorEl}
				open={open}
				onClose={() => setAnchorEl(null)}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'right',
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'right',
				}}
			>
				<MenuItem onClick={download}>
					<ListItemIcon>
						<FileDownload />
					</ListItemIcon>
					Salvar no computador
				</MenuItem>
				<MenuItem onClick={() => saveToCloud()}>
					<ListItemIcon>
						<CloudUpload />
					</ListItemIcon>
					Salvar na nuvem
				</MenuItem>
			</Menu>
			<Dialog
				open={openModalName}
				onClose={() => setOpenModal(false)}
				scroll='paper'
				maxWidth={'md'}
			>
				<DialogTitle>
					<IconButton onClick={() => setOpenModal(false)}>
						<Close />
					</IconButton>
				</DialogTitle>
				<DialogContent dividers>
					<h3>Minhas etiquetas</h3>

					<TextField
						fullWidth
						name='newDescriptionName'
						type="text"
						value={newDescriptionName}
						onChange={(e) => setNewDescriptionName(e.target.value)}
						onKeyDown={(e) => e.key == "Enter" ? saveNew() : undefined}
						variant="outlined"
						InputProps={{
							startAdornment: (
								<InputAdornment position="start" disablePointerEvents //className="editor-login-authentication-fields-adornment"
								>
									<span className='form-label'>
										Nome:
									</span>
								</InputAdornment>
							),

							endAdornment: (
								<InputAdornment position="end">
									<IconButton onClick={() => saveNew()}>
										<Send />
									</IconButton>
								</InputAdornment>
							)

						}}
					/>
				</DialogContent>
			</Dialog>
		</>);
};

export default Save;
