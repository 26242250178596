import { FormatListNumbered, KeyboardArrowDown } from "@mui/icons-material";
import { Button, Grid, InputAdornment, Menu, MenuItem, TextField } from "@mui/material";
import { t } from "i18next";
import { useState } from "react";
import { AddSequentialTextFeature, AddSequentialTextFeatures } from "@beontag/tageditor";
import React from "react";

export default function Numeration(props: { setMenuAnchorEl: Function }) {

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const [sequentialTextFeatureUserData, setSequentialTextFeatureUserData] = useState({
        sequenceStarts: 1,
        sequenceEnds: 1000,
        sequenceIncrement: 1,
        sequenceMask: '',
        sequencePrefix: '',
        sequenceSuffix: '',
    })

    const handleInputChange = (e: any) => {
        setSequentialTextFeatureUserData({ ...sequentialTextFeatureUserData, [e.target.name]: e.target.value })
    }

    const [sequentialTextFeature, setSequentialTextFeature] = useState<AddSequentialTextFeatures>();

    const handleAddFeature = (e: any) => {
        sequentialTextFeature?.add(sequentialTextFeatureUserData)
        props.setMenuAnchorEl(e.target)
        setAnchorEl(null)
    }

    return (
        <>
            <div className="menu-item">
                <Button
                    className='btn'
                    startIcon={<FormatListNumbered />}
                    endIcon={<KeyboardArrowDown />}
                    onClick={e => setAnchorEl(e.currentTarget)}
                > Numeração
                </Button>
            </div>

            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={() => setAnchorEl(null)}
                PaperProps={{
                    className: 'menu-dropdown'
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left'
                }}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left'
                }}
                className="editor-tb-menu"
            >
                {/* <MenuItem disableRipple className="editor-tb-menu-item">
                    <FormGroup>
                        <FormControlLabel
                            className="editor-numeration-checkbox"
                            control={
                                <Checkbox
                                    value='numeros'
                                    name='checkboxNumeracaoNumeros'
                                    color='primary'
                                />
                            }
                            label="Números"
                        />

                        <FormControlLabel
                            className="editor-numeration-checkbox"
                            control={
                                <Checkbox
                                    value='email'
                                    name='checkboxNumeracaoLetras'
                                />
                            }
                            label="Letras"
                        />
                    </FormGroup>
                </MenuItem> */}

                <MenuItem disableRipple className="editor-tb-menu-item">
                    <Grid item container spacing={1}>
                        <Grid item xs={6}>
                            <TextField
                                className="editor-numeracao-fields-container"
                                id="inicio"
                                type="number"
                                name="sequenceStarts"
                                aria-label="Início: "
                                variant="outlined"
                                value={sequentialTextFeatureUserData.sequenceStarts}
                                onChange={handleInputChange}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start" disablePointerEvents className="editor-numeracao-input-font">
                                            <span className='form-label'>
                                                {t("Início: ")}
                                            </span>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Grid>

                        <Grid item xs={6}>
                            <TextField
                                className="editor-numeracao-fields-container"
                                id="fim"
                                type="number"
                                name="sequenceEnds"
                                aria-label="Fim: "
                                variant="outlined"
                                value={sequentialTextFeatureUserData.sequenceEnds}
                                onChange={handleInputChange}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start" disablePointerEvents className="editor-numeracao-input-font">
                                            <span className='form-label'>
                                                {t("Fim: ")}
                                            </span>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Grid>
                    </Grid>
                </MenuItem>

                <MenuItem disableRipple className="editor-tb-menu-item">
                    <Grid item container spacing={1}>
                        <Grid item xs={6}>
                            <TextField
                                className="editor-numeracao-fields-container"
                                id="prefixo"
                                type="number"
                                name="sequencePrefix"
                                aria-label="Prefixo: "
                                variant="outlined"
                                value={sequentialTextFeatureUserData.sequencePrefix}
                                onChange={handleInputChange}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start" disablePointerEvents className="editor-numeracao-input-font">
                                            <span className='form-label'>
                                                {t("Prefixo: ")}
                                            </span>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Grid>

                        <Grid item xs={6}>
                            <TextField
                                className="editor-numeracao-fields-container"
                                id="sufixo"
                                type="number"
                                name="sequenceSuffix"
                                aria-label="Sufixo: "
                                variant="outlined"
                                value={sequentialTextFeatureUserData.sequenceSuffix}
                                onChange={handleInputChange}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start" disablePointerEvents className="editor-numeracao-input-font">
                                            <span className='form-label'>
                                                {t("Sufixo: ")}
                                            </span>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Grid>
                    </Grid>
                </MenuItem>

                <MenuItem disableRipple className="editor-tb-menu-item">
                    <div className="editor-numeracao-incremento">
                        <TextField
                            className="editor-numeracao-fields-container"
                            id="incremento"
                            type="number"
                            name="sequenceIncrement"
                            aria-label="Incremento: "
                            variant="outlined"
                            value={sequentialTextFeatureUserData.sequenceIncrement}
                            onChange={handleInputChange}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start" disablePointerEvents className="editor-numeracao-input-font">
                                        <span className='form-label'>
                                            {t("Incremento: ")}
                                        </span>
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </div>
                </MenuItem>

                <MenuItem disableRipple className="editor-tb-menu-item editor-tb-button-div">
                    <div >
                        <AddSequentialTextFeature featureUpdate={f => setSequentialTextFeature(f)}>
                            <button className="editor-tb-button" onClick={handleAddFeature}>INSERIR</button>
                        </AddSequentialTextFeature>
                    </div>
                </MenuItem>

            </Menu>
        </>
    )
}