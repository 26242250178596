import { useState } from "react";
import { useBoardContext, LayerFeature, LayerFeatures } from "@beontag/tageditor";
import { Button, ListItemIcon, ListItemText, Menu, MenuItem } from "@mui/material";
import { KeyboardArrowDown, KeyboardArrowUp, KeyboardDoubleArrowDown, KeyboardDoubleArrowUp, Layers as LayersIcon } from "@mui/icons-material";
import React from "react";

const Layers = () => {
    const { board } = useBoardContext();
    const [layerFeature, setLayerFeature] = useState<LayerFeatures>();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    return (
        <LayerFeature featureUpdate={f => setLayerFeature(f)}>
            <Button
                className='btn' 
                startIcon={<LayersIcon />} 
                endIcon={<KeyboardArrowDown />}
                onClick={e => setAnchorEl(e.currentTarget)}
                disabled={!board?.activeObjects.length}
            > 
                Posição
            </Button>

            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={() => setAnchorEl(null)}
                PaperProps={{
                    className: 'menu-dropdown'
                }}
                transformOrigin={{ 
                    horizontal: 'left', 
                    vertical: 'top'
                }}
                anchorOrigin={{ 
                    horizontal: 'left', 
                    vertical: 'bottom'
                }}
            >
                <MenuItem onClick={() => layerFeature?.up(false)}>
                    <ListItemIcon>
                        <KeyboardArrowUp fontSize="small" />
                    </ListItemIcon>

                    <ListItemText> Avançar </ListItemText>
                </MenuItem>

                <MenuItem onClick={() => layerFeature?.down(false)}>
                    <ListItemIcon>
                        <KeyboardArrowDown fontSize="small" />
                    </ListItemIcon>

                    <ListItemText> Recuar </ListItemText>
                </MenuItem>

                <MenuItem onClick={() => layerFeature?.up(true)}>
                    <ListItemIcon>
                        <KeyboardDoubleArrowUp fontSize="small" />
                    </ListItemIcon>

                    <ListItemText> Trazer para frente </ListItemText>
                </MenuItem>

                <MenuItem onClick={() => layerFeature?.down(true)}>
                    <ListItemIcon>
                        <KeyboardDoubleArrowDown fontSize="small" />
                    </ListItemIcon>

                    <ListItemText> Enviar para trás </ListItemText>
                </MenuItem>
            </Menu>
        </LayerFeature>
    );
};

export default Layers;
