import { Button, Grid, InputAdornment, InputLabel, MenuItem, Select, FormControl } from "@mui/material";
import { IFiltros } from "../../interfaces/produto/filtros";
import ClearIcon from '@mui/icons-material/Clear';
import { useTranslation } from "react-i18next";
import React from "react";

export default function ProductFilters(props: { filtros:IFiltros[] | undefined, values: {[key: string]: string}, handleInputChange: Function, clearInput: Function, t : Function, onFilterPress: Function }) {

    let length = props.filtros?.length || 1;

    const { t } = useTranslation("categoria")
    
    return (
        <>
            {props.filtros &&
                <Grid container spacing={2}>     
                    {props.filtros?.map((filtro) => {
                        
                        return (
                            <Grid item xs={12} sm={length >= 4 ? (24/length) : 12} md={(12/length - 2/length)} key={filtro.typeName}>
                                <FormControl>
                                <InputLabel id={`label-form-contato-select`}>{filtro.typeName !== 'Carreira' ? props.t(filtro.typeName) : t('Quantidade de carreiras')}</InputLabel>
                                <Select
                                    labelId={`label-form-contato-select`}
                                    value={props.values[filtro.typeName] ?? ''}
                                    id={filtro.typeName}
                                    label={filtro.typeName !== 'Carreira' ? t(filtro.typeName) : 'Quantidade de  carreiras'}
                                    name={filtro.typeName}
                                    onChange={(e) => props.handleInputChange(e)}
                                    MenuProps={{
                                        disablePortal: true,
                                    }}
                                    fullWidth
                                    endAdornment={
                                        <InputAdornment
                                            position='end'
                                            className="form-clear-selection"
                                            onClick={() => props.clearInput(filtro.typeName)}
                                        >
                                            <div><ClearIcon fontSize="small" /></div>
                                        </InputAdornment>
                                    }
                                >
                                    {filtro.listEnum.map((item) => {
                                        return (
                                            <MenuItem key={item.value} value={item.value}>
                                                {item.text}
                                            </MenuItem>
                                        )
                                    })}
                                </Select>
                                </FormControl>

                            </Grid>
                        )
                    })}

                    

                        <Grid item xs={12} md={2}>
                            <div className="btn-product-filter-container">
                                <Button className="btn btn-outlined-secondary" onClick={() => { 
                                    props.onFilterPress()
                                    } } variant="outlined" fullWidth>Filtrar</Button>
                            </div>

                            
                        </Grid>
                </Grid> 
            }   
        </>
    )
}