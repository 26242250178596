import Slider from 'react-slick'
import IImagem from '../../interfaces/produto/imagem'

import { ForwardArrow, BackArrow } from '../common/slideshowArrows'
import React from 'react';

export default function CarouselProductCard(props: {images:string[]}) {

    const settings = {
        dots: false,
        infinite: true,
        speed: 200,
        slidesToShow: 1,
        slidesToScroll: 1,
        //autoplay: true,
        autoplaySpeed: 5000,
        cssEase: "linear",
        pauseOnHover: true,
        arrows: true,
        nextArrow: <ForwardArrow type='product-card'/>,
		prevArrow: <BackArrow type='product-card'/>,
    };

    return (
        <>
            <Slider {...settings}>
                {props.images? props.images.map((image, index) => {
                    return (
                        <div className='product-card-carousel-container' key={index}>
                            <img
                                src={image}
                                alt="Imagem do produto"
                                className="carousel-product-page"
                                key={index}
                            />
                        </div>
                    )
                }): ''}  
            </Slider>
        </>
    )
}