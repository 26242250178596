import Slider from 'react-slick'

import { BackArrow, ForwardArrow } from '../components/common/slideshowArrows'
import AltText from './common/altText'
import header_image from '../assets/header_resp_socioamb.webp'
import { useTranslation } from 'react-i18next';
import React from 'react';


export default function CarouselQuemSomos() {

	const settings = {
		dots: true,
		infinite: true,
		speed: 500,
		slidesToShow: 1,
		slidesToScroll: 1,
		autoplaySpeed: 5000,
		cssEase: "linear",
		pauseOnHover: true,
		arrows: true,
		nextArrow: <ForwardArrow type='carousel'/>,
		prevArrow: <BackArrow type='carousel'/>,
		appendDots: (dots: any) => (
			<div className='carousel-landing-settings-div'>
				<ul className="carousel-landing-settings-ul"> {dots} </ul>
			</div>
		),

	};

	const { t } = useTranslation("aEmpresa")

	return (
		<div className='carousel-quem-somos-super-container'>
			<Slider {...settings}>
				<div className='carousel-quem-somos-page-content-container'>
					<img alt="" src={header_image} className="carousel-quem-somos-page" />					
				</div>
			</Slider>

			<h1 className='quem-somos-header-text'><AltText>{t('Quem somos')}</AltText></h1>

			<div className="carousel-quem-somos-bottom-divider">
				<svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
					<path d="M1200 120L0 16.48 0 0 1200 0 1200 120z" className="shape-fill"></path>
				</svg>
			</div>
		</div>
	)
}