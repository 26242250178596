import { useState } from "react";

import { Button, Dialog, DialogContent, DialogTitle, Grid, IconButton, InputAdornment, TextField } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import sUsuario from '../../../services/usuarioService';
import { handleFormErrors } from '../../../helpers/formErrorHandler'

import { useMessage } from '../../../context/alertContext/context';
import ShowPasswordInputAdornment from "./showPasswordInputAdornment";

const ModalChangePassword = (props: {
  open: boolean,
  handleCloseModal: Function,
}) => {

    const { showAlertApp } = useMessage();
    const [inputPasswordVisible, setInputPasswordVisible] = useState<boolean>(false);

    const [formData, setFormData] = useState({
        oldPassword: '',
        newPassword: '',
        newPasswordConfirmation: ''
    })

    const [errors, setErrors] = useState({
        oldPassword: false,
        newPassword: false,
        newPasswordConfirmation: false
    })

    const handleInputChange = (e: any) => {
		setFormData({ ...formData, [e.target.name]: e.target.value });
	};

    const handleUpdatePassword = async (e: any) => {
        
        e.preventDefault();
        if (handleFormErrors(formData, errors, setErrors)) {
            return;
        }

        await sUsuario.updatePassword(formData).then((resp) => {
            showAlertApp('Senha atualizada com sucesso!', 'success', 5000)
        })
        .catch((err) => {
            showAlertApp("Houve um erro ao atualizar a senha, tente novamente mais tarde", 'error', 5000)
        })

    }


  return (
    <>
      <Dialog
        open={props.open}
        onClose={() => props.handleCloseModal()}
        scroll='paper'
        maxWidth={'md'}
        >
          <DialogTitle>
            <IconButton onClick={() => props.handleCloseModal()}>
                    <CloseIcon />
                </IconButton>
          </DialogTitle>
          <DialogContent dividers>
          <Grid container rowSpacing={2} className="editor-login-authentication modal-grid">
                    <Grid item xs={12}>
                        <h1 className="editor-login-authentication-text">Criar nova senha</h1>
                    </Grid>

                    <Grid item xs={12}>
                        <TextField
                            id="oldPassword"
                            type={inputPasswordVisible ? 'text' : 'password'}
                            name="oldPassword"
                            aria-label="Senha antiga: "
                            variant="outlined"
                            value={formData.oldPassword}
                            onChange={handleInputChange}
                            onPaste={(e) =>  {
                                e.preventDefault();
                                setFormData({ ...formData, oldPassword: e.clipboardData.getData('Text').trim() } )
                            }}
                            error={errors.oldPassword}
                            fullWidth
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start" disablePointerEvents className="editor-login-authentication-fields-adornment">
                                        <span className='form-label'>
                                            Senha antiga
                                        </span>
                                    </InputAdornment>
                                ),
                                endAdornment:
                                    <ShowPasswordInputAdornment
                                        inputVisible={inputPasswordVisible}
                                        setInputVisible={setInputPasswordVisible}
                                    />
                            }}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <TextField
                            id="newPassword"
                            type={inputPasswordVisible ? 'text' : 'password'}
                            name="newPassword"
                            aria-label="Senha nova: "
                            variant="outlined"
                            value={formData.newPassword}
                            onChange={handleInputChange}
                            error={errors.newPassword}
                            fullWidth
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start" disablePointerEvents className="editor-login-authentication-fields-adornment">
                                        <span className='form-label'>
                                            Nova senha
                                        </span>
                                    </InputAdornment>
                                ),
                                endAdornment:
                                    <ShowPasswordInputAdornment
                                        inputVisible={inputPasswordVisible}
                                        setInputVisible={setInputPasswordVisible}
                                    />
                            }}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <TextField
                            id="newPasswordConfirmation"
                            type={inputPasswordVisible ? 'text' : 'password'}
                            name="newPasswordConfirmation"
                            aria-label="Confirmar nova senha: "
                            variant="outlined"
                            value={formData.newPasswordConfirmation}
                            onChange={handleInputChange}
                            error={errors.newPasswordConfirmation}
                            fullWidth
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start" disablePointerEvents className="editor-login-authentication-fields-adornment">
                                        <span className='form-label'>
                                            Confirme senha
                                        </span>
                                    </InputAdornment>
                                ),
                                endAdornment:
                                    <ShowPasswordInputAdornment
                                        inputVisible={inputPasswordVisible}
                                        setInputVisible={setInputPasswordVisible}
                                    />
                            }}
                        />
                    </Grid>

                    <Grid item xs={12} className="modal-userdata-buttons">
                        <Button
                            type='submit'
                            variant="contained"
                            className='btn btn-outlined-secondary'
                            disableElevation
                            onClick={handleUpdatePassword}
                        >
                            Enviar
                        </Button>
                    </Grid>
                </Grid>

          </DialogContent>
        </Dialog>
    </>
  );
}

export default ModalChangePassword;