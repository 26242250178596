import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import React from 'react';

export function BackArrow (props:any) {
    const {onClick, type } = props;

    return (
        <div
            className={`slideshow-arrow-container back ${type}`}
            onClick={onClick}
        >
            <ArrowBackIosNewIcon />
        </div>
    )
}

export function ForwardArrow (props:any) {
    const { onClick, type } = props;
    
    return (
        <div
            className={`slideshow-arrow-container forward ${type}`}
            onClick={onClick}
        >
            <ArrowForwardIosIcon />
        </div>
    )
}