import AltText from "../common/altText";
import { ClickAwayListener, Grid } from "@mui/material";
import { useState } from "react";
import { Link } from "react-router-dom";
import { Trans, useTranslation } from "react-i18next";

import image1 from '../../assets/homescreen/images/mais_vistos_1.webp'
import image2 from '../../assets/homescreen/images/mais_vistos_2.webp'
import image3 from '../../assets/homescreen/images/mais_vistos_3.webp'
import image4 from '../../assets/homescreen/images/mais_vistos_4.webp'
import React from "react";

function MaisVistos() {
    const { t } = useTranslation('homepage')
    const [activeItem, setActiveItem] = useState<number | undefined>(undefined);

    function onClickAway() {
        setActiveItem(undefined)
    }

    return (
        <div className="maisVistos">
            <Trans t={t} i18nKey="Os mais procurados">
                <h1 className="maisVistos-trans-h1-text" >Os mais <AltText>procurados</AltText></h1>
            </Trans>

            <div>
                <ClickAwayListener
                    onClickAway={onClickAway}
                    mouseEvent="onMouseDown"
                    touchEvent="onTouchStart"
                >
                    <Grid container spacing={2}>
                        <Grid item container xs={6} md={3}>
                            <div className={`flip-card ${activeItem == 1 ? 'touch' : ''}`} onTouchStart={() => setActiveItem(1)}>
                                <div className="flip-card-inner">
                                    <div className="flip-card-front">
                                        <img src={image1} alt="Avatar" />
                                    </div>
                                    
                                    <Link to="produtos/CA4167" className="flip-card-back">
                                        <div>
                                            <h1>{t("Inkjet-Laser Branca")}</h1>
                                            <h1>297mm x 210mm</h1>
                                            {/* <p>FSC 100</p> */}
                                        </div>
                                    </Link>
                                </div>
                            </div>
                        </Grid>

                        <Grid item container xs={6} md={3}>
                            <div className={`flip-card ${activeItem == 2 ? 'touch' : ''}`} onTouchStart={() => setActiveItem(2)}>
                                <div className="flip-card-inner">
                                    <div className="flip-card-front">
                                        <img src={image2} alt="Avatar" />
                                    </div>
                                    
                                    <Link to="produtos/BAN38050" className="flip-card-back">
                                        <div>
                                            <h1>{t("Bloco Adesivo Neon")}</h1>
                                            <h1>38mm x 50mm</h1>
                                            {/* <p>Amarelo, 76x102mm</p> */}
                                        </div>
                                    </Link>
                                </div>
                            </div>
                        </Grid>

                        <Grid item container xs={6} md={3}>
                            <div className={`flip-card ${activeItem == 3 ? 'touch' : ''}`} onTouchStart={() => setActiveItem(3)}>
                                <div className="flip-card-inner">
                                    <div className="flip-card-front">
                                        <img src={image3} alt="Avatar" />
                                    </div>
                                    
                                    <Link to="produtos/MNR12044" className="flip-card-back">
                                        <div>
                                            <h1>{t("Marcador de página seta colorido")}</h1>
                                            <h1>12.7cm x 44mm</h1>
                                            {/* <p>5 cores</p> */}
                                        </div>
                                    </Link>
                                </div>
                            </div>
                        </Grid>

                        <Grid item container xs={6} md={3}>
                            <div className={`flip-card ${activeItem == 4 ? 'touch' : ''}`} onTouchStart={() => setActiveItem(4)}>
                                <div className="flip-card-inner">
                                    <div className="flip-card-front">
                                        <img src={image4} alt="Avatar" />
                                    </div>
                                    
                                    <Link to="produtos/F02233" className="flip-card-back">
                                        <div>
                                            <h1>{t("Filme adesivo transparente")}</h1>
                                            <h1>45cm x 25cm (40mi)</h1>
                                            {/* <p>transparente</p> */}
                                        </div>
                                    </Link>
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                </ClickAwayListener>
            </div>
        </div>
    )
}

export default MaisVistos;