import { useState } from "react";
import { useBoardContext, AlignFeature, AlignFeatures } from "@beontag/tageditor";
import { Button, ListItemIcon, ListItemText, Menu, MenuItem } from "@mui/material";
import { AlignHorizontalCenter, AlignHorizontalLeft, AlignHorizontalRight, AlignVerticalBottom, AlignVerticalTop, KeyboardArrowDown } from "@mui/icons-material";
import React from "react";

const Alignment = () => {
    const { board } = useBoardContext();
    const [alignFeature, setAlignFeature] = useState<AlignFeatures>();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    return (
        <AlignFeature featureUpdate={f => setAlignFeature(f)}>
            <Button
                className='btn' 
                startIcon={<AlignHorizontalRight />} 
                endIcon={<KeyboardArrowDown />}
                onClick={e => setAnchorEl(e.currentTarget)}
                disabled={!board?.activeObjects.length}
            > 
                Alinhamento
            </Button>

            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={() => setAnchorEl(null)}
                PaperProps={{
                    className: 'menu-dropdown'
                }}
                transformOrigin={{ 
                    horizontal: 'left', 
                    vertical: 'top'
                }}
                anchorOrigin={{ 
                    horizontal: 'left', 
                    vertical: 'bottom'
                }}
            >
                <MenuItem onClick={() => alignFeature?.align('left')}>
                    <ListItemIcon>
                        <AlignHorizontalLeft fontSize="small" />
                    </ListItemIcon>

                    <ListItemText> À esquerda </ListItemText>
                </MenuItem>

                <MenuItem onClick={() => alignFeature?.align('right')}>
                    <ListItemIcon>
                        <AlignHorizontalRight fontSize="small" />
                    </ListItemIcon>

                    <ListItemText> À direita </ListItemText>
                </MenuItem>

                <MenuItem onClick={() => alignFeature?.align('center')}>
                    <ListItemIcon>
                        <AlignHorizontalCenter fontSize="small" />
                    </ListItemIcon>

                    <ListItemText> Ao centro </ListItemText>
                </MenuItem>

                <MenuItem onClick={() => alignFeature?.align('top')}>
                    <ListItemIcon>
                        <AlignVerticalTop fontSize="small" />
                    </ListItemIcon>

                    <ListItemText> No topo </ListItemText>
                </MenuItem>

                <MenuItem onClick={() => alignFeature?.align('bottom')}>
                    <ListItemIcon>
                        <AlignVerticalBottom fontSize="small" />
                    </ListItemIcon>

                    <ListItemText> No fundo </ListItemText>
                </MenuItem>
            </Menu>
        </AlignFeature>
    );
};

export default Alignment;
