import { ZoomOut as ZoomOutIcon } from "@mui/icons-material";
import { Button } from "@mui/material";
import { useBoardContext } from "@beontag/tageditor";
import React from "react";

export interface ZoomOutProps {
    decreaseStep: number
}

const ZoomOut: React.FunctionComponent<ZoomOutProps> = (props) => {
    const { boardZoom } = useBoardContext();

    const zoomOut = () => boardZoom?.set(round(boardZoom?.value - props.decreaseStep));

    const lowestValue = () => boardZoom?.value === boardZoom?.min;

    const round = (value: number) => {
        return Math.round((value + Number.EPSILON) * 100) / 100;
    };

    return (
        <Button 
            className='btn' 
            startIcon={<ZoomOutIcon />}
            onClick={zoomOut}
            disabled={lowestValue()}
        > 
            Zoom Out
        </Button>
    );
};

export default ZoomOut;
