import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Grid } from '@mui/material'
import { FacebookLogo, InstagramLogo, YoutubeLogo } from '../../components/common/social-logos'

import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';

import footer_logo from '../../assets/logo_colacril_vertical_branco.webp'
import footer_logo_mobile from '../../assets/logo_colacril_horizontal_branco.webp'
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import LanguageSelector from './language-selector';
import { ICategoria } from '../../interfaces/produto/categoria'

import useMediaQuery from '@mui/material/useMediaQuery';
import { createTheme } from '@mui/material/styles'
import React from 'react';

export default function Footer(props: { categorias: ICategoria[] }) {
    const theme = createTheme();
    const { t } = useTranslation('footer');

    const matches = useMediaQuery(theme.breakpoints.down('lg'));

    // TODO: COLOCAR ALT TEXT NAS IMGS
    return (
        <footer className='footer'>
            <div className='footer-container'>
                <Grid container>
                    <Grid item xs={12} md={2.4}>
                        <div className='footer-img-logo-container'>
                            <img className="footer-img-logo" alt="" src={matches ? footer_logo_mobile : footer_logo} />
                        </div>
                            
                    </Grid>

                    <Grid item xs={12} md={2.4}>
                        <Accordion allowZeroExpanded={true}>
                            <AccordionItem>
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                        <p className='footer-text-title'>
                                            {t("Produtos")}

                                            <ArrowForwardIosIcon
                                                fontSize="small"
                                                className="accordion-icon"
                                            />
                                        </p>
                                    </AccordionItemButton>
                                </AccordionItemHeading>

                                <AccordionItemPanel>
                                    {props.categorias?.map((categoria, index) => {
                                        return (
                                            <Link to={`categoria/${categoria.alias}`} key={index}>
                                                <p className='footer-text'>{categoria.nome}</p>
                                            </Link>
                                        )
                                    })}
                                </AccordionItemPanel>
                            </AccordionItem>
                        </Accordion>
                    </Grid>

                    <Grid item container md={2.4}>
                        <Grid item xs={12}>
                            <Accordion allowZeroExpanded={true}>
                                <AccordionItem>
                                    <AccordionItemHeading>
                                        <AccordionItemButton className='accordion__button__software'>
                                            <Link to="downloads/download_software">
                                                <p className='footer-text-title'>{t("Software de impressão")}</p>
                                            </Link>
                                        </AccordionItemButton>
                                    </AccordionItemHeading>
                                </AccordionItem>
                            </Accordion>
                        </Grid>

                        <Grid item xs={12} md={2.4}>
                            <Accordion allowZeroExpanded={true}>
                                <AccordionItem>
                                    <AccordionItemHeading>
                                        <AccordionItemButton>
                                            <Link to="downloads">
                                                <p className='footer-text-title'>{t("Downloads")}</p>
                                            </Link>
                                        </AccordionItemButton>
                                    </AccordionItemHeading>
                                </AccordionItem>
                            </Accordion>
                        </Grid>
                    </Grid>

                    <Grid item xs={12} md={2.4}>
                        <Accordion allowZeroExpanded={true}>
                            <AccordionItem>
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                        <p className='footer-text-title'>
                                            {t("A Empresa")}
                                            <ArrowForwardIosIcon
                                                fontSize="small"
                                                className="accordion-icon"
                                            />
                                        </p>
                                    </AccordionItemButton>
                                </AccordionItemHeading>

                                <AccordionItemPanel>
                                    <Link to="empresa/quemsomos">
                                        <p className='footer-text'>{t("Quem somos")}</p>
                                    </Link>
                                    <Link to="empresa/valores">
                                        <p className='footer-text'>{t("Valores")}</p>
                                    </Link>
                                    <Link to="empresa/resp_socioamb">
                                        <p className='footer-text'>{t("Responsabilidade Ambiental")}</p>
                                    </Link>
                                    <Link to="empresa/certificados">
                                        <p className='footer-text'>{t("Certificados")}</p>
                                    </Link>
                                </AccordionItemPanel>
                            </AccordionItem>
                        </Accordion>
                    </Grid>

                    <Grid item xs={12} md={2.4}>
                        <div className='footer-logo-social-container'>
                        <Accordion allowZeroExpanded={true}>
                            <AccordionItem>
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                        <p className='footer-text-title'>
                                            {t("Outros")}
                                            <ArrowForwardIosIcon
                                                fontSize="small"
                                                className="accordion-icon"
                                            />
                                        </p>
                                    </AccordionItemButton>
                                </AccordionItemHeading>

                                <AccordionItemPanel>
                                    <Link to="faq">
                                        <p className='footer-text'>{t("Dúvidas frequentes")}</p>
                                    </Link>

                                    <Link to="contato">
                                        <p className='footer-text'>{t("Contato")}</p>
                                    </Link>
                                </AccordionItemPanel>
                            </AccordionItem>
                        </Accordion>
                        <p className="footer-social-icons-container">
                                <a
                                    href="https://www.facebook.com/colacriloffice/"
                                    target="_blank"
                                    rel='noopener noreferrer'
                                    title='facebook'
                                >
                                    <FacebookLogo />
                                </a>

                                <a
                                    href="https://www.instagram.com/colacriloffice/"
                                    target="_blank"
                                    rel='noopener noreferrer'
                                    title='instagram'
                                >
                                    <InstagramLogo />
                                </a>

                                <a
                                    href="https://www.youtube.com/channel/UCnLp6wVb-GlBMkoHPud0oiA"
                                    target="_blank"
                                    rel='noopener noreferrer'
                                    title='youtube'
                                >
                                    <YoutubeLogo />
                                </a>
                            </p>
                        </div>
                    </Grid>
                </Grid>
            </div>

            <div className='footer-social-language-combo-container'>
                <p className="footer-social-icons-container">
                    <a
                        href="https://www.facebook.com/colacriloffice/"
                        target="_blank"
                        rel='noopener noreferrer'
                        title='facebook'
                    >
                        <FacebookLogo />
                    </a>

                    <a
                        href="https://www.instagram.com/colacriloffice/"
                        target="_blank"
                        rel='noopener noreferrer'
                        title='instagram'
                    >
                        <InstagramLogo />
                    </a>

                    <a
                        href="https://www.youtube.com/channel/UCnLp6wVb-GlBMkoHPud0oiA"
                        target="_blank"
                        rel='noopener noreferrer'
                        title='youtube'
                    >
                        <YoutubeLogo />
                    </a>
                </p>

                <LanguageSelector />
            </div>

            <div>
                <p style={{ marginTop: 0, fontSize: 10, textAlign: 'center' }}>{process.env.REACT_APP_APP_VERSION}</p>
            </div>
        </footer>
    )
}