import { useLoaderData, useSearchParams } from "react-router-dom";
import IProdutoCardColacril from "../../../interfaces/produto/produtoCardColacril";

import sProduto from '../../../services/produtoService'
import ProductList from "../../../components/produtos/produto-list";
import { Trans, useTranslation } from "react-i18next";
import AltText from "../../../components/common/altText";

import bg_image from '../../../assets/pattern_colacril_resultado_busca.webp'
import linhaSeparator from '../../../assets/separadores/separador-pagina-duvidas.webp'

import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import React, { useEffect, useState } from "react";

function PesquisaProdutos() {

	const { i18n } = useTranslation();
	const [searchParams] = useSearchParams();
	const [count, setCount] = useState(0);
	const [produtos, setProdutos] = useState([]);
	const [termosAssistant, setTermosAssistant] = useState<(string | undefined)[]>([])
	let termo = searchParams.get('pesquisa') as string;
	let locale = i18n.language;

	
	let termsAssistant;

	useEffect(() => {
		if (termo) {

			sProduto.pesquisaProduto(locale, termo).then((resp) => {
				setProdutos(resp.data.produtos);
				setCount(resp.data.produtos.length);
			}).catch((err) => {
			})
	
		} else {
			const branco = searchParams.get('branco') as string;
			const categoria = searchParams.get('categoria') as string;
			const forma = searchParams.get('formato') as string;
			const rolos = searchParams.get('rolos') as string;
	
			const colorido = branco == 'true' ? 'Branca' : 'Colorida';
	
			setTermosAssistant([colorido, categoria, forma, rolos ? `${rolos} carreiras` : undefined])
			sProduto.assistenteBusca(locale, branco, categoria, forma, rolos).then((resp) => {
				setProdutos(resp.data.produtos);
				setCount(resp.data.produtos.length);
				//termo = categoria;
			})
		}
	}, [searchParams])

	// const { termo, produtos, count, termsAssistant } = useLoaderData();
	const { t } = useTranslation('produtos')

	return (
		<>
			<div className='content-header-container'>
				<img src={bg_image} alt="" className="content-header-image" />

				<div className='content-header-image-text content-header-text-search'>
					<Trans t={t} i18nKey="Resultados da busca">
						<h1 className='faq-header-title'>Resultados da <AltText>busca</AltText></h1>
					</Trans>

					<h3>
						{t("key", {count: count})}&nbsp;
							{/* Encontramos {count} resultados para: */}
						{termo ?? <span className="search-result-termo"> {termo}</span>}
						<span className="search-result-termo">
							{termsAssistant ?
								termsAssistant.map((termo, index) => {
									return (
										<>
											{index != 0 && termo ? <NavigateNextIcon fontSize="small" /> : ''}
											{termo ? t(termo) : ''}
										</>
									)
								})
								: ''}
						</span>
					</h3>
				</div>

				<div className="shape-divider-bottom-header-faq">
					<svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
						<path d="M985.66,92.83C906.67,72,823.78,31,743.84,14.19c-82.26-17.34-168.06-16.33-250.45.39-57.84,11.73-114,31.07-172,41.86A600.21,600.21,0,0,1,0,27.35V120H1200V95.8C1132.19,118.92,1055.71,111.31,985.66,92.83Z" className="shape-fill"></path>
					</svg>
				</div>

				<img src={linhaSeparator} className="separator-linha-bottom" alt="" />
			</div>

			<div className="page-content">
				<ProductList produtos={produtos} />
			</div>
		</>
	)
}

export default PesquisaProdutos;