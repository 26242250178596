import { useCallback, useState } from "react";
import NewPassword from "./newPassword";
import PasswordRecover from "./passwordRecover";
import RequestAccess from "./requestAccess";
import UserLogin from "./userLogin";

function Login() {

    const [activeComponent, setActiveComponent] = useState("login");

    const modifyActiveComponent = useCallback(newActiveComponent => {
        setActiveComponent(newActiveComponent);
    }, [setActiveComponent]);


    return (
        <div className="editor-login">

            {activeComponent == "login" && <UserLogin modifyActiveComponent={modifyActiveComponent} />}
            {activeComponent === "esqueci-senha" && <PasswordRecover modifyActiveComponent={modifyActiveComponent} />}
            {activeComponent === 'criar-nova-senha' && <NewPassword modifyActiveComponent={modifyActiveComponent} />}
            {activeComponent === "solicitar-acesso" && <RequestAccess modifyActiveComponent={modifyActiveComponent} />}

        </div>

    );
}

export default Login;