import { Dialog, DialogContent, DialogProps, DialogTitle, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';
import React from 'react';

function ModalMensagensGerais(props: { 
    open: boolean, 
    handleClose: Function, 
    scroll: DialogProps['scroll']
}) {

    const { t } = useTranslation('termoConsentimento')
    
    return (
        <Dialog
            open={props.open}
            onClose={() => props.handleClose()}
            scroll={props.scroll}
            fullWidth
            maxWidth={"lg"}
        >
            <DialogTitle >
                <IconButton onClick={() => props.handleClose()}>
                    <CloseIcon />
                </IconButton>
            </DialogTitle>

            <DialogContent dividers={props.scroll === 'paper'}>
                <div>
                    <h1>{t("TERMO DE CONSENTIMENTO PARA TRATAMENTO DE DADOS PESSOAIS")}</h1>
                    <p>{t("Através do presente Termo de Consentimento para Tratamento de Dados Pessoais, o Titular de Dados Pessoais consente e concorda, de forma livre, inequívoca e informada, que seus Dados Pessoais sejam objeto de operações conduzidas pela (Auto Adesivos Paraná S.A. (“Colacril Office”)) e Terceiros em seu nome, no sentido de coletar, produzir, receber, classificar, utilizar, acessar, reproduzir, transmitir, distribuir, processar, arquivar, armazenar, eliminar, avaliar ou controlar, modificar, comunicar, transferir, difundir ou extrair, de acordo com os limites impostos pela legislação vigente, exclusivamente para as finalidades abaixo descritas.")}</p>

                    <br />

                    <p>{t("O presente Consentimento pressupõe o prévio conhecimento do Titular sobre a Política de Proteção de Dados Pessoais da Controladora, disponível no link https://www.contatoseguro.com.br/beontag, que desde já passa a ser considerado anexo ao presente Consentimento.")}</p>

                    <br />

                    <ol>
                        <li>
                            {t("Finalidades específicas para o tratamento dos dados pessoais: ")}
                            <p>
                                {t("Os dados pessoais serão tratados para as seguintes finalidades: ")}
                            </p>

                            <ul>
                                <li>
                                    {t("Realização de ações de marketing para promover e divulgar produtos e promoções de todas as linhas de produtos da (Auto Adesivos Paraná S.A. (“Colacril Office”)) por e-mail ou correio;")}
                                </li>

                                <li>
                                    {t("Realização de ações de marketing para promover e divulgar produtos e promoções de todas as linhas de produtos da (Auto Adesivos Paraná S.A. (“Colacril Office”)) por WhatsApp e mensagens de texto no número de celular fornecido;")}
                                </li>

                                <li>
                                    {t("Realização de ações de marketing para promover e divulgar lançamentos e novos produtos da (Auto Adesivos Paraná S.A. (“Colacril Office”)) por e-mail ou correio;")}
                                </li>

                                <li>
                                    {t("Envio de mensagens sobre produtos ou serviços da (Auto Adesivos Paraná S.A. (“Colacril Office”)) por WhatsApp e mensagens de texto no número de celular fornecido;")}
                                </li>

                                <li>
                                    {t("Comunicados sobre novidades e/ou eventos proporcionados pela (Auto Adesivos Paraná S.A. (“Colacril Office”)) por e-mail ou correio;")}
                                </li>

                                <li>
                                    {t("Comunicados sobre novidades e/ou eventos proporcionados pela (Auto Adesivos Paraná S.A. (“Colacril Office”)) por WhatsApp e mensagens de texto no número de celular fornecido.")}
                                </li>
                            </ul>
                        </li>

                        <li>
                            {t("Dados pessoais tratados: ")}
                            <p>
                                {t("Para atingir cada uma das finalidades específicas acima, fica autorizada a empresa (Auto Adesivos Paraná S.A. (“Colacril Office”)) a tomar decisões referentes aos tratamentos dos seguintes dados pessoais: nome, endereço, endereço de e-mail e número de telefone, país, estado, cidade, currículo.")}
                            </p>
                        </li>

                        <li>
                            {t("Compartilhamento com Terceiros: ")}
                            <p>
                                {t("A empresa (Auto Adesivos Paraná S.A. (“Colacril Office”)) fica autorizada a compartilhar os dados com outros agentes de tratamento quando o compartilhamento for necessário para atingir as finalidades indicadas no item “1”. Algumas das categorias de terceiros com os quais haverá compartilhamento de dados, envolvem: (i) empresas que forneçam serviço de e-mail marketing; (ii) sistemas, provedores de e-mail e plataformas nas quais ocorre o processamento e armazenamento de dados utilizados pela (Auto Adesivos Paraná S.A. (“Colacril Office”)) para tratar dados de clientes, clientes em processo de prospecção e qualquer titular de dados que forneça os dados de forma livre, informada e inequivocamente para receber informações da (Auto Adesivos Paraná S.A. (“Colacril Office”))  via e-mail; (iii) Terceiros que representam a (Auto Adesivos Paraná S.A. (“Colacril Office”)) comercialmente; (iv) Terceiros que elaboram os avisos e comunicados digitais divulgados nos e-mails; (v) Terceiros que auxiliam a (Auto Adesivos Paraná S.A. (“Colacril Office”)) no atendimento ao cliente.")}
                            </p>
                        </li>

                        <li>
                            {t("Transferência Internacional de dados")}
                            <p>
                                {t("O titular está ciente e de acordo com o fato de que o compartilhamento com os terceiros indicados no item “3” pode resultar em compartilhamento internacional de dados especialmente no que tange aos servidores de sistemas e plataformas de processamento e armazenamento de dados pessoais situados fora do território brasileiro.")}
                            </p>
                        </li>

                        <li>
                            {t("Término do tratamento dos dados")}
                            <p>
                                {t("A (Auto Adesivos Paraná S.A. (“Colacril Office”)) poderá manter os dados pessoais durante todo o período em que forem necessários para atingir as finalidades específicas indicadas no item “1” e enquanto houver consentimento válido (não revogado) ou enquanto subsistir outra base legal que suporte a manutenção dos dados pessoais.")}
                            </p>
                        </li>

                        <li>
                            {t("Segurança dos dados")}
                            <p>
                                {t("A (Auto Adesivos Paraná S.A. (“Colacril Office”)) adotará todas as medidas técnicas, físicas, administrativas e organizacionais necessárias para garantir a segurança dos dados pessoais contra acessos indevidos, perda, alteração, destruição, comunicação ou outras formas de tratamento que não sejam adequadas com as finalidades descritas no item “1” e com a Lei Geral de Proteção de Dados Pessoais (Lei nº 13.709/2018) e posterior regulamentação.")}
                            </p>
                        </li>

                        <li>
                            {t("Direitos do titular")}
                            <p>
                                {t("O titular dos dados pessoais tem o direito de obter da (Auto Adesivos Paraná S.A. (“Colacril Office”)): ")}
                            </p>

                            <ul>
                                <li>{t("Confirmação sobre existência de tratamento de dados;")} </li>
                                <li>{t("Acesso aos seus dados;")}</li>
                                <li>{t("Correção de dados incompletos, inexatos ou desatualizados;")}</li>
                                <li>{t("Portabilidade dos dados para outro Controlador, mediante requisição expressa;")}</li>
                                <li>{t("Anonimização, bloqueio ou eliminação de dados, caso tratados em desconformidade a lei;")}</li>
                                <li>{t("Eliminação dos dados tratados mediante o presente consentimento;")}</li>
                                <li>{t("Informação sobre a possibilidade de não fornecer o consentimento e as consequências da negativa;")}</li>
                                <li>{t("Revogação do consentimento.")}</li>
                            </ul>

                            <p>
                                {t("O titular pode apresentar requisição para exercício de quaisquer dos direitos indicados acima através do canal https://www.contatoseguro.com.br/beontag.")}
                            </p>
                        </li>

                        <li>
                            {t("Revogação do consentimento")}
                            <p>
                                {t("O titular pode, a qualquer momento, requerer e obter a revogação do consentimento ora conferido à (Auto Adesivos Paraná S.A. (“Colacril Office”)), mediante requisição enviada ao canal Telefone SAC 0800 4400064 ou ao site https://www.contatoseguro.com.br/beontag.")}
                            </p>

                            <p>
                                {t("A revogação do consentimento acarretará a interrupção das atividades de tratamento de dados indicados no item “1”.")}
                            </p>

                            <p>
                                {t("A interrupção das operações de tratamento em decorrência da revogação de consentimento não implica necessariamente em eliminação dos dados pessoais do titular, salvo quando não houver nenhuma outra base legal que suporte a manutenção desses dados.")}
                            </p>

                            <p>
                                {t("Os tratamentos realizados sob amparo do consentimento permanecerão válidos, legítimos e ratificados.")}
                            </p>

                            <p>
                                {t("São Paulo, 25 de maio de 2021.")}
                            </p>
                        </li>
                    </ol>
                </div>
            </DialogContent>
        </Dialog>
    )
}

export default ModalMensagensGerais;