import React, { createContext, useCallback, useState } from 'react';
import INotificationContext from '../../interfaces/alertContext';
import IAlertMessage from '../../interfaces/alert';
import { IconButton, Snackbar } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const NotificationContext = createContext<INotificationContext | undefined>(undefined);

const NotificationContextProvider: React.FunctionComponent<{children: React.ReactElement}> = (props) => {
    const [alertApp, setAlertApp] = useState<IAlertMessage>();

    const handleCloseApp = useCallback(() => {
        setAlertApp((prevState:any) => {
            return {
                ...prevState,
                isOpen: false
            }
        });
    }, []);

    const showAlertApp = function (message:string, severity: "error"|"info"|"warning"|"success", timeout?:number, action?: JSX.Element) {
        setAlertApp({ message, severity, timeout, isOpen: true, action });
    }


    const messageContext = {
        showAlertApp,
    }


    return (
        <NotificationContext.Provider value={messageContext}>
            {props.children}

            <Snackbar
                open={alertApp?.isOpen}
                autoHideDuration={alertApp?.timeout}
                onClose={alertApp?.timeout ? handleCloseApp : undefined}
                message={alertApp?.message}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                className={alertApp?.severity}
                action={
                    alertApp?.action ||
                    <IconButton
                        size="small"
                        aria-label="close"
                        color="inherit"
                        onClick={handleCloseApp}
                    >
                        <CloseIcon fontSize="small" />
                    </IconButton>
                  }
            />
    
        </NotificationContext.Provider>
    );
}

export function useMessage(): INotificationContext {
    const context = React.useContext(NotificationContext)
    if (context === undefined) {
        throw new Error('useMessage must be used within a NotificationContextProvider')
    }
    return context
}

export default NotificationContextProvider