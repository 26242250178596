import { useCallback } from "react";
import { useBoardContext } from "@beontag/tageditor";
import { Button } from "@mui/material";
import { Image, Portrait } from "@mui/icons-material";
import React from "react";

const Orientation = () => {
    const { board } = useBoardContext();
    
    const toggleOrientation = useCallback((value: number) => {
        if(board) {
            board.angle = value;
        }
    }, [board]);

    return (
        <>
            <Button 
                className='btn' 
                startIcon={<Image />}
                onClick={() => toggleOrientation(0)}
                disabled={board?.angle === 0}
            > 
                Paisagem
            </Button>
            
            <Button
                className='btn' 
                startIcon={<Portrait />}
                onClick={() => toggleOrientation(90)}
                disabled={board?.angle === 90}
            > 
                Retrato
            </Button>
        </>
    );
};

export default Orientation;
