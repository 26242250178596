import React from 'react';

export default function CustomDot(props: {onClick:Function, active: boolean, index:number, carouselState:any})  {
    const { currentSlide } = props.carouselState;

    return (
        <li className={`custom-dot-list ${props.active ? 'active-custom-dot' : ''}`}>
            <button
                className='custom-dot-button'
                title={props.index.toString()}
                onClick={() => props.onClick()}
            />
        </li>
    )
}