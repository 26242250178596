import { useCallback, useEffect } from "react";
import { objectFactory, useBoardContext, useKeysCombinationHandler } from "@beontag/tageditor";
import { useClipboardContext } from "./ClipboardContext";
import { Button } from "@mui/material";
import { ContentPaste } from "@mui/icons-material";
import React from "react";

export interface PasteProps {
    pasteDisplacement: number;
}

const Paste: React.FunctionComponent<PasteProps> = (props) => {
    const { board, boardHistory } = useBoardContext();
    const { clipboard } = useClipboardContext();

    const paste = useCallback(() => {
        if (board && boardHistory && clipboard?.length) {
            clipboard.forEach(obj => {
                obj.set({
                    left: obj.left! + props.pasteDisplacement,
                    top: obj.top! + props.pasteDisplacement,
                });
            });

            objectFactory.Object.clone(clipboard, undefined).then(clones => {
                boardHistory.runPaused(() => {
                    clones.forEach(c => board.add(c))
                })

                // board.fireModified(...clones);

                board.setActiveObjects(...clones);

            });
        }
    }, [props.pasteDisplacement, board, boardHistory, clipboard]);

    const CtrlVPressCount = useKeysCombinationHandler(["Control", "V"]);
    useEffect(() => {
        if (CtrlVPressCount) {
            paste();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [CtrlVPressCount]);

    return (
        <Button 
            className='btn' 
            startIcon={<ContentPaste />}
            onClick={paste}
            disabled={!clipboard?.length}
        > 
            Colar
        </Button>
    );
};

export default Paste;
