import { Box, Button, Grid } from "@mui/material";
import { ISubCategoria } from "../../../../interfaces/produto/subCategoria";
import { useEffect, useState } from "react";
import { IFormEditorFiltroProdutos } from "../../../../interfaces/editor/form-filtro-produtos";
import { useEditorContext } from "../../../../context/EditorContext";
import TabelaProdutosEditor from "./tabelaProdutosEditor";
import FiltrosTabelaProdutosEditor from "./filtrosTabelaProdutosEditor";
import React from "react";

function TemplateSelection() {
	const { selecionarProduto, subCategorias } = useEditorContext();
 
    const [subCategoriaSelecionada, setSubCategoriaSelecionada] = useState<ISubCategoria>(subCategorias[0]);

    const [form, setForm] = useState<IFormEditorFiltroProdutos>({
		nomeCodigo: '',
        altura: '',
        largura: '',
        formato: ''
	});

    useEffect(() => {
        clearFilters();
    }, [subCategoriaSelecionada])


    const clearFilters = () => {
        setForm({
            nomeCodigo: '',
            altura: '',
            largura: '',
            formato: ''
        });
    }

    const handleInputChange = (e: any) => {
		setForm({ ...form, [e.target.name]: e.target.value });
	}

    const handleProductFiltering = () => {
        let produtos = subCategoriaSelecionada?.produtos ?? [];
        
        if (form.nomeCodigo.trim().length > 0) {
            produtos = produtos.filter(prod => {
                return (
                    prod.nome.toUpperCase().indexOf(form.nomeCodigo.toUpperCase()) > -1
                    || 
                    prod.items.some(item => item.codigo.toUpperCase().indexOf(form.nomeCodigo.toUpperCase()) > -1)
                );
            });
        }

        if (form.largura.trim().length > 0) {
            produtos = produtos.filter(prod => {
                const dimensoes = prod.dimensao.toUpperCase().split('X') ?? [];
                // @ts-ignore
                return dimensoes.length > 0 && dimensoes.shift().indexOf(form.largura) > -1;
            });
        }

        if (form.altura.trim().length > 0) {
            produtos = produtos.filter(prod => {
                const dimensoes = prod.dimensao.toUpperCase().split('X') ?? [];
                // @ts-ignore
                return dimensoes.length > 0 && dimensoes.pop().indexOf(form.altura) > -1;
            });
        }

        if (form.formato.trim().length > 0) {
            produtos = produtos.filter(prod => prod.forma.toUpperCase() === form.formato.toUpperCase());
        }

        return produtos;
    }


    return (
        <>
            <Box className='editor-menu'>
                {
                    subCategorias.map((subcategoria) => {
                        if (subcategoria.produtos && subcategoria.produtos.length > 0) {
                            return (
                                <div className="menu-item" key={subcategoria.idCategoria}>
                                    <Button
                                        className={`btn ${subCategoriaSelecionada == subcategoria ? 'btn-contained-primary' : ''}`} 
                                        onClick={() => { setSubCategoriaSelecionada(subcategoria)}}
                                    >
                                        {subcategoria.nome}
                                    </Button>
                                </div>
                            );
                        }
                    })
                }
            </Box>

            <Grid container display='flex' justifyContent='center'>
                <Grid item md={11} xs={12}>

                    <FiltrosTabelaProdutosEditor
                        handleInputChange={handleInputChange}
                        form={form}
                        subCategoriaSelecionada={subCategoriaSelecionada}
                    />

                    <TabelaProdutosEditor
                        handleProductFiltering={handleProductFiltering}
                        selecionarProduto={selecionarProduto}
                    />
                    
                </Grid>
            </Grid>

            
        </>
    );
}


export default TemplateSelection;