import { IconButton, Paper, Tooltip } from "@mui/material";

import React from "react";
import { useEditorContext } from "../../../../context/EditorContext";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

function DadosEtiqueta() {

    const { produtoSelecionado } = useEditorContext();

    return (
        
        <div className="statusbar-container info-container">
            
            <Tooltip
                classes={{ tooltip: 'produto-info-tooltip' }}
                placement="top-start"
                title={
                    <React.Fragment>
                        <Paper elevation={2}>
                        <aside className="editor-menu-prod-selecionado-info-container">
                            <div className="editor-menu-prod-selecionado-content">
                                
                                <div className="editor-menu-prod-selecionado-data">
                                    <b>Produto selecionado:</b>
                                    {/* @ts-ignore */}
                                    <p>{produtoSelecionado?.nome} {produtoSelecionado?.dimensao}</p>
                                    <p>
                                        {/* @ts-ignore */}
                                        {produtoSelecionado?.items.map((item, index) => {
                                            return (
                                                <b key={index}>{index != 0 ? ' / ' : ''}{item.codigo}</b>
                                            )
                                        })}
                                        <b>{produtoSelecionado?.codigo}</b>
                                    </p>
                                    <p>{produtoSelecionado?.forma}</p>
                                    <p><b>{produtoSelecionado?.folhas}</b></p>
                                </div>
                            </div>

                        </aside>
                        </Paper>
                    </React.Fragment>
                }
            >
                <IconButton
                    onClick={() => undefined}
                    size='small'
                    className={`cursor-button`}
                >
                    <InfoOutlinedIcon />
                </IconButton>
            </Tooltip>
            
        </div>
    );
}

export default DadosEtiqueta;