import { useCallback, useState } from "react";
import { useBoardContext } from "@beontag/tageditor";
import { Button, ListItemIcon, ListItemText, Menu, MenuItem } from "@mui/material";
import { KeyboardArrowDown, Rotate90DegreesCcw, RotateLeft, RotateRight } from "@mui/icons-material";
import React from "react";

const Rotate = () => {
    const { board, boardHistory } = useBoardContext();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    
    const rotate = useCallback((angle: number) => {
        if(board && boardHistory) {
            const selection = board.activeSelection ?? board.activeObjects[0]

            selection.rotate((selection.angle ?? 0) + angle)

            //board.fireModified(selection);

            board.requestRenderAll();
        }
    }, [board, boardHistory]);

    return (
        <>
            <Button
                className='btn' 
                startIcon={<Rotate90DegreesCcw />} 
                endIcon={<KeyboardArrowDown />}
                onClick={e => setAnchorEl(e.currentTarget)}
                disabled={!board?.activeObjects.length}
            > 
                Rotação
            </Button>

            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={() => setAnchorEl(null)}
                PaperProps={{
                    className: 'menu-dropdown'
                }}
                transformOrigin={{ 
                    horizontal: 'left', 
                    vertical: 'top'
                }}
                anchorOrigin={{ 
                    horizontal: 'left', 
                    vertical: 'bottom'
                }}
            >
                <MenuItem onClick={() => rotate(90)}>
                    <ListItemIcon>
                        <RotateRight fontSize="small" />
                    </ListItemIcon>

                    <ListItemText> 90º Direita </ListItemText>
                </MenuItem>

                <MenuItem onClick={() => rotate(-90)}>
                    <ListItemIcon>
                        <RotateLeft fontSize="small" />
                    </ListItemIcon>

                    <ListItemText> 90º Esquerda </ListItemText>
                </MenuItem>
            </Menu>
        </>
    );
};

export default Rotate;
