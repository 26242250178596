import { Drafts } from "@mui/icons-material";
import { Button } from "@mui/material";
import { useCallback, useState } from "react";
import { AddDataFieldFeature, AddDataFieldFeatures, IAddDataFieldOptions, useBoardContext } from "@beontag/tageditor";
// Prevent use eval function on exceljs (https://github.com/exceljs/exceljs/issues/1426#issuecomment-903548408)
import 'regenerator-runtime/runtime';
import ExcelJS from 'exceljs';
import { useEditorContext } from "../../../../../context/EditorContext";
import { useMessage } from "../../../../../context/alertContext/context";
import React from "react";

interface IReadWorkbook {
    error?: string;
    source?: IAddDataFieldOptions
}

export default function DirectMail(props: { setMenuAnchorEl: Function }) {

    const [dataFieldFeature, setDataFieldFeature] = useState<AddDataFieldFeatures>();

    const { boardConfig } = useBoardContext();
    const { numberOfPages, setNumberOfPages } = useEditorContext();

    const { showAlertApp } = useMessage();

    const importDataField = useCallback(async (e: any) => {
        const accept = ".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
        const files = await openFiles(accept);
        if (files?.length) {
            showAlertApp('Carregando...', 'info')
            const buffer = await readAsArrayBufferAsync(files[0])
            const readWorkbook = await readWorkbookAsync(buffer);
            if (readWorkbook.error) {
                //alert(readWorkbook.error);
                showAlertApp(`Erro ao carregar: ${readWorkbook.error}`, 'error', 5000)

            } else if (readWorkbook.source) {
                showAlertApp('Carregado com sucesso', 'success', 5000)
                dataFieldFeature?.add(readWorkbook.source);
                props.setMenuAnchorEl(e.target)
            }
        }
    }, [dataFieldFeature]);

    const openFiles = async (accept: string): Promise<FileList> => {
        return new Promise<FileList>((resolve, reject) => {
            const inputEl = document.createElement("input");
            inputEl.accept = accept;
            inputEl.type = "file";
            inputEl.hidden = true;
            inputEl.onchange = async (e) => {
                const element = e.target as HTMLInputElement;
                if (element?.files?.length) {
                    resolve(element.files);
                } else {
                    reject();
                }
            };
            document.body.appendChild(inputEl); // required for firefox
            inputEl.click();
            inputEl.remove();
        });
    }

    const readAsArrayBufferAsync = (file: File) => {
        return new Promise<ArrayBuffer>((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = () => {
                resolve(reader.result as ArrayBuffer);
            };
            reader.readAsArrayBuffer(file);
        });
    };

    const readWorkbookAsync = async (buffer: ArrayBuffer): Promise<IReadWorkbook> => {
        let workbook: ExcelJS.Workbook;
        try {
            workbook = await new ExcelJS.Workbook().xlsx.load(buffer);
        } catch (e) {
            console.warn(e);
            return { error: 'Não foi possível ler a planilha.' };
        }

        if (workbook.worksheets.length === 0) {
            return { error: 'A planilha deve possui pelo menos uma aba.' };
        }

        const worksheet = workbook.worksheets[0];
        if (worksheet.rowCount <= 1) {
            return { error: 'A primeira aba deve possui mais de uma linha.' };
        }

        const headers = worksheet.getRows(1, 1);
        if (!headers || !headers.length) {
            return { error: 'A primeira aba deve possui pelo menos uma coluna.' };
        }

        const source: IAddDataFieldOptions = {
            dataSource: {
                keys: [],
                values: []
            }
        };

        let colNumbers: number[] = []
        const header = headers[0];
        header.eachCell((cell, colNumber) => {
            if (cell.value) {
                colNumbers.push(colNumber);
                source.dataSource.keys!.push(cell.value.toString());
            }
        });

        const dataRows = worksheet.getRows(2, worksheet.rowCount)!;
        dataRows.forEach(row => {
            const rowValues: string[] = [];
            for (const colNumber of colNumbers) {
                const cell = row.getCell(colNumber);
                let cellValue = cell.value || "";
                if (cellValue instanceof Date) {
                    cellValue = cellValue.toLocaleDateString('pt-BR', { timeZone: 'Etc/UTC' })
                }
                if (cellValue.hasOwnProperty('richText')) {
                    let text = '';
                    cellValue.richText.map((item: any) => {
                        text = `${text}${item.text}`
                    })
                    cellValue = text;
                }
                rowValues.push(cellValue.toString());
            }
            source.dataSource.values!.push(rowValues);
        });

        addPages(worksheet.rowCount);

        return { source };
    };

    const addPages = (rowsWorksheet: number) => {

        if (boardConfig && boardConfig.page) {
            const tagsPerPage = boardConfig.page.rows * boardConfig.page.columns;
            const ceilPagesNeeded = Math.ceil(rowsWorksheet / tagsPerPage)
            if (ceilPagesNeeded > numberOfPages) {
                setNumberOfPages(ceilPagesNeeded)
            }
        }
    }

    return (
        <>
            <div className="menu-item">
                <AddDataFieldFeature featureUpdate={f => setDataFieldFeature(f)}>
                    <Button
                        className='btn'
                        startIcon={<Drafts />}
                        component="label"
                        onClick={(e) => importDataField(e)}
                    >
                        Mala Direta
                    </Button>
                </AddDataFieldFeature>
            </div>
        </>
    )
}