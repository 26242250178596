import { Button, IconButton } from '@mui/material';
import { useCallback, useState } from 'react';
import FaleConosco from '../../../components/contacts-forms/fale_conosco';
import TelaAgradecimento from '../../../components/contacts-forms/tela_agradecimento';
import TrabalheConosco from '../../../components/contacts-forms/trabalhe_conosco';
import ContentHeaderImage from '../../../components/common/content-header-image';
import CaixaTexto from '../../../components/common/conteudo-caixa-texto';
import DetalhesProduto from '../../../components/produtos/dados-produto';
import imageContato from '../../../assets/contato-imagem-top.webp'
import linhaSeparator from '../../../assets/separadores/separador-pagina-contato.webp'
import { useTranslation } from 'react-i18next';

import TelaAgradecimentoTc from '../../../components/contacts-forms/tela_agradecimentoTc';
import CloseIcon from '@mui/icons-material/Close';

import { Snackbar } from '@mui/material';

import { useMessage } from '../../../context/alertContext/context';
import React from 'react';


function Contato() {
  const [activeComponent, setActiveComponent] = useState("fale-conosco");

  const { showAlertApp } = useMessage();

  const modifyActiveComponent = useCallback(newActiveComponent => {
      setActiveComponent(newActiveComponent);
  }, [setActiveComponent]);


  const { t } = useTranslation(["contato", "lgpd", "termoConsentimento"])

  return (
    <>
      <ContentHeaderImage
        titulo=""
        subtitulo=""
        image={imageContato}
        extraImageClass="content-header-image-contato"
      >
        <div className="shape-divider-bottom-header-contato">
          <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
            <path d="M1200 120L0 16.48 0 0 1200 0 1200 120z" className="shape-fill"></path>
          </svg>
        </div>
      </ContentHeaderImage>

      {activeComponent !== 'submited' &&
        <div className='contato-index-buttons'>
          <Button
            className={`btn contato-index-buttons_btn ${activeComponent === "fale-conosco" ? 'btn-contained-primary' : 'btn-outlined-secondary'}`}
            variant={`${activeComponent === "fale-conosco" ? 'contained' : 'outlined'}`}
            onClick={() => modifyActiveComponent("fale-conosco")}
            disableElevation
          >
            {t("Fale Conosco")}
          </Button>

          <Button
            className={`btn contato-index-buttons_btn ${activeComponent === "trabalhe-conosco" ? 'btn-contained-primary' : 'btn-outlined-secondary'}`}
            variant={`${activeComponent === "trabalhe-conosco" ? 'contained' : 'outlined'}`}
            onClick={() => modifyActiveComponent("trabalhe-conosco")}
            disableElevation
          >
            {t("Trabalhe Conosco")}
          </Button>
        </div>
      }

      {activeComponent === "trabalhe-conosco" && <TrabalheConosco modifyActiveComponent={modifyActiveComponent} />}

      {activeComponent === "fale-conosco" && <FaleConosco modifyActiveComponent={modifyActiveComponent} />}

      {activeComponent === "submited" && <TelaAgradecimento />}

      {activeComponent === "submitedTc" && <TelaAgradecimentoTc />}

      <div className='detalhes-contato-box-container'>
        <DetalhesProduto>
          <CaixaTexto title={t("ESCRITÓRIO NO BRASIL")}>
            <>
              <p>{t("Rua Iaiá, nº 77")}</p>
              <p>{t("Itaim Bibi - Sao Paulo/SP")}</p>
              <p>{t("CEP: 04542-0600")}</p>
              <p>{t("Telefone +55 11 3706-9800")}</p>
            </>
          </CaixaTexto>

          <CaixaTexto title={t("FÁBRICA NO BRASIL")}>
            <>
              <p>{t("Av. Presidente Kennedy, 2427")}</p>
              <p>{t("Jd. Lar paraná - Campo Mourão/PR")}</p>
              <p>{t("CEP: 87306-000")}</p>
              <p>{t("Telefone: +55 44 3518-3500")}</p>
            </>
          </CaixaTexto>
        </DetalhesProduto>

        <div className="shape-divider-bottom-detalhes-box-contato">
          <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
            <path d="M 0 0 V 25 c 5 1 291 111.46 735 90 c 442 2.74 457 -73.26 497 -77 V 0 Z" className="shape-fill"></path>
          </svg>
        </div>

        <img aria-hidden="true" src={linhaSeparator} className="separator-linha-top" />
      </div>

    </>
  );
}

export default Contato;