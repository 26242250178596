// @ts-nocheck
import { useEffect, useState, useRef } from 'react';

import CategoriaProdutos from '../../../components/produtos/botoes-categoria-produtos-colacril';
import { Button } from '@mui/material';
import AltText from '../../../components/common/altText';
import { useTranslation } from 'react-i18next';
import sProduto from '../../../services/produtoService'
import ProductList from '../../../components/produtos/produto-list';
import ProductFilters from '../../../components/produtos/productFIlters';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import bg_image from '../../../assets/pattern_colacril_assistente_busca.webp'
import { IProdutosCategoria } from '../../../interfaces/produto/produtosCategoria';
import { splitter } from '../../../helpers/splitter';
import { ICategoria } from '../../../interfaces/produto/categoria';
import { IFiltros } from '../../../interfaces/produto/filtros';
import { Link, useOutletContext, useParams, useSearchParams } from 'react-router-dom';

function Categoria() {

  const [searchParams, setSearchParams] = useSearchParams();
  const { categoria } = useParams();
  const { i18n } = useTranslation();

  const formRef = useRef<HTMLFormElement>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [dados, setDados] = useState<IProdutosCategoria | undefined>();

  const [selectedSubcategory, setSelectedSubcategory] = useState<number>(dados?.selecionada?.idCategoria ?? 0);
  const categorias: ICategoria[] = useOutletContext();

  const getAlias = () => {
    return categorias.find(x => x.idCategoria === selectedSubcategory)?.alias || categorias[0]?.subCategorias.find(x => x.idCategoria === selectedSubcategory)?.alias;
  }

  const [categoryAlias, setCategoryAlias] = useState<string>(getAlias() || '')

  const nomeCategoria = dados?.categoria?.nome;
  const { t } = useTranslation('categoria')
  const name = splitter(nomeCategoria ?? '')

  const handleSetFiltros = () => {
    // Obtem os filtros de uma dada categoria de acordo com a categoria/subcategoria selecionada
    return categorias.find(x => x.idCategoria === selectedSubcategory)?.filtros || categorias[0]?.subCategorias.find(x => x.idCategoria == selectedSubcategory)?.filtros;
  }

  const [filtros, setFiltros] = useState(handleSetFiltros());

  const setInitialFiltroValues = () => {
    let filtrosValues: { [key: string]: string } = {};
    // Para cada filtro existente, se houver, obtem os filtros vindos da request/do estado e monta um objeto de estado para uso nos Selects
    filtros?.forEach((filtroItem) => {
      let value = dados?.filtro?.find((x: IFiltros) => x.typeName === filtroItem.typeName)?.listEnum[0].value ?? "";
      filtrosValues = { ...filtrosValues, [filtroItem.typeName]: value }
    })

    return filtrosValues;
  }

  // Resetamos todos os valores dos filtros para evitar dados restantes ao trocarmos de categoria
  const resetFilterValues = (newFilters?: { [key: string]: string }) => {
    let filtrosValues: { [key: string]: string } = {};
    filtros?.forEach((filtroItem) => {
      filtrosValues = { ...filtrosValues, [filtroItem.typeName]: '' }
    })

    setFiltroValue(filtrosValues)
  }

  useEffect(() => {
    setFiltros(handleSetFiltros())
  }, [categorias])

  useEffect(() => {
    setFiltroValue(setInitialFiltroValues())
  }, [filtros])

  const [filtroValue, setFiltroValue] = useState<{ [key: string]: string }>(setInitialFiltroValues())

  const modifySelectedCategory = (newSubcategory: number) => {
    setFiltros(handleSetFiltros())
    resetFilterValues()
    setSelectedSubcategory(newSubcategory);
  }

  const getProdutos = () => {
    setIsLoading(true)
    const filter = Object.fromEntries(searchParams) as { [key: string]: string }
    sProduto.listProduto(i18n.language, filter, categoria).then((resp) => {
      setDados(resp.data)
      setSelectedSubcategory(resp.data.selecionada?.idCategoria)
    }).finally(() => setIsLoading(false))

  }

  useEffect(() => {
    getProdutos()
  }, [categoria])

  useEffect(() => {
    let filtrosTemp = handleSetFiltros()
    setFiltros(filtrosTemp)
    setCategoryAlias(getAlias() || '')
    const filter = Object.fromEntries(searchParams) as { [key: string]: string }
    setFiltroValue(filter)

}, [selectedSubcategory])

  // Ao acontecer uma mudança em um dos Selects, atualiza o valor
  const handleInputChange = (e: any) => {
    setFiltroValue({ ...filtroValue, [e.target.name]: e.target.value });
  };

  const clearInput = (inputName: string) => {
    setFiltroValue({ ...filtroValue, [inputName]: '' })
    // if (searchParams.has(inputName)) {
    //   searchParams.delete(inputName);
    //   setSearchParams(searchParams);
    // }
  }

  const handleFiltrarButton = () => {
    let filtroParams = {}
    if (filtros) {
      for (const filtro of filtros) {
        filtroParams[filtro.typeName] = filtroValue[filtro.typeName] ?? ''
      }
    }
    setSearchParams(filtroParams)
  }

  useEffect(() => {
    getProdutos()
    const filter = Object.fromEntries(searchParams) as { [key: string]: string }
    setFiltroValue(filter)
  }, [searchParams])


  return (
    <>
      <div className='product-category-header-container'>
        <div className='product-category-header-text'>
          <h1><AltText>{name[0]}</AltText> {name[1]}</h1>
          {/* @ts-ignore */}
          <h3>{dados?.categoria?.descricao}</h3>
        </div>
      </div>

      <div className='page-content'>
        <div className="product-categories-button-container">
          <form
            method="get"
            action={`/categoria/${categoryAlias}`}
            ref={formRef}
          >

            {/* @ts-ignore */}
            {dados?.categoria.subCategorias &&

              <CategoriaProdutos

                subCategorias={dados.categoria.subCategorias}
                selectedSubcategory={selectedSubcategory}
                modifySelectedCategory={modifySelectedCategory}
                handleInputChange={handleInputChange}
              />

            }

            {Object.keys(filtroValue).length > 0 &&
              <ProductFilters
                filtros={filtros}
                values={filtroValue}
                handleInputChange={handleInputChange}
                clearInput={clearInput}
                t={t}
                onFilterPress={handleFiltrarButton}
              />
            }

          </form>
        </div>

        {/* @ts-ignore */}
        {!isLoading ? <ProductList produtos={dados.produtos} /> :
          <div className='page-error-container'>
            <h1>Carregando...</h1>
          </div>
        }
      </div>

      <div className='content-footer-container'>
        <img alt="" src={bg_image} className="content-header-image" />

        <div className='content-header-image-text black-text'>
          <h1>{t("Não encontrou o que queria?")}</h1>
          <h3 className="content-footer-h3">{t("Conte com o nosso assistente de busca e veja o produto que melhor atende sua necessidade")}</h3>

          <Link
            to="/assistente" className='btn-link'>
            <Button
              variant="contained"
              className="btn btn-contained-primary"
              disableElevation
            >
              {t("ASSISTENTE DE BUSCA")}
            </Button>
          </Link>

        </div>

        <div className="shape-divider-top-try-assistant-homepage">
          <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
            <path d="M1200 120L0 16.48 0 0 1200 0 1200 120z" className="shape-fill"></path>
          </svg>
        </div>
      </div>
    </>
  );
}

export default Categoria;
