import { AddImageFeature, AddImageFeatures } from "@beontag/tageditor";
import { Image } from "@mui/icons-material";
import { Button } from "@mui/material";
import { useCallback, useState } from "react";
import PropertyBar from "../propertyBar";
import React from "react";

export default function Images(props: { setMenuAnchorEl: Function }) {

    const [imageFeature, setImageFeature] = useState<AddImageFeatures>();
    const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);

    const addImage = useCallback(() => {
        const inputEl = document.createElement("input");
        inputEl.accept = "image/*";
        inputEl.type = "file";
        inputEl.hidden = true;
        inputEl.onchange = (e) => {
            const element = e.target as HTMLInputElement;
            if (element?.files?.length) {
                const reader = new FileReader();
                reader.onload = (ev) => {
                    const content = ev?.target?.result as string;
                    imageFeature?.add(content)
                };
                reader.readAsDataURL(element.files[0]);
            }
        };
        document.body.appendChild(inputEl);
        inputEl.click();
        inputEl.remove();
    }, [imageFeature]);

    const handleOpen = (e: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
        props.setMenuAnchorEl(e.target);
    }

    return (
        <>
            <div className="menu-item">
                <AddImageFeature featureUpdate={f => setImageFeature(f)}>
                    <Button
                        className='btn'
                        startIcon={<Image />}
                        component="label"
                        onClick={(e: any) => {
                            addImage()
                            handleOpen(e)
                        }}
                    > Imagem
                    </Button>
                </AddImageFeature>

                {/* <PropertyBar handleOpen={menuAnchorEl} /> */}
            </div>
        </>
    )
}