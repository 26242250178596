
import { Logout } from '@mui/icons-material';
import { Button, ListItemIcon, Menu, MenuItem, Tooltip } from '@mui/material';
import colacril_logo from '../../../assets/logo_colacril_horizontal.webp';
import { useEditorContext } from '../../../context/EditorContext';
import InstallDesktopIcon from '@mui/icons-material/InstallDesktop';
import Open from './Open';
import Save from './Save';
import Back from './Back';
import ModalUserData from '../login/modalUserData';
import ModalChangePassword from '../login/modalChangePassword';
import { useState } from 'react';

import { Password, Person, AccountBox, NoteOutlined } from '@mui/icons-material';
import Print from './Print';
import { useUserContext } from '../../../context/userContext';

function Header(props: { setOpenPWAModal: Function, setOpenModalMinhasEtiquetas: Function }) {
    const { produtoSelecionado } = useEditorContext();
    const { usuarioLogado, logoff, userData } = useUserContext();

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const isInstalledPWA: boolean = (typeof window !== 'undefined' && (window.matchMedia('(display-mode: standalone)').matches))

    const [openModalUserData, setOpenModalUserData] = useState<boolean>(false);
    const [openModalChangePassword, setOpenModalChangePassword] = useState<boolean>(false);

    return (
        <header className="editor-header">
            <img alt="" className='logo' src={colacril_logo} />

            <div>
                {
                    usuarioLogado && (
                        <>
                            {
                                produtoSelecionado &&
                                    <>
                                        <Back />
                                        <Print />
                                        <Save />
                                    </>
                            }

                            <Open setOpenModalMinhasEtiquetas={props.setOpenModalMinhasEtiquetas} />

                            {!isInstalledPWA && 
                                <Button
                                    className='btn'
                                    startIcon={
                                        <InstallDesktopIcon fontSize='inherit' />
                                    }
                                    onClick={() => props.setOpenPWAModal(true)}
                                >
                                    Instalar
                                </Button>
                            }

                            <Tooltip title="Configurações de conta">
                                <Button 
                                    className='btn'
                                    startIcon={
                                        <AccountBox fontSize='inherit' />
                                    }
                                    onClick={handleClick}
                                    aria-controls={open ? 'account-menu' : undefined}
                                    aria-haspopup="true"
                                    aria-expanded={open ? 'true' : undefined}
                                >
                                    {userData.unique_name.split(' ').slice(0, 2).join(' ')}
                                </Button>
                            </Tooltip>
                            <Menu
                                elevation={1}
                                anchorEl={anchorEl}
                                open={open}
                                onClose={() => setAnchorEl(null)}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'right',
                                }}
                                    transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                            >
                                <MenuItem onClick={() => setOpenModalUserData(true)}>
                                    <ListItemIcon>
                                        <Person />
                                    </ListItemIcon>
                                    Meus dados
                                </MenuItem>
                                <MenuItem onClick={() => props.setOpenModalMinhasEtiquetas(true)}>
                                    <ListItemIcon>
                                        <NoteOutlined fontSize='inherit' />
                                    </ListItemIcon>
                                    Minhas Etiquetas
                                </MenuItem>
                                <MenuItem onClick={() => setOpenModalChangePassword(true)}>
                                    <ListItemIcon>
                                        <Password />
                                    </ListItemIcon>
                                    Trocar senha
                                </MenuItem>
                                <MenuItem onClick={() => logoff()}>
                                    <ListItemIcon>
                                        <Logout fontSize='inherit' />
                                    </ListItemIcon>
                                    Sair
                                </MenuItem>
                            </Menu>
                            
                            {/* <Button 
                                className='btn'
                                startIcon={
                                    <Logout fontSize='inherit' />
                                }
                                onClick={logoff}
                            >
                                Sair
                            </Button> */}
                        </>
                    )
                }
            </div>
            <ModalUserData open={openModalUserData} handleCloseModal={() => setOpenModalUserData(false)} />
            <ModalChangePassword open={openModalChangePassword} handleCloseModal={() => setOpenModalChangePassword(false)} />
        </header>
    );
}

export default Header;