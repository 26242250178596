import { useCallback, useEffect } from "react";
import { objectFactory, useBoardContext, useKeysCombinationHandler } from "@beontag/tageditor";
import { useClipboardContext } from "./ClipboardContext";
import { Button } from "@mui/material";
import { ContentCut } from "@mui/icons-material";
import React from "react";

export interface CutProps {
    cutDisplacement: number;
}

const Cut: React.FunctionComponent<CutProps> = (props) => {
    const { board } = useBoardContext();
    const { setClipboard } = useClipboardContext();

    const cut = useCallback(() => {
        const activeObjects = board?.activeObjects;
        const canCut = (
            activeObjects?.length
            && activeObjects.every(obj => !objectFactory.Object.isEditing(obj))
        );
        if (canCut) {
            objectFactory.Object.clone(activeObjects, board?.activeSelection).then(clones => {
                clones.forEach(c =>
                    c.set({
                        left: (c.left || 0) + props.cutDisplacement,
                        top: (c.top || 0) + props.cutDisplacement
                    })
                );
    
                setClipboard(clones);
                board?.remove(...activeObjects);
            });
        }
    }, [props.cutDisplacement, board, setClipboard]);

    const CtrlXPressCount = useKeysCombinationHandler(["Control", "X"]);
    useEffect(() => {
        if (CtrlXPressCount) {
            cut();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [CtrlXPressCount]);

    return (
        <Button 
            className='btn' 
            startIcon={<ContentCut />}
            onClick={cut}
            disabled={!board?.activeObjects.length}
        > 
            Recortar
        </Button>
    );
};

export default Cut;
