import AssistenteMainColacril from '../../../components/assistente-busca/assistente-main';

import bg_image from '../../../assets/pattern_colacril_assistente_busca.webp'
import AltText from '../../../components/common/altText';
import { Trans, useTranslation } from 'react-i18next';
import { useOutletContext } from 'react-router-dom';
import linhaSeparator from '../../../assets/separadores/separator-pagina-assistente.webp'
import { ICategoria } from '../../../interfaces/produto/categoria';
import React from 'react';

function Assistente() {
  const { t } = useTranslation('assistenteBusca')

  const categorias: ICategoria[] = useOutletContext();

  return (
    <>
      
		  <div className='content-header-container'>
        <img alt="" src={bg_image} />

        <div className='content-header-image-text content-header-assistant-text'>
          <Trans t={t} i18nKey="Assistente de busca">
            <h1 className='faq-header-title'>Assistente de<AltText>busca</AltText></h1>
          </Trans>

          <p>
            {t("Está em dúvida sobre qual etiqueta corresponde a sua necessidade? Nosso assistente de busca pode te ajudar! Siga o passo a passo de 3 etapas e nós te mostraremos as  opções de produtos ideais para você.")}
          </p>
        </div>

        <div className="shape-divider-bottom-assistant">
          <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
            <path d="M1200 120L0 16.48 0 0 1200 0 1200 120z" className="shape-fill"></path>
          </svg>
        </div>
        
        <img src={linhaSeparator} className="separator-linha-bottom" aria-hidden="true"/>
      </div>

      <AssistenteMainColacril categorias={categorias}/>
    </>
  );
}

export default Assistente;