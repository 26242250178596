import { useEffect } from 'react';
import * as gtag from './gtags.client'
import React from 'react';

function isDevEnv() {
    return process.env.NODE_ENV === "development"
}

export default function Analytics() {
    return (
        <>
            {process.env.NODE_ENV === "development" ? null :
                <>
                    <MetaAnalytics />
                    <RDStation />
                </>
            }
            
        </>
    )
}

export function GoogleTagsHead() {

    const gtmTrackingId = process.env.REACT_APP_GTM_TRACKING_ID;

    return (
        <>
            {!gtmTrackingId || isDevEnv() ? null : (
            <script
                async
                id="gtag-init"
                dangerouslySetInnerHTML={{
                    __html: `
                    (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                    'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                    })(window,document,'script','dataLayer','${gtmTrackingId}');
                    `,
                }}
            />
            )}
        </>
    )
}

export function GoogleTagsBody() {

    const gtmTrackingId = process.env.REACT_APP_GTM_TRACKING_ID;


    return (
        <>
            {!gtmTrackingId || isDevEnv() ? null : (
                <noscript>
                <iframe
                    src={`https://www.googletagmanager.com/ns.html?id=${gtmTrackingId}`}
                    height="0"
                    width="0"
                    style={{ display: 'none', visibility: 'hidden'}}
                />
            </noscript>
            )}
        </>
    )
}

function MetaAnalytics() {

    const metaTrackingId = process.env.REACT_APP_META_TRACKING_ID;

    return (
        <>
            <script
                async
                id="gtag-init"
                dangerouslySetInnerHTML={{
                    __html: `
                    !function(f,b,e,v,n,t,s)
                    {if(f.fbq)return;n=f.fbq=function(){n.callMethod ?
                        n.callMethod.apply(n, arguments) : n.queue.push(arguments)};
                    if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
                    n.queue=[];t=b.createElement(e);t.async=!0;
                    t.src=v;s=b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t,s)}(window, document,'script',
                    'https://connect.facebook.net/en_US/fbevents.js');
                    fbq('init', '${metaTrackingId}');
                    fbq('track', 'PageView');
                    `,
                }}
            />
            <noscript>
                <img
                    height="1"
                    width="1"
                    style={{ display: "none" }}
                    src={`https://www.facebook.com/tr?id=${metaTrackingId}&ev=PageView&noscript=1`}
                />
            </noscript>
        </>
    )
}

function RDStation () {
    
    return (
        <script
            type="text/javascript"
            async
            src="https://d335luupugsy2.cloudfront.net/js/loader-scripts/754d49bb-5d3b-487f-977e-02cfd3f738d9-loader.js"
        >

        </script>
    )
}