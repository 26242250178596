import CarouselLanding from '../../../components/home-page/carousel-landing'

import VisitFaq from '../../../components/home-page/visit-faq';
import { useOutletContext } from 'react-router-dom'

import MaisVistos from '../../../components/home-page/maisVistos';
import TudoQuePrecisa from '../../../components/home-page/tudoQuePrecisa';
import TryAssistantHomepage from '../../../components/home-page/try-assistant-homepage';
import TryPrintingSoftware from '../../../components/home-page/TryPrintingSoftware';
import ProductCategories from '../../../components/home-page/products-categories';

import "react-multi-carousel/lib/styles.css";
import { ICategoria } from '../../../interfaces/produto/categoria';
import Analytics from '../../../analytics/analytics';
import React from 'react';

// export const links: LinksFunction = () => {
//   return [
//     { rel: "stylesheet", href: multi_carousel_styles },
//   ];
// };

// export const loader: LoaderFunction = async () => {
//   return {
//     ga4TrackingId: process.env.GA4_TRACKING_ID,
//     gaUTrackingId: process.env.GAU_TRACKING_ID,
//     metaTrackingId: process.env.META_TRACKING_ID,
//   }
// }

function LandingPage() {
  const categorias = useOutletContext() as ICategoria[];

  return (
    <>
      <Analytics />
      <div className="product-categories-background">
        <CarouselLanding />
        <ProductCategories categorias={categorias} />
      </div>

      <TryPrintingSoftware />

      <div className='page-content'>
        <MaisVistos />
        <TudoQuePrecisa />
      </div>

      <TryAssistantHomepage />
      <VisitFaq />
    </>
  );
}

export default LandingPage;
