import { useState } from 'react';
import { Link } from "react-router-dom";
import { ICategoria } from '../../interfaces/produto/categoria';

import AccordionMenuProdutos from './accordion-menu-produtos';
import { ISubCategoria } from '../../interfaces/produto/subCategoria';
import { IFiltros } from '../../interfaces/produto/filtros';

import { removeAcento } from '../../helpers/removeAcento'
import { useTranslation } from 'react-i18next';
import React from 'react';

export default function MenuProdutos(props: {handleCloseMenus:Function, categorias:ICategoria[]}) {
    const [selectedSubcategoria, setSelectedSubcategoria] = useState<ISubCategoria>({idCategoria: 0, nome: '', filtros: [], produtos: [], alias: ''});
    const [isExpanded, setIsExpanded] = useState<boolean>(false);

    const setExpandButtonAction = (isExpandedValue:boolean) => {
        setIsExpanded(isExpandedValue);
        if (!isExpandedValue) {
            setSelectedSubcategoria({idCategoria: 0, nome: '', filtros: [], produtos: [], alias: ''})
        }
    }

    const { t } = useTranslation('common')

    return (
        <>
        {props.categorias != undefined && props.categorias.length != 0 ?
        <div className="menu-produtos-container">
            <div className="menu-produtos-categories-container"> 
                <AccordionMenuProdutos
                    categorias={props.categorias}
                    isExpanded={isExpanded}
                    handleCloseMenus={props.handleCloseMenus}
                    setIsExpanded={setExpandButtonAction}
                    setSelectedSubcategoria={setSelectedSubcategoria}
                    IdSelectedSubcategoria={selectedSubcategoria.idCategoria?.valueOf()}
                />  
            </div>

            <div className="menu-produtos-subcategories-container">
                {selectedSubcategoria.idCategoria != 0 && selectedSubcategoria.filtros && selectedSubcategoria.filtros.map((filtro:IFiltros, index) => {
                    return (
                        <div className="menu-produtos-subcategory-item-container" key={index}>
                            <p className="menu-produtos-subcategory-header">{t(filtro.typeName)}</p>
                            
                            {filtro.listEnum.map((item, index2) => {
                                return (        
                                    <Link
                                        to={`categoria/${selectedSubcategoria.alias}?${filtro.typeName}=${item.value}`}
                                        onClick={() => props.handleCloseMenus()}
                                        key={index2}
                                    >
                                        <p className="menu-produtos-subcategory-item">{item.text}</p>
                                    </Link>
                                )
                            })}
                        </div>
                    )
                })}
            </div>
        </div>
        :
            <div className='menu-produtos-container menu-produtos-erro-container'>
                <h1>
                    Tivemos um problema ao carregar os produtos
                </h1>
            </div>
        }
        </>
    )
}