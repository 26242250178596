import { Dialog, DialogContent, DialogProps, DialogTitle, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';
import React from 'react';

function PoliticaDePrivacidade(props: { open: boolean, handleClose: Function, scroll: DialogProps['scroll'] }) {
	const { t } = useTranslation('lgpd')

    return (
        <Dialog
            open={props.open}
            onClose={() => props.handleClose()}
            scroll={props.scroll}
            fullWidth
            maxWidth={"lg"}
        >
            <DialogTitle >
                <IconButton onClick={() => props.handleClose()}>
                    <CloseIcon />
                </IconButton>
            </DialogTitle>

            <DialogContent dividers={props.scroll === 'paper'}>
                <div>
                    <h1>{t("POLÍTICA DE PRIVACIDADE E PROTEÇÃO DE DADOS PESSOAIS – VERSÃO PÚBLICA")}</h1>
                    
                    <ol>
                        <li>
                            <b>{t("OBJETIVO")}</b>

                            <p>
                                {t("No cotidiano da Auto Adesivos Paraná S.A. (“Colacril Office”); Auto Adesivos Paraná S.A. (“Colacril Office”), seja na condução dos negócios, na busca por novos produtos, serviços e oportunidades, ou na organização de sua estrutura interna, o tratamento de dados pessoais é parte indispensável dessa realidade. O tratamento de dados pessoais é fundamental às nossas atividades, a Auto Adesivos Paraná S.A. (“Colacril Office”)compreende que deve agir com responsabilidade e transparência, zelando por tais informações e conferindo-lhes medidas de segurança técnicas e administrativas.")}
                            </p>

                            <p>
                                {t("A presente Política prevê diretrizes e estabelece regras relacionadas à privacidade e à proteção dos dados pessoais de clientes, colaboradores e terceiros durante o tratamento de dados pessoais pela Auto Adesivos Paraná S.A. (“Colacril Office”) , e na relação com terceiros, nas quais haja compartilhamento ou uso compartilhado de dados pessoais.")}
                            </p>

                            <p>
                                {t("Com este documento, a Auto Adesivos Paraná S.A. (“Colacril Office”) visa estar em conformidade com as normas aplicáveis de proteção de dados, promovendo a transparência e boa-fé perante os titulares, mediante a proteção de seus dados pessoais e de seus direitos e liberdades civis, inclusive a Lei 13.709/2018 (“LGPD”), bem como as melhores práticas ao seu alcance.")}
                            </p>

                            <br />
                        </li>

                        <li>
                            <b>{t("ABRANGÊNCIA")}</b>

                            <p>
                                {t("A Política aplica-se a Auto Adesivos Paraná S.A. (“Colacril Office”)de forma integral, principalmente às áreas de negócio e operacionais, bem como os terceiros com os quais a Auto Adesivos Paraná S.A. (“Colacril Office”)compartilhe dados pessoais, tanto no Brasil quanto no exterior.")}
                            </p>

                            <br />
                        </li>

                        <li>
                            <b>{t("REFERÊNCIAS")}</b>
                            
                            <p>
                                <ul>
                                    <li>
                                        {t("Lei Geral de Proteção de Dados Pessoais (“LGPD”) – Lei nº 13.709/2018;")}
                                    </li>

                                    <li>
                                        {t("General Data Protection Regulation (“GDPR”) – Regulation (EU) 2016/679;")}
                                    </li>
                                </ul>
                            </p>

                            <br />
                        </li>

                        <li>
                            <b>{t("GLOSSÁRIO")}</b>

                            <p>
                                <ul>
                                    <li>
                                        <p>
                                            {t("ANPD: Autoridade Nacional de Proteção de Dados")}
                                        </p>
                                    </li>

                                    <li>
                                        <p>
                                            {t("Auto Adesivos Paraná S.A. (“Colacril Office”): Auto Adesivos Paraná S.A. (“Colacril Office”)")}
                                        </p>
                                    </li>

                                    <li>
                                        <p>
                                            {t("Controlador ou controladores: pessoa(s) natural(is) ou jurídica(s), de direito público ou privado, a quem competem as decisões referentes ao tratamento de dados pessoais.")}
                                        </p>
                                    </li>

                                    <li>
                                        <p>
                                            {t("Dado Anonimizado: dado relativo ao titular que não possa ser identificado, considerando a utilização de meios técnicos razoáveis e disponíveis na ocasião de seu tratamento.")}
                                        </p>
                                    </li>

                                    <li>
                                        <p>
                                            {t("Dado pessoal ou dados pessoais: informação(ões) relacionada(s) a pessoa natural identificada ou identificável. Isto é, informação(ões) que identifiquem uma pessoa natural de forma direta (nome, sobrenome, CPF, impressão digital, endereço de e-mail, número de telefone) ou indireta, a partir de associações e perfilamento (endereço, estado civil, profissão, renda, histórico financeiro, nota de crédito).")}
                                        </p>
                                    </li>

                                    <li>
                                        <p>
                                            {t("Dado pessoal sensível ou dados pessoais sensíveis: dado pessoal sobre origem racial ou étnica, convicção religiosa, opinião política, filiação a sindicato ou a organização de caráter religioso, filosófico ou político, dado referente à saúde ou à vida sexual, dado genético ou biométrico, quando vinculado a uma pessoa natural. De outro modo, são dados pessoais que revelem informações relacionadas à intimidade do Titular, podendo lhe gerar discriminação. Dados pessoais sensíveis implicam em riscos mais elevados que os dados pessoais e vulnerabilidades aos direitos e liberdades dos titulares.")}
                                        </p>
                                    </li>

                                    <li>
                                        <p>
                                            {t("Data Protection Officer (DPO) ou Encarregado: trata-se da pessoa responsável na Auto Adesivos Paraná S.A. (“Colacril Office”)como canal de comunicação entre o controlador, os titulares dos dados e a ANPD.")}
                                        </p>
                                    </li>

                                    <li>
                                        <p>
                                            {t("Fluxo de tratamento de dados pessoais (“Fluxo”): qualquer operação realizada por área da Auto Adesivos Paraná S.A. (“Colacril Office”)que envolva tratamento de dados pessoais com uma finalidade específica.")}
                                        </p>
                                    </li>

                                    <li>
                                        <p>
                                            {t("Eliminação: exclusão de dado ou de conjunto de dados armazenados em banco de dados ou em documentos físicos.")}
                                        </p>
                                    </li>

                                    <li>
                                        <p>
                                            {t("Incidente de segurança: ocorrência relacionada à segurança, técnica ou administrativa, dos dados pessoais e que possa trazer riscos ou danos aos Titulares. São exemplos de incidentes: vazamento de dados, acessos não autorizados, destruição ou alteração dos dados pessoais, entre outros.")}
                                        </p>
                                    </li>

                                    <li>
                                        <p>
                                            {t("Menores: refere-se às crianças (até doze anos incompletos) e adolescentes (entre doze e dezoito anos).")}
                                        </p>
                                    </li>

                                    <li>
                                        <p>
                                            {t("Programa: refere-se ao programa de compliance de proteção de dados pessoais da Auto Adesivos Paraná S.A. (“Colacril Office”).")}
                                        </p>
                                    </li>

                                    <li>
                                        <p>
                                            {t("Operador ou operadores: pessoa(s) natural(is) ou jurídica(s), de direito público ou privado, que realiza(m) o tratamento de dados pessoais em nome do controlador.")}
                                        </p>
                                    </li>

                                    <li>
                                        <p>
                                            {t("RIPD ou Relatório de Impacto: trata-se do relatório de impacto à proteção de dados pessoais, previsto na LGPD, e obrigatório para os casos previstos no Formulário para Registro de Novos Tratamentos de Dados Pessoais.")}
                                        </p>
                                    </li>

                                    <li>
                                        <p>
                                            {t("Terceiros: abrange pessoas naturais e jurídicas, sob a condição de fornecedores, prestadores de serviço, representantes comerciais, parceiros, agentes intermediários, entre outros vínculos jurídicos que tratem dados pessoais em nome da Auto Adesivos Paraná S.A. (“Colacril Office”).")}
                                        </p>
                                    </li>

                                    <li>
                                        <p>
                                            {t("Teste de Proporcionalidade do Legítimo Interesse: trata-se de teste de quatro fases a ser conduzido em paralelo ao Relatório de Impacto, sempre que a hipótese de tratamento for o interesse legítimo da Auto Adesivos Paraná S.A. (“Colacril Office”). O teste está previsto no Formulário para Registro de Novos Tratamentos de Dados Pessoais.")}
                                        </p>
                                    </li>

                                    <li>
                                        <p>
                                            {t("Titular ou Titulares: pessoa(s) natural(is) a quem se referem os dados pessoais que são objeto de tratamento pela Auto Adesivos Paraná S.A. (“Colacril Office”), incluindo, por exemplo: clientes, empregados, diretores, acionistas e sócios de terceiros.")}
                                        </p>
                                    </li>

                                    <li>
                                        <p>
                                            {t("Transferência internacional ou transferência internacional de dados: transferência dos dados pessoais para outro país, em qualquer momento do tratamento inclusive para mero armazenamento. A transferência não se confunde com a transmissão, que é apenas a utilização de meio (exemplo: e-mail com servidor no exterior) para destinatário no Brasil.")}
                                        </p>
                                    </li>

                                    <li>
                                        <p>
                                            {t("Tratamento: todas as operações realizadas com dados pessoais e dados pessoais sensíveis, desde sua coleta até a eliminação, incluindo o mero acesso e visualização dos dados.")}
                                        </p>
                                    </li>

                                    <li>
                                        <p>
                                            {t("Uso Compartilhado de Dados: Comunicação, difusão, transferência internacional, interconexão de dados pessoais ou tratamento compartilhado de bancos de dados pessoais por órgãos e entidades públicos no cumprimento de suas competências legais, ou entre esses e entes privados, reciprocamente, com autorização específica, para uma ou mais modalidades de tratamento permitidas por esses entes públicos, ou entre entes privados.")}
                                        </p>
                                    </li>

                                    <li>
                                        <p>
                                            {t("Usuários - todas as pessoas que visitem e acessem os websites ou aplicativos da Auto Adesivos Paraná S.A. (“Colacril Office”). Também podemos nos referir ao Usuário como “você”.")}
                                        </p>
                                    </li>
                                </ul>
                            </p>

                            <br />
                        </li>

                        <li>
                            <b>{t("DISPOSIÇÕES GERAIS")}</b>

                            <ol>
                                <li>
                                    <p>
                                        <b>{t("Conceitos Gerais")}</b>

                                        <ol>
                                            <li>
                                                <p>
                                                    <b>{t("Tratamento de Dados Pessoais")}</b>

                                                    <p>
                                                        {t("O tratamento de dados pessoais inclui: “toda operação realizada com dados pessoais, como as que se referem a coleta, produção, recepção, classificação, utilização, acesso, reprodução, transmissão, distribuição, processamento, arquivamento, armazenamento, eliminação, avaliação ou controle da informação, modificação, comunicação, transferência, difusão ou extração”.")}
                                                    </p>

                                                    <p>
                                                        {t("Pela ampla definição legal, qualquer ação acima envolvendo dados pessoais configura uma atividade de tratamento. Não é necessário que se aplique efetiva conduta com o dado pessoal, ou resultar um produto diferente. A mera visualização, a partir do acesso aos dados pessoais, já caracteriza o tratamento.")}
                                                    </p>

                                                    <p>
                                                        {t("A Auto Adesivos Paraná S.A. (“Colacril Office”), preocupado com a conformidade de cada tratamento realizado sob sua responsabilidade, busca conscientizar seus colaboradores e terceiros e adotar continuamente medidas de segurança.")}
                                                    </p>

                                                    <p>
                                                        {t("Exemplos de tratamentos realizados pela Auto Adesivos Paraná S.A. (“Colacril Office”): ")}
                                                    </p>

                                                    <ul>
                                                        <li>
                                                            <p>
                                                                {t("Coleta, recepção, utilização e armazenamento de dados pessoais para cadastro de novos clientes e manutenção da base de clientes existentes;")}
                                                            </p>
                                                        </li>

                                                        <li>
                                                            <p>
                                                                {t("Utilização e comunicação de dados pessoais em reportes oficiais aos órgãos reguladores;")}
                                                            </p>
                                                        </li>

                                                        <li>
                                                            <p>
                                                                {t("Controle da informação de colaboradores e transmissão a órgãos públicos em observância às leis vigentes;")}
                                                            </p>
                                                        </li>

                                                        <li>
                                                            <p>
                                                                {t("Arquivamento de dados pessoais de Terceiros, pelo prazo legal;")}
                                                            </p>
                                                        </li>

                                                        <li>
                                                            <p>
                                                                {t("Eliminação de dados pessoais de colaboradores desligados, após decorrido o período de guarda obrigatória.")}
                                                            </p>
                                                        </li>
                                                    </ul>
                                                </p>

                                                <br />
                                            </li>

                                            <li>
                                                <b>{t("Informações coletadas")}</b>

                                                <p>
                                                    {t("A Auto Adesivos Paraná S.A. (“Colacril Office”)coleta dados e informações dos Usuários em seus websites ou aplicativos quando fornecidos pelo Usuário, e em acordo com as bases legais de tratamento da LGPD, no preenchimento de formulários ou cadastros.")}
                                                </p>

                                                <p>
                                                    {t("Para facilitar o uso do website ou aplicativo, a Auto Adesivos Paraná S.A. (“Colacril Office”)também pode coletar dados da navegação ou do aparelho do Usuário, através de rastreio (cookies), autorizações concedidas aos websites ou aplicativos que fornecem a localização geográfica, endereço de protocolo da internet, informações de data e hora de uso do website pelo Usuário, informações referentes a páginas acessadas, às quantidades de cliques e tentativas de uso do Website pelo Usuário.")}
                                                </p>

                                                <p>
                                                    {t("Destacamos que eventual não compartilhamento de dados pelo Usuário pode impactar na usabilidade e experiência de acesso ao website ou aplicativos.")}
                                                </p>

                                                <p>
                                                    {t("Todo e qualquer hipótese de fornecimento de consentimento pelo Usuário para a finalidade de tratamento do dado pessoal é coletado de forma individual, clara e específica. A qualquer tempo, o Usuário poderá alterar suas concessões de consentimento para o tratamento de seus dads, seja concedendo novas permissões ou restringindo o consentimento para a permissões atuais. Outras informações e diretrizes relacionadas ao consentimento dos Usuários poderá ser identificada na Política de Gestão de Consentimento.")}
                                                </p>

                                                <p>
                                                    {t("A Auto Adesivos Paraná S.A. (“Colacril Office”) disponibiliza um canal de comunicação aos titulares, acessível publicamente em nosso website. Que poderá ser acessado pelo seguinte meio: https://www.contatoseguro.com.br/beontag.")}
                                                </p>

                                                <br />
                                            </li>

                                            <li>
                                                <b>{t("Importância de coletas suas informações")}</b>

                                                <p>
                                                    {t("A coleta de informações tem objetivo de prestar serviços necessários e melhorar os produtos e serviços oferecidos para facilitar a utilização pelo Usuário, permitir o suporte e atendimento aos Usuários, além de cumprir e a executar obrigações legais, contratuais, regulatórias, a proteção e o exercício regular de direitos pelo Usuário e pela Auto Adesivos Paraná S.A. (“Colacril Office”).")}
                                                </p>

                                                <br />
                                            </li>

                                            <li>
                                                <b>{t("Uso de Cookies")}</b>

                                                <p>
                                                    {t("Cookies são arquivos de internet que armazenam o que o internauta está visitando nos websites num determinado momento.")}
                                                </p>

                                                <p>
                                                    {t("Os cookies podem servir para permitir o acesso e o funcionamento dos websites ou aplicativos; os cookies de autenticação, reconhecer o Usuário, possibilitando o seu acesso a áreas restritas dos websites ou aplicativos e para oferecer conteúdos, ofertas e/ou serviços da Auto Adesivos Paraná S.A. (“Colacril Office”)ou de parceiros.")}
                                                </p>

                                                <p>
                                                    {t("Os cookies disponibilizados nos sites da Auto Adesivos Paraná S.A. (“Colacril Office”)permitem que o usuário tenha uma experiência de navegação personalizada, com maior velocidade e maior personalização de conteúdo.")}
                                                </p>
                                                
                                                <br />
                                            </li>

                                            <li>
                                                <b>{t("Desativação de cookies")}</b>

                                                <p>
                                                    {t("O Usuário pode desativar os cookies do seu navegador e nas configurações do sistema operacional do seu aparelho ou equipamento de acesso aos websites ou aplicativos.")}
                                                </p>

                                                <p>
                                                    {t("No entanto, não recomendamos desativar os cookies de funcionamento pois podem bloquear ou impedir as funcionalidades e até mesmo o uso dos websites ou aplicativos, principalmente aquelas relacionadas à personalização da experiência do usuário, prejudicando a navegação pelos sites da Auto Adesivos Paraná S.A. (“Colacril Office”).")}
                                                </p>

                                                <br />
                                            </li>

                                            <li>
                                                <b>{t("Agentes de Tratamento")}</b>

                                                <p>
                                                    {t("Há duas categorias de agentes envolvidos nas operações de tratamento: controladores e operadores. Os controladores são responsáveis pelas decisões a serem tomadas no tratamento de dados pessoais, ao passo que os operadores conduzem as atividades de tratamento conforme determinações do controlador.")}
                                                </p>

                                                <br />
                                            </li>
                                        </ol>
                                    </p>
                                </li>

                                <li>
                                    <b>{t("Princípios e Bases Legais")}</b>
                                    
                                    <p>
                                        {t("A Auto Adesivos Paraná S.A. (“Colacril Office”)realiza apenas operações de tratamento que estejam alinhadas com os requisitos da LGPD, principalmente em relação a seus princípios e hipóteses de tratamento (bases legais).")}
                                    </p>

                                    <br />

                                    <ol>
                                        <li>
                                            <b>{t("Bases Legais")}</b>

                                            <p>
                                                {t("Os dados pessoais só serão tratados sob as seguintes circunstâncias: ")}
                                            </p>

                                            <br />

                                            <table>
                                                <tr>
                                                    <td>{t("CONTRATOS")}</td>
                                                    <td>{t("Quando necessário aos procedimentos preliminares ou à execução de um contrato, a pedido do Titular, em que este é parte; ou")}</td>
                                                </tr>

                                                <tr>
                                                    <td>{t("OBRIGAÇÕES LEGAIS OU REGULATÓRIAS")}</td>
                                                    <td>{t("Se há uma obrigação legal ou regulatória da qual decorra a necessidade de tratamento de dados pessoais para cumpri-la; ou")}</td>
                                                </tr>

                                                <tr>
                                                    <td>{t("PROTEÇÃO DO CRÉDITO")}</td>
                                                    <td>{t("Para a proteção do crédito, inclusive quanto ao disposto na legislação pertinente; ou")}</td>
                                                </tr>

                                                <tr>
                                                    <td>{t("EXERCÍCIO REGULAR DE DIREITOS")}</td>
                                                    <td>{t("Para o exercício regular de direitos em processo judicial, administrativo ou arbitral, inclusive durante o período em que transcorra o prazo prescricional; ou")}</td>
                                                </tr>

                                                <tr>
                                                    <td>{t("POLÍTICAS PÚBLICAS")}</td>
                                                    <td>{t("Se estiverem relacionados à execução de uma política pública pela administração pública, sob cenário em que a Auto Adesivos Paraná S.A. (“Colacril Office”)esteja legalmente vinculado; ou")}</td>
                                                </tr>

                                                <tr>
                                                    <td>{t("LEGÍTIMO INTERESSE")}</td>
                                                    <td>{t("Para atender aos interesses legítimos do controlador ou de Terceiro, desde que preencha os requisitos do teste de proporcionalidade do legítimo interesse; ou")}</td>
                                                </tr>
                                                
                                                <tr>
                                                    <td>{t("CONSENTIMENTO")}</td>
                                                    <td>{t("Quando as hipóteses anteriores não forem aplicáveis e o Titular tiver fornecido consentimento para a exata finalidade do tratamento em questão.")}</td>
                                                </tr>
                                            </table>

                                            <br />

                                            <p>
                                                {t("As operações de tratamento devem obedecer aos aspectos apontados no Mapeamento de Dados, principalmente quanto às finalidades.")}
                                            </p>

                                            <p>
                                                {t("Em regra, a Auto Adesivos Paraná S.A. (“Colacril Office”)não realiza o tratamento de dados pessoais sensíveis, exceto sob as seguintes circunstâncias: ")}
                                            </p>

                                            <br />

                                            <table>
                                                <tr>
                                                    <td>{t("OBRIGAÇÕES LEGAIS OU REGULATÓRIAS")}</td>
                                                    <td>{t("Se há uma obrigação legal ou regulatória da qual decorra a necessidade de tratamento de dados pessoais para cumpri-la; ou")}</td>
                                                </tr>

                                                <tr>
                                                    <td>{t("EXERCÍCIO REGULAR DE DIREITOS")}</td>
                                                    <td>{t("Para o exercício regular de direitos relacionado a um contrato ou em processo judicial, administrativo ou arbitral, inclusive durante o período em que transcorra o prazo prescricional; ou")}</td>
                                                </tr>
                                                
                                                <tr>
                                                    <td>{t("POLÍTICAS PÚBLICAS")}</td>
                                                    <td>{t("Se estiverem relacionados à execução de uma política pública pela administração pública, sob cenário em que a Auto Adesivos Paraná S.A. (“Colacril Office”)esteja legalmente vinculada; ou")}</td>
                                                </tr>
                                                
                                                <tr>
                                                    <td>{t("PREVENÇÃO À FRAUDE E À SEGURANÇA DO TITULAR")}</td>
                                                    <td>{t("Para garantir de prevenção à fraude e à segurança do próprio titular, especificamente nos processos de identificação e autenticação de cadastro em sistemas eletrônicos; ou")}</td>
                                                </tr>
                                                
                                                <tr>
                                                    <td>{t("CONSENTIMENTO")}</td>
                                                    <td>{t("Quando as hipóteses anteriores não forem aplicáveis e o Titular tiver fornecido consentimento, de forma específica e destacada, para finalidade específica ligada ao tratamento do dado pessoal sensível.")}</td>
                                                </tr>
                                            </table>
                                            
                                            <br />
                                        </li>

                                        <li>
                                            <p>
                                                <b>{t("Princípios")}</b>

                                                <p>
                                                    {t("Todas as operações de tratamento deverão observar os princípios da LGPD, principalmente quanto às seguintes diretrizes: ")}
                                                </p>

                                                <br />

                                                <ul>
                                                    <li>
                                                        {t("Não Discriminação")}
                                                        <p>
                                                            {t("Sob nenhuma circunstância, os dados pessoais e dados pessoais sensíveis poderão ser tratados de forma discriminatória entre os Titulares de determinada categoria.")}
                                                        </p>
                                                    </li>

                                                    <li>
                                                        {t("Finalidade, Adequação e Necessidade")}
                                                        <p>
                                                            {t("Em todas as operações, a Auto Adesivos Paraná S.A. (“Colacril Office”)tratará o mínimo necessário de dados pessoais compatíveis com finalidades legítimas, específicas, explícitas e informadas ao Titular, além da observância à base legal cabível.")}
                                                        </p>
                                                    </li>

                                                    <li>
                                                        {t("Livre Acesso, Qualidade dos Dados e Transparência")}
                                                        <p>
                                                            {t("Aos Titulares, a Auto Adesivos Paraná S.A. (“Colacril Office”)garantirá a consulta facilitada e gratuita sobre a forma e duração do tratamento, além de informações precisas e didáticas quanto à realização do tratamento e os agentes envolvidos, desde que não viole segredo comercial ou industrial da instituição ou de Terceiro.")}
                                                        </p>

                                                        <p>
                                                            {t("Também asseguramos a qualidade dos dados pessoais utilizados, possibilitando aos Titulares a atualização destes, para que sejam os mais exatos e alinhados ao tratamento.")}
                                                        </p>
                                                    </li>

                                                    <li>
                                                        {t("Segurança, Prevenção, Responsabilização e Prestação de Contas")}
                                                        <p>
                                                            {t("A Auto Adesivos Paraná S.A. (“Colacril Office”)adota padrões de segurança adequados às suas operações, principalmente quando envolvem tratamento de dados pessoais, de modo a prevenir incidentes de segurança.")}
                                                        </p>
                                                    </li>
                                                </ul>
                                            </p>
                                        </li>
                                    </ol>

                                    <br />
                                </li>

                                <li>
                                    <b>{t("Direitos dos Titulares")}</b>

                                    <p>
                                        {t("A Auto Adesivos Paraná S.A. (“Colacril Office”)assegura a observância aos direitos dos titulares dos tratamentos de dados pessoais que realiza, conforme as disposições abaixo mencionadas: ")}
                                    </p>

                                    <ul>
                                        <li>
                                            <p>
                                                {t("Direito de Acesso/Explicação: os titulares de dados pessoais tratados pela Auto Adesivos Paraná S.A. (“Colacril Office”)poderão ter acesso e solicitar informações como a confirmação de que seus dados são tratados pela companhia, bem como informações sobre o referido tratamento. As respostas para tais solicitações serão dadas de forma simplificada ou completa, de acordo com o previsto em lei, dentro de um prazo de 15 (quinze) dias a contar do requerimento do titular, resguardados os segredos industriais;")}
                                            </p>
                                        </li>

                                        <li>
                                            <p>
                                                {t("Direito de Retificação: O titular do dado pessoal poderá solicitar a retificação sobre o registro de seus dados pessoais, como dados imprecisos, incorretos ou desatualizados;")}
                                            </p>
                                        </li>

                                        <li>
                                            <p>
                                                {t("Direito de Cancelamento/Esquecimento: O titular dos dados poderá solicitar a eliminação, bloqueio ou anonimização de seus dados pessoais tratados pela Auto Adesivos Paraná S.A. (“Colacril Office”)quando estes forem processados em excesso ou de forma ilegal. O titular também terá o direito a solicitar a eliminação de seus dados pessoais quando a base legal para o tratamento for proveniente de consentimento, salvo nas hipóteses de retenção previstas em lei;")}
                                            </p>
                                        </li>

                                        <li>
                                            <p>
                                                {t("Direito de Oposição: o titular dos dados poderá se opor ao tratamento de seus dados pessoais caso não tenha consentido com este tratamento;")}
                                            </p>
                                        </li>

                                        <li>
                                            <p>
                                                {t("Direito a Portabilidade: o titular dos dados poderá solicitar a portabilidade de seus dados pessoais a outro prestador de serviços ou produtos, mediante solicitação expressa, de acordo com a regulamentação da ANPD e órgãos reguladores.")}
                                            </p>
                                        </li>
                                    </ul>

                                    <p>
                                        {t("De modo a atender requisições dos titulares, a Auto Adesivos Paraná S.A. (“Colacril Office”)possui ferramentas e mecanismos que visam a celeridade e efetividade na resposta ou observância destes direitos, bem como o devido arquivamento das medidas que forem adotadas em relação a essa requisição.")}
                                    </p>

                                    <p>
                                        {t("Para tanto, disponibilizamos um canal de comunicação aos titulares, acessível publicamente em nosso website. Que poderá ser acessado pelo seguinte meio: https://www.contatoseguro.com.br/beontag.")}
                                    </p>
                                </li>
                            </ol>

                            <br />
                        </li>

                        <li>
                            <b>{t("DIRETRIZES ESPECÍFICAS")}</b>

                            <ol>
                                <li>
                                    <p>
                                        <b>{t("Transferência Internacional")}</b>

                                        <br />

                                        <p>
                                            {t("A Auto Adesivos Paraná S.A. (“Colacril Office”)adota conduta restritiva quanto à transferência internacional de dados pessoais, realizando-a apenas quando estritamente necessário à condução de suas atividades ou quando há padrão de segurança compatível com suas diretrizes.")}
                                        </p>

                                        <p>
                                            {t("Nestes casos, a Auto Adesivos Paraná S.A. (“Colacril Office”)observa a legislação local do país de transferência, para o devido cumprimento. A Auto Adesivos Paraná S.A. (“Colacril Office”)também garante a ciência prévia dos Titulares da possibilidade de transferência internacional de seus dados pessoais, a partir de cláusulas contratuais ou do consentimento específico, conforme o caso concreto.")}
                                        </p>

                                        <p>
                                            {t("A Auto Adesivos Paraná S.A. (“Colacril Office”)também observa os requisitos da LGPD para a possibilidade de transferência internacional: ")}
                                        </p>

                                        <ul>
                                            <li>
                                                <p>
                                                    {t("Os dados pessoais são transferidos para países com grau adequado de proteção, em linha com as diretrizes da ANPD;")}
                                                </p>
                                            </li>

                                            <li>
                                                <p>
                                                    {t("Os dados pessoais são transferidos quando a Auto Adesivos Paraná S.A. (“Colacril Office”)é capaz de responsabilizar-se. Nesta hipótese, além de observar os direitos e deveres previstos na LGPD, a Auto Adesivos Paraná S.A. (“Colacril Office”)se utilizará de cláusulas contratuais específicas ou padrões; normas corporativas globais; e do Programa de Compliance de Proteção de Dados Pessoais.")}
                                                </p>
                                            </li>
                                        </ul>
                                    </p>

                                    <br />
                                </li>

                                <li>
                                    <b>{t("Tratamento de Dados Pessoais de Menores")}</b>

                                    <p>
                                        {t("A Auto Adesivos Paraná S.A. (“Colacril Office”)não realiza, em regra, o tratamento de dados pessoais de menores. Contudo, existem ocasiões em que será necessário tratá-los. Nestes casos, os dados serão tratados no melhor interesse do menor.")}
                                    </p>

                                    <p>
                                        {t("Nesses casos, será obrigatório obter o consentimento específico e em destaque dos pais do titular dos dados pessoais, salvo em casos que o tratamento tiver como base legal para exercício regular (defesa) em processo judicial, administrativo ou arbitral, somente quando se referir ao tratamento de dados de adolescentes.")}
                                    </p>

                                    <p>
                                        {t("Os dados pessoais de crianças e adolescentes, assim como os dados sensíveis, devem estar sujeitos a maior proteção em relação a outros dados pessoais. Desta forma, dados pessoais sensíveis deverão ser classificados de maneira destacada.")}
                                    </p>

                                    <br />
                                </li>

                                <li>
                                    <b>{t("Privacy by Design")}</b>

                                    <p>
                                        {t("Em consideração ao princípio de Privacy by Design, todos os produtos e serviços que sejam criados pela Auto Adesivos Paraná S.A. (“Colacril Office”)são objeto de análise para que seja garantida a privacidade e a proteção de dados pessoais dos titulares, desde a concepção.")}
                                    </p>

                                    <p>
                                        {t("A análise inicia-se a partir do preenchimento do Formulário para Registro de Novos Tratamentos de Dados Pessoais, pela área responsável pela inovação, e é deliberada pelo Comitê de Proteção de Dados, que garantirá a observância ao princípio em questão.")}
                                    </p>

                                    <br />
                                </li>
                            </ol>
                        </li>

                        <li>
                            <b>{t("DISPOSIÇÕES FINAIS")}</b>

                            <p>
                                {t("O presente documento deve ser lido e interpretado em conjunto com as demais Políticas e Procedimentos adotados pela Auto Adesivos Paraná S.A. (“Colacril Office”), bem como com as leis e regulamentações relacionadas.")}
                            </p>
                            
                            <p>
                                {t("Qualquer dúvida relativa a esta Política deve ser encaminhada ao Canal de Comunicação do Titular de Dados por meio do e-mail comitelgpd@beontag.com ou ao Encarregado por e-mail lgpd@beontag.com.")}
                            </p>
                        </li>
                    </ol>
                </div>
            </DialogContent>
        </Dialog>
    )
}

export default PoliticaDePrivacidade;