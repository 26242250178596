export enum Languages {
    Portuguese = "pt",
    English = "en",
    Spanish = "es"
}

const i18nextOptions = {
    fallbackLng: Languages.Portuguese,
    supportedLngs: Object.values(Languages),
    ns: "common",
    defaultNS: "common",
    fallbackNS: "common",
    // nsSeparator: '|',
    // keySeparator: '\\',
    react: { useSuspense: false },
    //debug: true,
}

export default i18nextOptions;