import { Button } from '@mui/material'
import React from 'react'
import { ICardOverCard } from '../../interfaces/cardOverCard'

export default function CardOverCard(values:ICardOverCard) {
    if (values.imageLast) {
        return (
            <div
                className={`card-over-card-container card-over-card-container-${values.styleType}`}
            >
                <div className={`left-content left-${values.styleType}`}>
                    <div className={`content content-${values.styleType}`}>
                        <img src={values.image} className='image-mobile' alt={values.header} />
                        <h1>{values.header}</h1>
                        
                        <h3>{values.text}</h3>
                        
                        <a href={values.buttonActionLink} download className='btn-link'>
                        {values.buttonText && 
                            <Button
                                className='btn btn-contained-secondary'
                                variant='contained'
                                disableElevation
                            >
                                {values.buttonText}
                            </Button>
                        }
                        </a>
                    </div>
                </div>

                <div className={`right-image right-${values.styleType}`}>
                    <img src={values.image} />
                </div>
            </div>
        )
    } else {
        return (
            <div
                className={`card-over-card-container card-over-card-container-${values.styleType} card-over-card-content-right`}
            >
                <div className={`left-image left-${values.styleType}`}>
                    <img src={values.image} />         
                </div>
                
                <div className={`right-content right-${values.styleType}`}>
                    <div className={`content content-${values.styleType}`}>
                        <img src={values.image} className='image-mobile'/>
                        <h1>{values.header}</h1>
                        
                        <h3>{values.text}</h3>

                        <a href={values.buttonActionLink} download className='btn-link'>
                            <Button
                                className="btn btn-outlined-secondary"
                                variant='outlined'
                            >
                                {values.buttonText}
                            </Button>
                        </a>
                    </div>
                </div>
            </div>
        )
    }
}