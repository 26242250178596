import { Button, InputAdornment } from '@mui/material';
import TextField from '@mui/material/TextField';
import { useEffect, useState } from 'react';
import AltText from '../../../components/common/altText';
import AccordionFaq from '../../../components/faq/accordion-faq';
import SearchIcon from '@mui/icons-material/Search';
import { duvidas } from '../../../components/faq/duvidas'
import imageContato from '../../../assets/contato-imagem-top.webp'
import linhaSeparator from '../../../assets/separadores/separador-pagina-duvidas.webp'
import bg_image from '../../../assets/pattern_colacril_duvidas_frequentes.webp'
import { Link } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import React from 'react';


function Faq() {
	const [searchTerm, setSearchTerm] = useState<string>('');
	const [questions, setQuestions] = useState(duvidas);

	const { t } = useTranslation('faq')

	useEffect(() => {
		if (searchTerm) {
			const filtered = duvidas.map((item) => {
				const filteredItem = item.duvidas.filter((duvida) => {
					const lowercaseSearchTerm = searchTerm.toLowerCase()
					const tituloLowerCase = duvida.titulo.toLowerCase()
					const subtituloLowerCase = duvida.subtitulo.toLowerCase()

					return tituloLowerCase.includes(lowercaseSearchTerm) ||
						subtituloLowerCase.includes(lowercaseSearchTerm)
				});

				return { tituloCategoria: item.tituloCategoria, duvidas: filteredItem }
			});

			setQuestions(filtered);
		} else {
			setQuestions(duvidas);
		}
	}, [searchTerm]);


	return (
		<>
			<div className='content-header-container'>
				<img src={bg_image} alt="" className="content-header-image" />

				<div className='content-header-image-text content-header-text-search'>
					<Trans t={t} i18nKey="Dúvidas frequentes">
						<h1 className='faq-header-title'> <AltText>Dúvidas</AltText> frequentes</h1>
					</Trans>

					<TextField
						placeholder="Digite o que está buscando..."
						fullWidth
						value={searchTerm}
						size="small"
						className='faq-search-bar'
						onChange={(e) => setSearchTerm(e.target.value)}
						InputProps={{
							startAdornment: (
								<InputAdornment position="start">
									<SearchIcon />
								</InputAdornment>
							),
						}}
					/>
				</div>

				<div className="shape-divider-bottom-header-faq">
					<svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
						<path d="M985.66,92.83C906.67,72,823.78,31,743.84,14.19c-82.26-17.34-168.06-16.33-250.45.39-57.84,11.73-114,31.07-172,41.86A600.21,600.21,0,0,1,0,27.35V120H1200V95.8C1132.19,118.92,1055.71,111.31,985.66,92.83Z" className="shape-fill"></path>
					</svg>
				</div>

				<img src={linhaSeparator} className="separator-linha-bottom" alt="" />
			</div>

			<div className='page-content'>
				{questions.map((duvida, index) => {
					return (
						<span key={index}>
							<h2 className="faq-section-header" >{t(duvida.tituloCategoria)}</h2>
							{duvida.duvidas.map((item, index2) => {
								return (
									<AccordionFaq key={index2} titulo={t(item.titulo)} subtitulo={t(item.subtitulo)} />
								)
							})}
						</span>
					)
				})}
			</div>

			<div className='content-footer-container dark-overlay-container'>
				<img src={imageContato} alt="" className="content-header-image dark-overlay-image" />
				
				<div className='content-header-image-text '>
					<h1>{t("Ainda com dúvidas?")}</h1>
					
					<h3 className="content-footer-h3">
						{t("Para dúvidas, sugestões, solicitações e mais informações sobre os produtos e software, entre em contato!")}
					</h3>
					
					<Link to="/contato" className="btn-link">
						<Button variant="contained" className="btn btn-contained-primary" disableElevation>{t("FALE CONOSCO")}</Button>
					</Link>
				</div>

				<div className="shape-divider-top-content-footer">
					<svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
						<path d="M1200 120L0 16.48 0 0 1200 0 1200 120z" className="shape-fill"></path>
					</svg>
				</div>
			</div>
		</>
	);
}
export default Faq;