import Button from "@mui/material/Button";
import AltText from "../../../../components/common/altText";

import laptop from "../../../../assets/note.webp";
import laptop_new from "../../../../assets/laptop_pca_colacril.png";
import { Trans, useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import useMediaQuery from "@mui/material/useMediaQuery";
import { createTheme } from "@mui/material/styles";
import { useState } from "react";
import {
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import React from "react";

function DownloadSoftware() {
  const { t } = useTranslation("software");

  const theme = createTheme();

  const matches = useMediaQuery(theme.breakpoints.down("md"));

  const [openModal, setOpenModal] = useState<boolean>(false);
  const [checkbox, setCheckbox] = useState<boolean>(false);

  return (
    <>
      <div className="download-software-header-container">
        <Trans t={t} i18nKey="Software de impressão">
          <h1>
            {" "}
            <AltText variant="black-dots">Software</AltText> de impressão
          </h1>
        </Trans>

        <h2 className="downloads-paragraph">
          {t(
            "Crie designs para as suas etiquetas de acordo com a sua necessidade! Seja para pequenos negócios, empresas ou lar, conte com o software de impressão Colacril Office."
          )}
        </h2>

        <div className="download-software-button download-software-button-div">
          {!matches ? (
            <>
              <Link to="/editor" className="btn-link">
                <Button
                  variant="contained"
                  className="btn btn-contained-secondary btn-download-software"
                  disableElevation
                >
                  {t("Acessar software")}
                </Button>
              </Link>
            </>
          ) : (
            ""
          )}
        </div>

        <div className="shape-divider-bottom-download-software-header">
          <svg
            data-name="Layer 1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1200 120"
            preserveAspectRatio="none"
          >
            <path
              d="M 0 0 V 34 C 0 65.52 268.63 112.77 600 112.77 S 1200 65.52 1268 26 V 0 Z"
              className="shape-fill"
            ></path>
          </svg>
        </div>
      </div>

      <div className="download-software-image-container">
        <img
          alt=""
          className="download-image download-software-img"
          src={laptop_new}
        />
      </div>
    </>
  );
}

export default DownloadSoftware;
