import { Fonts, useBoardContext, useKeysCombinationHandler, usePropertyContext } from "@beontag/tageditor";
import { Checkbox, FormControlLabel, FormGroup, IconButton, MenuItem, Select, Slider, Stack, TextField } from "@mui/material";
import { useEffect } from "react";

import { fabric } from 'fabric';

import BoldIcon from '@mui/icons-material/FormatBold';
import ItalicIcon from '@mui/icons-material/FormatItalic';
import OpenWithIcon from '@mui/icons-material/OpenWith';
import CloseIcon from '@mui/icons-material/Close';
import React from "react";

const CurvedText: React.FunctionComponent = () => {
  const { properties, setProperty } = usePropertyContext<fabric.CurvedText>();

  const keysPressCountBold = useKeysCombinationHandler(["Control", "b"]);
  const keysPressCountItalic = useKeysCombinationHandler(["Control", "i"]);
  useEffect(() => {
    if (keysPressCountBold) {
      fontWeightToggle();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keysPressCountBold]);

  useEffect(() => {
    if (keysPressCountItalic) {
      fontStyleToggle();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keysPressCountItalic]);

  const fontWeightToggle = () => {
    setProperty({ fontWeight: properties.fontWeight === "normal" ? "bold" : "normal" });
  }

  const fontStyleToggle = () => {
    setProperty({ fontStyle: properties.fontStyle === "normal" ? "italic" : "normal" });
  }

  const handleOnChange = (key: string, rawValue: string | number) => {
    setProperty({ [key]: rawValue });
  }

  const handleOnChangeBooleanProp = (key: string, rawValue: boolean) => {
    setProperty({ [key]: rawValue });
  }

  const { board } = useBoardContext()

    const closeWindow = () => {
		board?.setActiveObjects()
	}


  return (
    <>
      <div className="property-bar-title handle">
                            Propriedades do objeto
                            <OpenWithIcon className="icon left" />
                            <IconButton onClick={() => closeWindow()} className="icon right not-draggable">
                                <CloseIcon />
                            </IconButton>
                        </div>

      <div className="property-bar-itens">
        <label htmlFor="tamanho-da-fonte" className="cursor-pointer">Texto</label>
        &nbsp;
        <TextField
          fullWidth
          className="input-main-width"
          type="text"
          value={properties.text}
          onChange={(e) => handleOnChange("text", e.currentTarget.value)}
          variant="outlined"
          onKeyDown={(e) => { if (e.key == "Delete") e.stopPropagation() }}
        />
      </div>

      <div className="property-bar-itens">
        <span>Fonte</span>
        <Select
          value={properties.fontFamily || "Times New Roman"}
          onChange={(e) => handleOnChange("fontFamily", e.target.value)}
        >
          {
            Fonts.getList().map((font, index) => {
              return <MenuItem value={font} key={index}> {font} </MenuItem>
            })
          }
        </Select>
      </div>

      <div className="property-bar-itens">
        <span>Tamanho da fonte</span>
        <TextField
          fullWidth
          className="property-box-input-width"
          type="number"
          InputProps={{ inputProps: { min: 0, step: 1 } }}
          value={properties.fontSize || 15}
          onChange={(e) => handleOnChange("fontSize", e.currentTarget.value)}
          variant="outlined"
        />

      </div>

      <div className="property-bar-itens">
        <label htmlFor="texto-cor-do-objeto" className="property-bar-pointer-label">Cor do Objeto</label>
        <input
          id="texto-cor-do-objeto"
          className="property-bar-color-input"
          type="color"
          value={properties.fill?.toString() || "#000"}
          onChange={(e) => handleOnChange("fill", e.currentTarget.value)}
        ></input>
      </div>

      <div className="property-bar-itens">
        <label htmlFor="texto-cor-da-borda" className="property-bar-pointer-label">Cor da Borda</label>
        <input
          id="texto-cor-da-borda"
          className="property-bar-color-input"
          type="color"
          value={properties.strokeStyle?.toString() || "#000"}
          onChange={(e) => handleOnChange("strokeStyle", e.currentTarget.value)}
        ></input>
      </div>

      <div className="property-bar-itens">
        <span>Espessura da borda</span>
        <TextField
          fullWidth
          className="property-box-input-width"
          type="number"
          InputProps={{ inputProps: { min: 0, step: 1 } }}
          value={properties.strokeWidth?.toString() || 0}
          onChange={(e) => handleOnChange("strokeWidth", +e.currentTarget.value)}
          variant="outlined"
        />

      </div>
  

      <div className="property-bar-itens">
        <span>Transparência do Objeto</span>
        <TextField
          fullWidth
          className="property-box-input-width"
          type="number"
          InputProps={{ inputProps: { min: 0.1, max: 1, step: 0.1 } }}
          value={properties.opacity || 1}
          onChange={(e) => handleOnChange("opacity", e.currentTarget.value)}
          variant="outlined"
        />
      </div>

      <div className="property-bar-itens not-draggable">
        <span className="curved-text-kerning-span">Diâmetro</span>
        <Slider
          defaultValue={properties.diameter ?? 1000}
          min={properties.minDiameter}
          max={properties.maxDiameter}
          step={10}
          value={properties.diameter ?? 1000}
          // @ts-ignore
          onChange={(e) => handleOnChange("diameter", e.target.value)}
        />
      </div>
      <div className="property-bar-itens not-draggable">
        <span className="curved-text-kerning-span">Kerning</span>
        <Slider
          defaultValue={properties.kerning ?? 0}
          min={-10}
          max={10}
          step={1}
          value={properties.kerning ?? 0}
          // @ts-ignore
          onChange={(e) => handleOnChange("kerning", e.target.value)}
        />
      </div>

      <div className="property-bar-itens extra-bottom-margin">
        <FormGroup>
          <FormControlLabel
            className="editor-numeration-checkbox"
            control={
              <Checkbox
                value={Boolean(properties.flipped)}
                name='checkbox-subtitle'
                color='primary'
                checked={Boolean(properties.flipped)}
                onChange={(e) => handleOnChangeBooleanProp("flipped", e.currentTarget.checked)}
              />
            }
            label="Invertido"
          />
        </FormGroup>
      </div>

      <Stack direction='row' className="property-bar-text-options">
        <IconButton
          aria-label="Negrito"
          size="large"
          onClick={fontWeightToggle}
          className={`property-bar-text-options-button ${properties.fontWeight == 'bold' ? 'active' : ''}`}
        >
          <BoldIcon />
        </IconButton>

        <IconButton
          aria-label="Itálico"
          size="large"
          onClick={fontStyleToggle}
          className={`property-bar-text-options-button ${properties.fontStyle == 'italic' ? 'active' : ''}`}
        >
          <ItalicIcon />
        </IconButton>


      </Stack>


    </>
  );
};

export default CurvedText;
