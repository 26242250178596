import { Box, FormControl, Grid, InputAdornment, MenuItem, Select, TextField, Paper } from "@mui/material";
import React from "react";
import { IFormEditorFiltroProdutos } from "../../../../interfaces/editor/form-filtro-produtos";
import { ISubCategoria } from "../../../../interfaces/produto/subCategoria";

export default function FiltrosTabelaProdutosEditor(props: {
    handleInputChange: Function,
    form: IFormEditorFiltroProdutos,
    subCategoriaSelecionada: ISubCategoria,
}) {

    const { handleInputChange, form, subCategoriaSelecionada } = props;

    return (
        <Box component={Paper} elevation={0} padding={2} marginY={2}>
            <Grid container spacing={4}>
                <Grid item lg={4} xs={12}>
                    <TextField
                        fullWidth
                        type="text"
                        name="nomeCodigo"
                        variant="outlined"
                        onChange={(e) => handleInputChange(e)}
                        value={form.nomeCodigo}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start" disablePointerEvents>
                                    <span className='form-label'>
                                        Nome ou Código do Produto:
                                    </span>
                                </InputAdornment>
                            ),
                        }}
                    />
                </Grid>

                <Grid item lg={2} xs={6}>
                    <TextField
                        fullWidth
                        type="text"
                        name="largura"
                        variant="outlined"
                        onChange={(e) => handleInputChange(e)}
                        value={form.largura}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start" disablePointerEvents>
                                    <span className='form-label'>
                                        Largura:
                                    </span>
                                </InputAdornment>
                            ),
                        }}
                    />
                </Grid>

                <Grid item lg={2} xs={6}>
                    <TextField
                        fullWidth
                        type="text"
                        name="altura"
                        variant="outlined"
                        onChange={(e) => handleInputChange(e)}
                        value={form.altura}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start" disablePointerEvents>
                                    <span className='form-label'>
                                        Altura:
                                    </span>
                                </InputAdornment>
                            ),
                        }}
                    />
                </Grid>

                {subCategoriaSelecionada?.filtros &&
                    <Grid item lg={4} xs={12}>
                        <FormControl fullWidth>
                            <Select
                                value={form.formato}
                                name='formato'
                                onChange={(e) => handleInputChange(e)}
                                startAdornment={
                                    <InputAdornment
                                        position='start'
                                        disablePointerEvents
                                    >
                                        <span className='form-label'>
                                            Formato:
                                        </span>
                                    </InputAdornment>
                                }
                                MenuProps={{
                                    className: 'formato-produtos'
                                }}
                            >
                                {subCategoriaSelecionada?.filtros
                                    .find(filtro => filtro.typeName.toUpperCase().indexOf('FORMA') > -1)?.listEnum
                                    .map(option => (
                                        <MenuItem key={option.value} value={option.value}>
                                            {option.text}
                                        </MenuItem>
                                    ))}
                            </Select>
                        </FormControl>
                    </Grid>
                }
            </Grid>
        </Box>
    )
}