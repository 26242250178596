import { useBoardContext, PageCanvas, BoardPage } from "@beontag/tageditor";
import { Button, Paper, Popper, TextField, Tooltip, IconButton } from "@mui/material";
import { useState } from "react";
import Draggable from "react-draggable";
import { ArrowBackIosNewOutlined, ArrowForwardIosOutlined, AddOutlined, RemoveOutlined } from "@mui/icons-material"
import { ArrowsAngleContract } from "react-bootstrap-icons";
import { useEditorContext } from "../../../../../context/EditorContext";

import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import React from "react";

export default function EtiquetaEditorPages() {

	const { boardFile } = useBoardContext()

	const [showMenu, setShowMenu] = useState<boolean>(false);

	const [boardPage, setBoardPage] = useState<BoardPage>();
	const { numberOfPages, setNumberOfPages, produtoSelecionado } = useEditorContext();

	let currentPage = boardFile?.pageNumber || 1;

	const setEditMode = (mode: "all" | "one") => {
		if (boardFile) {
			boardFile.editMode = mode;
		}
	}

	const setPageNumber = (rawValue: string) => {
		if (boardFile) {
			const value = Number.parseInt(rawValue);

			if (isNaN(value)) {
				return;
			}

			if (value < 1) {
				currentPage = 1;
			}

			boardFile.pageNumber = value;
			currentPage = value;
		}
	}

	return (
		<>
			<Draggable handle=".handle" defaultClassName="draggable-default-cursor">
				{/* @ts-ignore */}
				<Popper open className="label-selector-container">

					<div className="property-bar-container draggable-default-cursor">
						<div className="property-bar-title handle">
							<span>Qual etiqueta deseja editar?</span>
							<button
								className="preview-box-show-menu"
								title="Exibir/Ocultar menu"
								onClick={() => setShowMenu(!showMenu)}
							>
								<ArrowsAngleContract
									size={16}
									className="not-draggable icon"
								/>
							</button>
						</div>
					</div>

					{showMenu ?
						<>

							<div className="label-selector-buttons">
								<Button
									className={`label-selector-buttons-btn ${boardFile?.editMode == 'all' ? 'label-selector-btn-action' : 'label-selector-btn-unselected'}`}
									variant={boardFile?.editMode == 'all' ? 'contained' : 'outlined'}
									onClick={() => setEditMode('all')}
									disableElevation
								>
									<span className="label-selector-buttons-text">TODAS</span>
								</Button>
								<Button
									className={`label-selector-buttons-btn ${boardFile?.editMode == 'one' ? 'label-selector-btn-action' : 'label-selector-btn-unselected'}`}
									variant={boardFile?.editMode == 'one' ? 'contained' : 'outlined'}
									onClick={() => setEditMode('one')}
									disableElevation
								>
									<span className="label-selector-buttons-text">APENAS UMA</span>
								</Button>
							</div>

							<div className="property-bar-container draggable-default-cursor">

								<div className="property-bar-title handle">
									<span>Qual etiqueta deseja editar?</span>
									<button
										className="preview-box-show-menu"
										title="Exibir/Ocultar menu"
										onClick={() => setShowMenu(!showMenu)}
									>
										<ArrowsAngleContract
											size={16}
											className="not-draggable icon"
										/>
									</button>
								</div>

								<div className="etiqueta-editor-pages-info-container">
									<span>
										{/* @ts-ignore */}
										{produtoSelecionado?.items.map((item, index) => {
											return (
												<span key={index}>{index != 0 ? ' / ' : ''}{item.codigo}</span>
											)
										})}
										<span>{produtoSelecionado?.codigo}</span>
									</span>
									<Tooltip
										classes={{ tooltip: 'produto-info-tooltip' }}
										placement="bottom"
										title={
											<>
												<Paper elevation={2}>
													<aside className="editor-menu-prod-selecionado-info-container">
														<div className="editor-menu-prod-selecionado-content">

															<div className="editor-menu-prod-selecionado-data">
																<b>Produto selecionado:</b>
																{/* @ts-ignore */}
																<p>{produtoSelecionado?.nome} {produtoSelecionado?.dimensao}</p>
																<p>
																	{/* @ts-ignore */}
																	{produtoSelecionado?.items.map((item, index) => {
																		return (
																			<b key={index}>{index != 0 ? ' / ' : ''}{item.codigo}</b>
																		)
																	})}
																	<b>{produtoSelecionado?.codigo}</b>
																</p>
																<p>{produtoSelecionado?.forma}</p>
																<p><b>{produtoSelecionado?.folhas}</b></p>
															</div>
														</div>

													</aside>
												</Paper>
											</>
										}
									>
										<IconButton
											onClick={() => undefined}
											size='small'
											className={`cursor-button`}
										>
											<InfoOutlinedIcon />
										</IconButton>
									</Tooltip>
								</div>

								<div className="canva-button-selector">

									<button
										title="Anterior"
										onClick={() => boardFile?.pageNumber != 1 ? setPageNumber(((boardFile?.pageNumber ?? 0) - 1).toString()) : ''}
									>
										<ArrowBackIosNewOutlined
											className={`etiqueta-paginator-arrow ${boardFile?.pageNumber != 1 ? 'active-button' : ''}`}
										/>
									</button>

									<div className="label-selector-pages-input">
										<TextField
											fullWidth
											className="label-selector-page-number"
											type="number"
											InputProps={{ inputProps: { min: 1, step: 1, max: numberOfPages } }}
											value={currentPage}
											onChange={(e) => setPageNumber(e.target.value)}
											variant="outlined"
										/>

										&nbsp;
										de
										&nbsp;

										<span>{numberOfPages}</span>
									</div>

									<button
										title="Próximo"
										onClick={() => boardFile?.pageNumber != numberOfPages ? setPageNumber(((boardFile?.pageNumber ?? 0) + 1).toString()) : ''}
									>
										<ArrowForwardIosOutlined
											className={`etiqueta-paginator-arrow ${boardFile?.pageNumber != numberOfPages ? 'active-button' : ''}`}
										/>
									</button>

								</div>


								<div className="etiqueta-editor-pages-preview-container">
									<PageCanvas featureUpdate={f => setBoardPage(f)} showEdges renderless={false} readonly={false} />
								</div>

								<div className="etiqueta-editor-pages-page-switcher-container">

									<div className="etiqueta-editor-pages-inputs add-remove-page-container">

										<Button
											variant="outlined"
											className="btn btn-outlined-primary btn-add-page"
											onClick={() => {
												// @ts-ignore
												setNumberOfPages((prev) => {
													return prev + 1
												})
												if (boardFile) {
													boardFile.pageNumber = boardFile.pageNumber + 1
												}
											}}
											title="Adicionar pagina"
											startIcon={<AddOutlined fontSize="small" />}
										>
											Adicionar página
										</Button>

										<Button
											className="btn btn-outlined-primary btn-delete-page"
											onClick={() => {
												boardFile?.deletePage(boardFile.pageNumber)
												// @ts-ignore
												setNumberOfPages((prev) => {
													if (prev != 1) {
														if (boardFile?.pageNumber == prev) {
															// @ts-ignore
															boardFile.pageNumber = boardFile.pageNumber - 1
														}
														return prev - 1
													}
													return prev
												})
											}}
											disableElevation
											variant="outlined"
											//color="error"
											title="Remover pagina"
											startIcon={<RemoveOutlined fontSize="small" />}

										>
											Remover página
										</Button>
									</div>

								</div>



							</div>
						</>
						: ''}

				</Popper>
			</Draggable>
		</>
	)
}