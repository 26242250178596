// @ts-nocheck
import { Accordion, AccordionItem, AccordionItemButton, AccordionItemHeading, AccordionItemPanel } from 'react-accessible-accordion'
import { useTranslation } from 'react-i18next'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Link } from 'react-router-dom';
import { ISubCategoria } from '../../interfaces/produto/subCategoria';
import useMediaQuery from '@mui/material/useMediaQuery';
import { createTheme } from '@mui/material/styles'
import { ICategoria } from '../../interfaces/produto/categoria';
import { CloudUploadOutlined } from '@mui/icons-material';

import CloseIcon from '@mui/icons-material/Close';
import React from 'react';

export default function AccordionMenuProdutos(props: {
    categorias:any,
    isExpanded:boolean,
    handleCloseMenus:Function,
    setIsExpanded: Function,
    setSelectedSubcategoria: Function,
    IdSelectedSubcategoria:number|undefined
}) {

    const theme = createTheme();
    const { t } = useTranslation();
    const matches = useMediaQuery(theme.breakpoints.up('lg'));

    function onMouseOver(categoria:ICategoria) {
        if (matches) {
            if (categoria.subCategorias != undefined) {
                props.setIsExpanded(true)
            } else {
                if (categoria.filtros != undefined) {
                    props.setSelectedSubcategoria({idCategoria: categoria.idCategoria, nome: categoria.nome, filtros: categoria.filtros, alias: categoria.alias })
                } else {
                    props.setSelectedSubcategoria({idCategoria: 0, nome: '', filtros: []}) 
                }
            }
        }
    }

    return (
        <div className="menu-accordion-produtos">
            <Accordion allowZeroExpanded={true}>
                {props.categorias?.map((categoria:ICategoria) => {
                    return (
                        <AccordionItem key={categoria.idCategoria} dangerouslySetExpanded={categoria.subCategorias != undefined ? props.isExpanded : undefined}>
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                    <div
                                        className="menu-produtos-category-item"
                                        onMouseOver={() => onMouseOver(categoria)}
                                    >
                                        <Link
                                            to={`categoria/${categoria.alias}`}
                                            onClick={() => props.handleCloseMenus()}
                                        >
                                            {t(categoria.nome)} 
                                        </Link>

                                        {categoria.subCategorias && 
                                            <ArrowForwardIosIcon
                                                fontSize="small"
                                                className="accordion-icon"
                                                onClick={() => props.setIsExpanded(!props.isExpanded)}
                                            />
                                        }

                                        {!matches && categoria.filtros ?
                                            <ArrowForwardIosIcon
                                                fontSize="small"
                                                className="accordion-icon"
                                                onClick={() => props.setSelectedSubcategoria({idCategoria: categoria.idCategoria, nome: categoria.nome, filtros: categoria.filtros})}
                                                
                                            />
                                        : ''}

                                    </div>
                                </AccordionItemButton>
                            </AccordionItemHeading>
                            {categoria.subCategorias &&  
                                
                                <AccordionItemPanel className={`accordion__panel ${![1, 2, 3, 4].includes(props.IdSelectedSubcategoria)  ? 'inactive' : 'active'}`}>
                                    <ul>
                                        {categoria.subCategorias.map((subCategoria:ISubCategoria, index:number) => {
                                            return (
                                                <li
                                                    className={`menu-produtos-subcategoria-item ${props.IdSelectedSubcategoria == subCategoria.idCategoria ? 'selected-menu-produtos-subcategoria-item' : ''}`}
                                                    onMouseOver={() => matches ? props.setSelectedSubcategoria(subCategoria) : undefined}
                                                    onClick={() => matches ? undefined : props.setSelectedSubcategoria(subCategoria)}
                                                    key={index}
                                                >   
                                                    <Link
                                                        to={matches ? `categoria/${subCategoria.alias}` : ''}
                                                        onClick={() => matches ? props.handleCloseMenus() : undefined}
                                                    >
                                                        {t(subCategoria.nome)}
                                                    </Link>

                                                    <ArrowForwardIosIcon
                                                        fontSize="small"
                                                        className="accordion-icon"
                                                        onClick={() => props.setSelectedSubcategoria(subCategoria)}
                                                    />
                                                </li>
                                            )
                                        })}
                                    </ul>
                                </AccordionItemPanel>
                            }
                        </AccordionItem>
                    )
                })}
            </Accordion>
        </div>
    )
}