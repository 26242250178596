import { Grid } from '@mui/material'
import { useTranslation } from 'react-i18next'

import continua from '../../assets/assistant-colacril/continua_step2.webp'
import branca from '../../assets/assistant-colacril/branco_step1.webp'
import multiuso from '../../assets/assistant-colacril/multiuso_step2.webp'
import React from 'react'

export default function Step2(props: { handleSelect: Function}) {
	const { t } = useTranslation('assistenteBusca')

	return (
		<>
			<h2>{t("Escolha o tipo desejado: ")}</h2>
			
			<Grid container className='assistant-grid-container' spacing={1}>
				<Grid item xs={12} sm={4} >
					<div className='assistant-grid-item' onClick={() => props.handleSelect("Inkjet")}>
						<img alt="" src={branca}  />
						<p className="assistant-grid-text">{t("Inkjet ou laser")}</p>
					</div>
				</Grid>

				<Grid item xs={12} sm={4} >
					<div className='assistant-grid-item' onClick={() => props.handleSelect("Multiuso")}>
						<img alt="" src={multiuso}  />
						<p className="assistant-grid-text">{t("Multiuso")}</p>
					</div>
				</Grid>
				
				<Grid item xs={12} sm={4} >
					<div className='assistant-grid-item' onClick={() => props.handleSelect("Continuo")}>
						<img alt="" src={continua}  />
						<p className="assistant-grid-text">{t("Rolo contínuo")}</p>
					</div>
				</Grid>
			</Grid>
		</>
	)
}