import Grid from '@mui/material/Grid'
import React from 'react'
import { useTranslation } from 'react-i18next'

export default function Step3Continuo(props: {handleSelect:Function }) {

	const { t } = useTranslation('assistenteBusca')

    return (
        <>
            <h2>{t("Escolha a forma desejada: ")}</h2>
            
            <Grid container className='assistant-grid-container' spacing={1}>
                <Grid item xs={6} md={3}>
                    <button type='submit'>
                    <div className='assistant-grid-item' onClick={() => props.handleSelect(1)}>
                        <h1>1</h1>
                        <p className="assistant-grid-text">{t("Carreira")}</p>
                    </div>   
                    </button>             
                </Grid>
                
                <Grid item xs={6} md={3}>
                <button type='submit'>
                    <div className='assistant-grid-item' onClick={() => props.handleSelect(2)}>
                        <h1>2</h1>
                        <p className="assistant-grid-text">{t("Carreiras")}</p>
                    </div>
                    </button> 
                </Grid>
                
                <Grid item xs={6} md={3}>
                <button type='submit'>
                    <div className='assistant-grid-item' onClick={() => props.handleSelect(3)}>
                        <h1>3</h1>
                        <p className="assistant-grid-text">{t("Carreiras")}</p>
                    </div>
                    </button> 
                </Grid>
                
                <Grid item xs={6} md={3}>
                <button type='submit'>
                    <div className='assistant-grid-item' onClick={() => props.handleSelect(4)}>
                        <h1>4</h1>
                        <p className="assistant-grid-text">{t("Carreiras")}</p>
                    </div>
                    </button> 
                </Grid>
                
                <Grid item xs={6} md={4}>
                <button type='submit'>
                    <div className='assistant-grid-item' onClick={() => props.handleSelect(5)}>
                        <h1>5</h1>
                        <p className="assistant-grid-text">{t("Carreiras")}</p>
                    </div>
                    </button> 
                </Grid>
                
                <Grid item xs={6} md={4}>
                <button type='submit'>
                    <div className='assistant-grid-item' onClick={() => props.handleSelect(8)}>
                        <h1>8</h1>
                        <p className="assistant-grid-text">{t("Carreiras")}</p>
                    </div>
                    </button> 
                </Grid>
                
                <Grid item xs={6} md={4}>
                <button type='submit'>
                    <div className='assistant-grid-item' onClick={() => props.handleSelect(10)}>
                        <h1>10</h1>
                        <p className="assistant-grid-text">{t("Carreiras")}</p>
                    </div>
                    </button> 
                </Grid>
            </Grid>
        </>
    )
}