import { useTranslation } from 'react-i18next';
import { Link } from "react-router-dom";

import Carousel from 'react-multi-carousel';
import { BackArrow, ForwardArrow } from '../common/slideshowArrows'
import CustomDot from '../common/CustomDot';

import etiquetas_adesivas from '../../assets/homescreen/Etiquetas_adesivas.webp'
import filmes_adesivos from '../../assets/homescreen/Filmes_adesivos.webp'
import fitas_adesivas from '../../assets/homescreen/Fitas_adesivas.webp'
import blocos_adesivos from '../../assets/homescreen/Blocos_adesivos.webp'
import marcadores_paginas from '../../assets/homescreen/Marcadores_pagina.webp'
import { ICategoria } from '../../interfaces/produto/categoria';


export default function ProductCategories(props: { categorias: ICategoria[] }) {
    const { t } = useTranslation('homepage')

    const responsive = {
        desktop: {
            breakpoint: { max: 5000, min: 1200 },
            items: 5,
        },
        tablet: {
            breakpoint: { max: 1200, min: 900 },
            items: 3,
            partialVisibilityGutter: 50
        },
        mobile: {
            breakpoint: { max: 900, min: 600 },
            items: 2,
            partialVisibilityGutter: 50
        },
        mobileSmall: {
            breakpoint: { max: 600, min: 400 },
            items: 2,
            partialVisibilityGutter: 20
        },
        mobileExtraSmall: {
            breakpoint: { max: 399, min: 1 },
            items: 1,
            partialVisibilityGutter: 150,
        }
    };


    return (
        <section className="row-categories-super-container">
            {props.categorias != undefined && props.categorias.length != 0 ? 
                <Carousel
                    swipeable
                    draggable
                    showDots
                    renderDotsOutside
                    infinite={false}
                    partialVisible
                    //centerMode
                    arrows
                    // @ts-ignore
                    customDot={<CustomDot />}
                    customLeftArrow={<BackArrow type='carousel' />}
                    customRightArrow={<ForwardArrow type='carousel' />}
                    responsive={responsive}
                    shouldResetAutoplay={false}
                    ssr={true}
                >   
                    <div className='row-categories-container'>
                        <Link to={`categoria/${props.categorias[0].alias}`} className='row-categories-link'>
                            <img alt="" src={etiquetas_adesivas} className="row-categories-image" />
                            
                            <p className='row-categories-text'>{props.categorias[0].nome}</p>
                            <p className="row-categories-descripton">
                                {t("Etiquetas inkjet e laser, Multiuso e Contínuas. Indicadas para uso doméstico, corporativo e/ou escolar.")}
                            </p>
                        </Link>
                    </div>

                    <div className='row-categories-container'>
                        <Link to={`categoria/${props.categorias[1].alias}`} className='row-categories-link'>
                            <img alt="" src={filmes_adesivos} className="row-categories-image" />
                            
                            <p className='row-categories-text'>{props.categorias[1].nome}</p>
                            <p className="row-categories-descripton">{t("Qualidade, durabilidade e flexibilidade para revestir, encapar e plastificar.")}</p>
                        </Link>
                    </div>

                    <div className='row-categories-container'>
                        <Link to={`categoria/${props?.categorias[2].alias}`} className='row-categories-link'>
                            <img alt="" src={fitas_adesivas} className="row-categories-image" />         
                            
                            <p className='row-categories-text'>{props.categorias[2].nome}</p>
                            <p className="row-categories-descripton">{t("Garantem resistência no fechamento de pacotes, caixas e embalagens.")}</p>
                        </Link>
                    </div>

                    <div className='row-categories-container'>
                        <Link to={`categoria/${props.categorias[3].alias}`} className='row-categories-link'>
                            <img alt="" src={blocos_adesivos} className="row-categories-image" />
                            
                            <p className='row-categories-text'>{props.categorias[3].nome}</p>       
                            <p className="row-categories-descripton">{t("Blocos adesivos reposicionáveis. Ideais para escrever lembretes, destacar e organizar idéias.")}</p>
                        </Link>
                    </div>

                    <div className='row-categories-container'>
                        <Link to={`categoria/${props.categorias[4].alias}`} className='row-categories-link'>
                            <img alt="" src={marcadores_paginas} className="row-categories-image" />
                            
                            <p className='row-categories-text'>{props.categorias[4].nome}</p>          
                            <p className="row-categories-descripton">{t("Os marcadores ajudam na organização e busca por informações, garantindo agilidade no dia a dia através da organização.")}</p>
                        </Link>
                    </div>

                    
                </Carousel>
            : ''}
        </section>
    )
}