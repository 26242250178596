import { Button, Grid } from '@mui/material'
import { IDadosProduto } from '../../interfaces/produto/produto'
import React from 'react'

export default function DetalhesProduto(dados:IDadosProduto) {
    return (
        <>
            <Grid container spacing={0.5}>
                {dados.children.map((child, index) => {
                    return (
                        <Grid item xs={12} md={12/dados.children.length} key={index}>
                            <div className='caixa-texto-container'>
                                {child}
                            </div>
                        </Grid>
                    )
                })}
            </Grid>
        </>
    )
}