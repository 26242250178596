import Button from '@mui/material/Button'
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next'
import bg_image from '../../assets/pattern_colacril_assistente_busca.webp'
import forma from '../../assets/Forma-3.webp'
import React from 'react';

export default function TryAssistantHomepage() {
    const { t } = useTranslation('homepage')

    return (
        <>
            <div className="try-assistant-container">
                <img alt="" src={bg_image} className="try-assistant-homepage-bg-image"/>
                
                <div className="try-assistant-elements">
                    <h1 className='try-assistant-header'>
                        {t("Conte conosco para escolher a etiqueta perfeita")}
                    </h1>

                    <h3 className="assistant-homepage-h3">
                        {t("Utilize nosso assistente de busca para filtrar todos os modelos de acordo com as suas necessidades.")}
                    </h3>

                    <Link to="assistente" className="btn-link">
                        <Button
                            variant='contained'
                            className='btn btn-contained-primary btn-try-assistant'
                            disableElevation
                        >
                            {t("Assistente de busca")}
                        </Button>
                    </Link>
                </div>
                
                <div className="shape-divider-bottom-try-assistant-homepage">
                    <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                        <path d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z" className="shape-fill"></path>
                    </svg>
                </div>

                <img alt="" src={forma} className="try-assistant-forma-image"/>
            </div>        
        </>
    )
}