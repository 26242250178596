import AltText from "../common/altText";
import { Grid } from "@mui/material";
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { Trans, useTranslation } from "react-i18next";

import image1 from '../../assets/homescreen/images/tudo_que_precisa_casa.webp'
import image2 from '../../assets/homescreen/images/tudo_que_precisa_negocio.webp'
import image3 from '../../assets/homescreen/images/tudo_que_precisa_estudo.webp'
import { Link } from "react-router-dom";
import React from "react";


function TudoQuePrecisa() {
    const { t } = useTranslation('homepage')

    // TODO: COLOCAR TEXTO ALT NAS IMGS
    return (
        <div className="tudoQuePrecisa">
            <Trans t={t} i18nKey="A Colacril Office tem tudo que você precisa">
                <h1 className="tudoQuePrecisa-h1-title">
                    A <AltText>Colacril Office</AltText> tem tudo que você precisa
                </h1>
            </Trans>
        
            <div>
                <Grid container spacing={2}>
                    <Grid item xs={6} md={4} className="tudo-que-precisa-grid tudo-que-precisa-grid-effect">
                        <Link to="/categoria/ead?Uso=Domestico" className="link-tudo-que-precisa-link">
                            <img alt="" src={image1} className="tudoQuePrecisa-content-images" />

                            <div className="tudo-que-precisa-container">
                                <h2 className="tudo-que-precisa-title">{t("Para sua casa")}</h2>
                                <NavigateNextIcon />
                            </div>

                            <p className="tudo-que-precisa-paragraph">
                                {t("Garanta mais organização na sua cozinha, nos seus armários e em todo seu lar. Veja como é fácil!")}
                            </p>
                        </Link>
                    </Grid>

                    <Grid item xs={6} md={4} className="tudo-que-precisa-grid tudo-que-precisa-grid-effect">
                        <Link to="/categoria/ead?Uso=Corporativo" className="link-tudo-que-precisa-link">
                            <img alt="" src={image2} className="tudoQuePrecisa-content-images" />

                            <div className="tudo-que-precisa-container">
                                <h2 className="tudo-que-precisa-title">{t("Para seu negócio")}</h2>
                                <NavigateNextIcon />
                            </div>

                            <p className="tudo-que-precisa-paragraph">
                                {t("Identifique e faça o controle de tudo que sua empresa precisa, tornando-a mais eficiente. Sem complicações!")}
                            </p>
                        </Link>
                    </Grid>

                    <Grid item xs={6} md={4} className="tudo-que-precisa-grid tudo-que-precisa-grid-effect">
                        <Link to="/categoria/ead?Uso=Escolar" className="link-tudo-que-precisa-link">
                            <img alt="" src={image3} className="tudoQuePrecisa-content-images" />

                            <div className="tudo-que-precisa-container">
                                <h2 className="tudo-que-precisa-title">{t("Para os seus estudos")}</h2>
                                <NavigateNextIcon />
                            </div>

                            <p className="tudo-que-precisa-paragraph">
                                {t("Organize sua rotina de estudos para ter os melhores resultados de forma simples e prática!")}
                            </p>
                        </Link>
                    </Grid>
                </Grid>
            </div>
        </div>
    )
}

export default TudoQuePrecisa;