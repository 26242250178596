import { useEffect } from "react";
import { useBoardContext, useKeysCombinationHandler } from "@beontag/tageditor";
import { Button } from "@mui/material";
import { Undo as UndoIcon } from "@mui/icons-material";
import React from "react";

const Undo = () => {
    const { boardHistory } = useBoardContext();

    const ctrlZCount = useKeysCombinationHandler(["Control", "z"]);
    useEffect(() => {
        if (boardHistory && ctrlZCount) {
            boardHistory.undo();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ctrlZCount]);

    return (
        <Button
            className='btn' 
            startIcon={<UndoIcon />}
            onClick={boardHistory?.undo} 
            disabled={!boardHistory?.canUndo}
        > 
            Desfazer
        </Button>
    );
};

export default Undo;
