import React from 'react'
import { AxiosResponse } from "axios";
import jwtDecode from "jwt-decode";
import { createContext, useCallback, useContext, useMemo, useState } from "react"
import IEditorContext, { IProdutoEditor } from "../interfaces/context/editorContext";
import IUserContext from "../interfaces/context/userContext";
import { IEtiquetaUsuario } from "../interfaces/editor/etiquetaUsuario";
import { IFormaEditor } from "../interfaces/editor/formaEditor";
import { IUserData } from "../interfaces/editor/userData";

const UserContext = createContext<IUserContext | undefined>(undefined);

const UserContextProvider = (props: { children: JSX.Element,  }) => {

    // Verifica token do usuario, se ainda é valido ou não
    const handleIsLoggedIn = () => {
        if (typeof window !== 'undefined') {
            const jwtToken = sessionStorage.getItem("tokenJwt");
            if (!jwtToken) return false
            // @ts-ignore
            const { exp } = jwtDecode(jwtToken)
            return (Date.now() <= exp * 1000)   
        }
        return false  
    }

    function getUserData (): IUserData {
        if (typeof window !== 'undefined') {
            const jwtToken = sessionStorage.getItem("tokenJwt");
            if (!jwtToken) return { unique_name: '', email: '', atualizar_senha: 'False', idUsuario: 0 }
            return jwtDecode<IUserData>(sessionStorage.getItem("tokenJwt") || '')
        } 
       
        return { unique_name: '', email: '', atualizar_senha: 'False', idUsuario: 0 }
    }

    const [usuarioLogado, setUsuarioLogado] = useState<boolean>(handleIsLoggedIn());
    const [userData, setUserData] = useState<IUserData>(getUserData());
    const [userJwt, setUserJwt] = useState<string | null>(() => {
        if (typeof window !== 'undefined') {
            return sessionStorage.getItem('tokenJwt')
        } else {
            return ''
        }
    });

    const login = useCallback((jwtPayload: IUserData) => {
        setUsuarioLogado(true);
        setUserData(jwtPayload)
    }, []);

    const logoff = useCallback(() => {
        setUsuarioLogado(false);
        if (typeof window !== "undefined") {
            sessionStorage.removeItem('tokenJwt')
        }
    }, []);

    const clearJwt = useCallback(() => {
        if (typeof window !== "undefined") {
            sessionStorage.removeItem('tokenJwt')
        }
    }, [])

    const setJwt = useCallback((jwt: string | null) => {
        if (jwt && typeof window !== "undefined") {
            sessionStorage.setItem('tokenJwt', jwt)
            setUserJwt(jwt)
        }
    }, [])
    
    const data: IUserContext = {
        usuarioLogado,
        login, 
        logoff,
        userData,
        clearJwt,
        userJwt,
        setJwt,
    };
    
    return (
        <UserContext.Provider value={data}>
            {props.children}
        </UserContext.Provider>
    );
}


const useUserContext = () => {
    const context = useContext(UserContext);

    if (context === undefined) {
        throw new Error('useUserContext must be used within a UserContextProvider');
    }

    return context;
}


export {
    useUserContext
};

export default UserContextProvider;