import { useEffect, useState } from "react";
import { useOutletContext, Outlet } from "react-router-dom";
import Footer from "../../components/header/footer";
import Header from "../../components/header/header";
import { ICategoria } from "../../interfaces/produto/categoria";

import "../../styles/colacril/main.min.css";
import sProduto from '../../services/produtoService'
import React from "react";
import { useTranslation } from "react-i18next";
import Analytics from "../../analytics/analytics";

function ColacrilSite() {
    const [hasOpenMenu, setHasOpenMenu] = useState<boolean>(false);
    const [categorias, setCategorias] = useState<ICategoria[]>([])
	const { i18n } = useTranslation();

    useEffect(() => {

		const locale = i18n.language;

		sProduto.obtemCategorias(locale, false).then((resp) => {
			setCategorias(resp.data.categorias)
		}).catch((err) => {
			console.error(err)
		});
	}, [i18n.language])

    return (
        <>
            <Header setHasOpenMenu={setHasOpenMenu} categorias={categorias}/>
            <Analytics />
            <div className={hasOpenMenu ? 'overlay-active' : ''}>
                <div className="container">
                    <Outlet context={categorias} />
                </div>
            </div>
                    
            <Footer categorias={categorias}/>
        </>
    );
}


export default ColacrilSite;