import { useEditorContext } from "../../../context/EditorContext";
import TagEditor from "./tagEditor";
import TemplateSelection from "./templateSelection";
import React from "react";

function Steps() {
    const { produtoSelecionado } = useEditorContext();

    if (produtoSelecionado === undefined) {
        return <TemplateSelection />
    }

    return <TagEditor />;
}


export default Steps;