import { Box } from "@mui/material";
import Undo from './Undo';
import Redo from "./Redo";
import ZoomIn from "./ZoomIn";
import ZoomOut from "./ZoomOut";
import Remove from "./Remove";
import ClipboardContextProvider from "./ClipboardContext";
import Copy from "./Copy";
import Paste from "./Paste";
import Cut from "./Cut";
import Orientation from "./Orientation";
import Rotate from "./Rotate";
import Layers from "./Layers";
import Alignment from "./Alignment";
import GridLines from "./GridLines";
import Mirror from "./Mirror";
import React from "react";

function ActionBar() {
    const cutPasteDisplacement = 20;
    const zoomStep = 0.2;

	return (
        <ClipboardContextProvider>
            <Box className='editor-menu darken-green-color'>
                <div className="menu-item">
                    <Undo />
                    <Redo />
                </div>

                <div className="menu-item">
                    <Copy />
                    <Paste pasteDisplacement={cutPasteDisplacement} />
                </div>

                <div className="menu-item">
                    <Cut cutDisplacement={cutPasteDisplacement} />
                    <Remove />
                </div>

                <div className="menu-item">
                    <GridLines />
                    <Alignment />
                </div>

                <div className="menu-item">
                    <Orientation />
                </div>

                <div className="menu-item">
                    <Rotate />
                    <Layers />
                </div>

                <div className="menu-item">
                    <ZoomOut decreaseStep={zoomStep} />
                    <ZoomIn increaseStep={zoomStep} />
                </div>

                <div className="menu-item">
                    <Mirror />
                </div>
            </Box>
        </ClipboardContextProvider>
    );
}


export default ActionBar;