import Button from '@mui/material/Button'
import Slider from 'react-slick'

import banner_1 from '../../assets/homescreen/images/banner_1.webp'
import banner_2 from '../../assets/homescreen/images/banner_2.webp'
import banner_3 from '../../assets/homescreen/images/banner_3.webp'
import banner_4 from '../../assets/homescreen/images/banner_4.webp'
import banner_5 from '../../assets/homescreen/images/banner_5.webp'

import banner_1_mobile from '../../assets/homescreen/images/banner_1_mobile.webp'
import banner_2_mobile from '../../assets/homescreen/images/banner_2_mobile.webp'
import banner_3_mobile from '../../assets/homescreen/images/banner_3_mobile.webp'
import banner_4_mobile from '../../assets/homescreen/images/banner_4_mobile.webp'
import banner_5_mobile from '../../assets/homescreen/images/banner_5_mobile.webp'

import { BackArrow, ForwardArrow } from '../../components/common/slideshowArrows'
import { Trans, useTranslation } from 'react-i18next'

import useMediaQuery from '@mui/material/useMediaQuery';
import { createTheme } from '@mui/material/styles'
import { Link } from "react-router-dom";
import React from 'react'

export default function CarouselLanding() {
	const settings = {
		dots: true,
		infinite: true,
		speed: 500,
		slidesToShow: 1,
		slidesToScroll: 1,
		autoplaySpeed: 5000,
		cssEase: "linear",
		pauseOnHover: true,
		arrows: true,
		nextArrow: <ForwardArrow type='carousel'/>,
		prevArrow: <BackArrow type='carousel'/>,
		appendDots: (dots: any) => (
			<div className='carousel-landing-settings-div'>
				<ul className="carousel-landing-settings-ul"> {dots} </ul>
			</div>
		),

	};

    const { t } = useTranslation('homepage')
	const theme = createTheme();

	const matches = useMediaQuery(theme.breakpoints.up('md'));

	return (
		<div className='carousel-landing-super-container'>
			<Slider {...settings}>

				<Link to="/categoria/eil?Uso=ECommerce">
					<div className='carousel-landing-page-content-container'>
						<img alt="" src={matches ? banner_1 : banner_1_mobile} className="carousel-landing-page" />
						
						<div className='carousel-landing-page-text-container'>
							<h1 className='carousel-landing-page-title'>{t("Imprimiu, colou, enviou!")}</h1>
							<br/>
							<br/>
							<h2 className='carousel-landing-page-subtitle'>
								<Trans t={t} i18nKey="Com as etiquetas E-commerce da Colacril Office, você garante agilidade no envio dos seus produtos.">
									Com as etiquetas E-commerce da Colacril Office, você garante agilidade no envio dos seus\u00a0produtos.
								</Trans>
							</h2>
							{/* <Button disableElevation variant="contained" className='btn btn-contained-primary'>SAIBA MAIS</Button> */}
						</div>
					</div>
				</Link>

				<Link to="/categoria/eil">
					<div className='carousel-landing-page-content-container'>
						<img alt="" src={matches ? banner_2 : banner_2_mobile} className="carousel-landing-page"/>
						
						<div className='carousel-landing-page-text-container'>
							<h1 className='carousel-landing-page-title'>{t("Etiquetas que se adaptam às necessidades do dia a dia!")}</h1>
							<br/>
							<br/>
							<h2 className='carousel-landing-page-subtitle'>
								<Trans t={t} i18nKey="Uma linha completa com modelos e formatos variados para uso em impressoras inkjet ou laser.">
									Uma linha completa com modelos e formatos variados para uso em impressoras inkjet ou\u00a0laser.
								</Trans>
							</h2>
							{/* <Button disableElevation variant="contained" className='btn btn-contained-primary'>SAIBA MAIS</Button> */}
						</div>
					</div>
				</Link>

				<Link to="/categoria/eil">
					<div className='carousel-landing-page-content-container'>
						<img alt="" src={matches ? banner_3 : banner_3_mobile} className="carousel-landing-page"/>
						
						<div className='carousel-landing-page-text-container' >
							<h1 className='carousel-landing-page-title'>{t("Produtos organizados!")}</h1>
							<br/>
							<br/>
							<h2 className='carousel-landing-page-subtitle'>
								<Trans t={t} i18nKey="Vários modelos de etiquetas para organizar sua casa e identificar tudo que for importante para você.">
									Vários modelos de etiquetas para organizar sua casa e identificar tudo que for importante para\u00a0você.
								</Trans>
							</h2>
							{/* <Button disableElevation variant="contained" className='btn btn-contained-primary'>SAIBA MAIS</Button> */}
						</div>
					</div>
				</Link>

				<Link to="/categoria/fil">
					<div className='carousel-landing-page-content-container'>
						<img alt="" src={matches ? banner_4 : banner_4_mobile} className="carousel-landing-page" />
						
						<div className='carousel-landing-page-text-container' >
							<h1 className='carousel-landing-page-title'>{t("Filmes adesivos Colacril Office!")}</h1>
							<br/>
							<br/>
							<h2 className='carousel-landing-page-subtitle'>
								<Trans t={t} i18nKey="Proteção e praticidade para colocar sua criatividade em prática.">
									Proteção e praticidade para colocar sua criatividade em\u00a0prática.
								</Trans>
							</h2>
							{/* <Button disableElevation variant="contained" className='btn btn-contained-primary'>SAIBA MAIS</Button> */}
						</div>
					</div>
				</Link>

				<Link to="/categoria/blo">
					<div className='carousel-landing-page-content-container'>
						<img alt="" src={matches ? banner_5 : banner_5_mobile} className="carousel-landing-page" />
						
						<div className='carousel-landing-page-text-container'>
							<h1 className='carousel-landing-page-title'>{t("Você anota, a gente te lembra!")}</h1>
							<br/>
							<br/>
							<h2 className='carousel-landing-page-subtitle'>
								<Trans t={t} i18nKey="Com os blocos adesivos Colacril Office fica tudo mais fácil.">
									Com os blocos adesivos Colacril Office fica tudo mais\u00a0fácil.
								</Trans>
							</h2>
							{/* <Button disableElevation variant="contained" className='btn btn-contained-primary'>SAIBA MAIS</Button> */}
						</div>
					</div>
				</Link>

			</Slider>

			<div className="carousel-landing-bottom-divider">
				<svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
					<path d="M1200 120L0 16.48 0 0 1200 0 1200 120z" className="shape-fill"></path>
				</svg>
			</div>
		</div>
	)
}