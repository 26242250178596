import { useBoardContext, usePropertyContext } from "@beontag/tageditor";
import { IconButton, TextField } from "@mui/material";
import OpenWithIcon from '@mui/icons-material/OpenWith';
import CloseIcon from '@mui/icons-material/Close';
import React from "react";

const Circle: React.FunctionComponent = (props) => {
  const { properties, setProperty } = usePropertyContext<fabric.Circle>();

  const handleOnChange = (key: string, rawValue: string) => {
    setProperty({ [key]: rawValue });
  }

  const handleOnChangeNumber = (key: string, rawValue: string) => {
    const value = parseInt(rawValue)
    setProperty({ [key]: value });
  }

  const { board } = useBoardContext()

    const closeWindow = () => {
		board?.setActiveObjects()
	}

  return <>
    <div className="property-bar-title handle">
                            Propriedades do objeto
                            <OpenWithIcon className="icon left" />
                            <IconButton onClick={() => closeWindow()} className="icon right not-draggable">
                                <CloseIcon />
                            </IconButton>
                        </div>

    <div className="property-bar-itens">
      <label htmlFor="rect-cor-do-objeto">Cor do Objeto</label>
      <input
        className="property-bar-color-input"
        id="rect-cor-do-objeto"
        type="color"
        value={properties.fill?.toString() || "#000"}
        onChange={(e) => handleOnChange("fill", e.currentTarget.value)}
      ></input>
    </div>

    <div className="property-bar-itens">
      <label htmlFor="rect-cor-da-borda">Cor da Borda</label>
      <input
        className="property-bar-color-input"
        id="rect-cor-da-borda"
        type="color"
        value={properties.stroke?.toString() || "#000"}
        onChange={(e) => handleOnChange("stroke", e.currentTarget.value)}
      ></input>
    </div>

    <div className="property-bar-itens">
      <span>Tamanho da borda</span>
      <TextField
        className="property-box-input-width"
        type="number"
        InputProps={{ inputProps: { min: 0, max: 9, step: 1 } }}
        value={properties.strokeWidth?.toString() || "1"}
        onChange={(e) => handleOnChangeNumber("strokeWidth", e.currentTarget.value)}
      />
    </div>

    <div className="property-bar-itens">
      <span>Transparência do objeto</span>
      <TextField
        className="property-box-input-width"
        type="number"
        InputProps={{ inputProps: { min: 0.1, max: 1, step: 0.1 } }}
        value={properties.opacity || 1}
        onChange={(e) => handleOnChange("opacity", e.currentTarget.value)}
      />
    </div>
  </>
};

export default Circle;
