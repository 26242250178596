import React from 'react'
import { ICaixaTexto } from '../../interfaces/caixaTexto'

export default function CaixaTexto(dados:ICaixaTexto) {
    return (
        <>
            <h4 className='caixa-texto-title'>{dados.title}</h4>

            <div className='caixa-texto-content'>
                {dados.children}
            </div>
            
            <div className='linha-separator-caixa-texto'></div>
        </>
    )
}