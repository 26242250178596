import React from "react";

function AltText(props: {variant?: string, children?: React.ReactElement | any }) {
    return (
        <div className="dotted-bg">
            <div
                className={`dotted ${props.variant ? props.variant : ''}`}
            />

            <span
                className={`alt-text-background ${props.variant ? props.variant : ''}`}
            >
                {props.children}
            </span>  
        </div>
    )
}

export default AltText;