import React from 'react'
import { createContext, useContext, useState } from "react";

export interface IClipboardContextProvider {
    clipboard: fabric.Object[];
    setClipboard: React.Dispatch<React.SetStateAction<fabric.Object[]>>;
}

const ClipboardContext = createContext<IClipboardContextProvider>({
    clipboard: [],
    setClipboard: () => { }
});

export const useClipboardContext = () => useContext<IClipboardContextProvider>(ClipboardContext);

const ClipboardContextProvider: React.FunctionComponent<{children: React.ReactElement}> = (props) => {
    const [clipboard, setClipboard] = useState<Array<fabric.Object>>([]);

    return (
        <ClipboardContext.Provider value={{ clipboard, setClipboard }}>
            {props.children}
        </ClipboardContext.Provider>
    );
};

export default ClipboardContextProvider;
