import Button from '@mui/material/Button'
import linhaSeparator from '../../assets/separadores/separador-pagina-duvidas.webp'
import laptop from '../../assets/note.webp'
import laptop_new from '../../assets/laptop_pca_colacril.png'
import { Trans, useTranslation } from 'react-i18next'
import { Link } from "react-router-dom";

import useMediaQuery from '@mui/material/useMediaQuery';
import { createTheme } from '@mui/material/styles'
import React from 'react'


export default function TryPrintingSoftware() {
    const { t } = useTranslation('homepage')

    const theme = createTheme();

    const matches = useMediaQuery(theme.breakpoints.down('md'));

    return (
        <div className='try-printing-software-container'>
            <div className='try-printing-software-text-container' >
                <Trans t={t} i18nKey="Crie e imprima as suas etiquetas com o nosso software">
                    <h1>Crie e imprima as suas etiquetas com o nosso <b>software</b></h1>
                </Trans>

                <p>
                    {t("Facilite a impressão das suas etiquetas de acordo com o que você precisa.")}
                </p>
                
                <div className='try-printing-software-button-container'>
                    <Link to="downloads/download_software">
                        <Button
                            disableElevation
                            variant='contained'
                            className="btn btn-contained-secondary"
                        >
                            {t("Saiba mais")}
                        </Button>
                    </Link>

                    {!matches ?
                    <a href='/old_assistant/colacril_setup.exe' download className='btn-link'>
                        <Button
                            variant='outlined'
                            className="btn btn-outlined-secondary"
                        >
                            {t("Baixe agora")}
                        </Button>
                    </a>
                    
                    : ''
                    }

                    
                </div>
            </div>

            <img alt="" src={laptop_new} className='try-printing-software-image' />

            <div className="shape-divider-bottom-download-software-header shape-divider-bottom-try-software-header">
                <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                    <path d="M 0 0 V 22 C 213 80 327 113 600 112.77 S 1056 66 1199 22 V 0 Z" className="shape-fill"></path>
                </svg>
            </div>
            
            <img src={linhaSeparator} className="separator-linha-bottom try-printing-separator-linha-bottom" aria-hidden="true" />
        </div>
    )
}