import { useEffect } from "react";
import { handlers, useBoardContext } from "@beontag/tageditor";

const GlobalHandlers = () => {
    const { board, boardHandlers, boardZoom } = useBoardContext();

    useEffect(() => {
        if (board && boardHandlers && boardZoom) {
            boardHandlers.add(handlers.createAligninGuidelineHandler(board, boardZoom));
            boardHandlers.add(handlers.createCenteringGuidelineHandler(board, boardZoom));
            //boardHandlers.add(handlers.createZoomWhellHandler(boardZoom));
            boardHandlers.add(handlers.createZoomWorkspaceWheelHandler(board, boardZoom))
        }
    }, [board, boardHandlers, boardZoom]);

    return null;
};

export default GlobalHandlers;
