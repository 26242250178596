import { Grid, TextField, InputAdornment, Button } from "@mui/material";
import { useState } from "react";

import sUsuario from '../../../services/usuarioService';
import { useMessage } from "../../../context/alertContext/context";

function PasswordRecover(props: { modifyActiveComponent: Function }) {

    const [form, setForm] = useState<{ [key: string]: string }>({
        email: ''
    });

    const { showAlertApp } = useMessage();

    const handleSubmit = async (e: any) => {
        e.preventDefault()


        await sUsuario.recoverPassword(form).then((resp) => {
            showAlertApp('Caso esse usuário exista em nosso banco de dados, enviaremos um email com uma senha temporária', 'success', 5000)
            props.modifyActiveComponent("login")
        })
        .catch((err) => {
            showAlertApp("Houve um erro ao recuperar a senha, tente novamente mais tarde", 'error', 5000)
        })
    }

    return (
        <div className="editor-login-container">

            <div className="editor-login-title">
                <h2 className="editor-login-text">Esqueci minha senha</h2>
            </div>

            <p className="editor-login-secondary-text">Informe o seu e-mail para solicitar uma nova senha provisória.</p>

            <Grid container rowSpacing={2} className="editor-login-authentication">


                <Grid item xs={12}>
                    <TextField
                        //className="editor-login-authentication-fields"
                        id="email"
                        type="email"
                        name="email"
                        aria-label="Email: "
                        variant="outlined"
                        value={form.email}
                        onChange={(e) => setForm({ email: e.target.value })}
                        fullWidth
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start" disablePointerEvents className="editor-login-authentication-fields-adornment">
                                    <span className='form-label'>
                                        E-mail:
                                    </span>
                                </InputAdornment>
                            ),
                        }}
                    />
                </Grid>

                <Grid item xs={12} className="button">
                    <Button
                        type='submit'
                        variant="contained"
                        className='btn btn-outlined-secondary'
                        disableElevation
                        onClick={(e) => handleSubmit(e)}
                    >
                        Enviar
                    </Button>

                    <br />
                    <br />

                    <p
                        role="button"
                        className="editor-login-tertiary-text editor-login-final-tertiary-text"
                        onKeyDown={() => props.modifyActiveComponent("login")}
                        onClick={() => props.modifyActiveComponent("login")}
                    >
                        Retornar para a tela de autenticação.
                    </p>


                </Grid>

            </Grid>


        </div>



    );
}

export default PasswordRecover;