import api from "../api";

const listProduto = async function(locale: string, filters: {[key: string]: string}, categoria?:string) {
    const params = filters;

    return api.get(`/categorias/${categoria}/produtos`, {
        params,
        headers: {
            "Accept-Language": locale
        }
    });
}

const obtemProduto = async function(locale:string, codigo?:string) {
    return api.get(`/produtos/${codigo}`, {
        headers: {
            "Accept-Language": locale
        }
    })
}

const obtemCategorias = async function(locale:string, products: boolean, produtosEditor?: boolean) {

    if (produtosEditor) {
        return api.get(`/categorias?ComProduto=${products}&ProdutosEditor=${produtosEditor != undefined ? produtosEditor : false}`, {
            headers: {
                "Accept-Language": locale
            }
        })
    } else {
        return api.get(`/categorias?ComProduto=${products}`, {
            headers: {
                "Accept-Language": locale
            }
        })
    }

}


const pesquisaProduto = async function(locale:string, termo:string) {
    return api.get("/produtos", {
        params: {
            pesquisa: termo
        },
        headers: {
            "Accept-Language": locale
        }
    })
}

const assistenteBusca = async function (locale: string, branco:string, categoria: string, forma:string, rolos: string) {
    return api.get('/produtos/assistente', {
        params: {
            branco: branco,
            categoria: categoria,
            forma: forma,
            rolos: rolos
        },
        headers: {
            "Accept-Language": locale
        }
    })
}

const produtoService = {
    listProduto,
    obtemProduto,
    obtemCategorias,
    pesquisaProduto,
    assistenteBusca
}

export default produtoService;