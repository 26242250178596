import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Languages } from "../../i18n/i18nextOptions";
import React from "react";

export default function LanguageSelector() {

    const { i18n } = useTranslation();
	const [lang, setLang] = useState<string>(i18n.language);

	const handleChangeLanguage = (language: Languages) => {
		setLang(language)
		i18n.changeLanguage(language)
	}

    return (
        <div className="language-selector-container">
            
                {Object.values(Languages).map(language => {
                    return (
                        <label key={language}>
                            <span
                                className={`language-selector-container ${language === lang ? 'active' : ''}`}
                                onClick={() => handleChangeLanguage(language)}>
                                {language}
                                {/* TODO: Remoção de nobreaking space */}
                                &nbsp;
                            </span>
                            
                        </label>
                    );
                })}

        </div>
    )
}
