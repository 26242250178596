import { AddCurvedTextFeature, AddCurvedTextFeatures, AddTextFeature, AddTextFeatures } from "@beontag/tageditor";
import { KeyboardArrowDown, TextFormat } from "@mui/icons-material";
import { Button, Menu, MenuItem } from "@mui/material";
import React from "react";
import { useState } from "react";

export default function Teste2(props: { setMenuAnchorEl: Function }) {

    const [textFeature, setTextFeature] = useState<AddTextFeatures>();
    const [curvedTextFeature, setCurvedTextFeature] = useState<AddCurvedTextFeatures>();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    function onClickCurvedText(e: React.MouseEvent<HTMLLIElement, MouseEvent>) {
        curvedTextFeature?.add();
        props.setMenuAnchorEl(e.currentTarget)
    }

    function onClick(e: React.MouseEvent<HTMLLIElement, MouseEvent>) {
        textFeature?.add()
        setAnchorEl(null)
        props.setMenuAnchorEl(e.currentTarget)
    }

    return (
        <>
            <div className="menu-item">
                <Button
                    className='btn'
                    startIcon={<TextFormat />}
                    endIcon={<KeyboardArrowDown />}
                    onClick={e => setAnchorEl(e.currentTarget)}
                > Texto
                </Button>

                <Menu
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={() => setAnchorEl(null)}
                    PaperProps={{
                        className: 'menu-dropdown'
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left'
                    }}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left'
                    }}
                    className="editor-tb-menu"
                >
                    <AddTextFeature featureUpdate={f => setTextFeature(f)}>
                        <MenuItem disableRipple onClick={e => onClick(e)} className="tb-qr-barcode-menuitem">
                        
                            Texto

                        </MenuItem>
                    </AddTextFeature>
                    <AddCurvedTextFeature featureUpdate={f => setCurvedTextFeature(f)}>
                        <MenuItem disableRipple onClick={e => onClickCurvedText(e)} className="tb-qr-barcode-menuitem">
                        
                            Texto curvo

                        </MenuItem>
                    </AddCurvedTextFeature>

                </Menu>

            </div>
        </>
    )
}