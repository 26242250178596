import IProdutoCard from '../../interfaces/produto/produtoCardColacril';

import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';

import CarouselProductCard from './carousel-product-card';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import React from 'react';


function ProdutoCard(produto: IProdutoCard) {

  const { t } = useTranslation('produtos')

  return (
    <Card className="product-card" elevation={0}>
      <CardMedia component="div" children={<CarouselProductCard images={produto.imagens}/>} />
      
      <CardContent>
        <div className='product-card-info'>

          <span className="product-card-info-data">
            <h2 className="product-card-title">{produto.nome}</h2>
            { produto.dimensao ? <h3 className="product-card-subtitle small-bottom-margin">{produto.dimensao}</h3> : ''}

            { produto.espessura ? <h3 className="product-card-subtitle">{produto.espessura}</h3> : ''}
            
            { produto.carreira ? <p className="product-card-text">{t("Carreira")}: {produto.carreira}</p> : '' }

            { produto.forma ? <p className="product-card-text">{t("Forma")}: {produto.forma}</p> : '' } 
            
            { produto.items?.map((item, index) => {
              return (
                <p key={index} className="product-card-text"><b>{item.codigo} {item.folhas ? ':' : ''}</b> {item.folhas ? item.folhas : ''}</p>
              )
            })}
            {produto.quantidadeFolhas ? 
              <p className="product-card-text">{t("Quantidade de folhas")}: {produto.quantidadeFolhas}</p>
            : '' }
          </span>
          <h3 className="product-card-text">
            <Link className="product-card-text more-info" to={`/produto/${produto.items[0].codigo}`}> {t('Mais informações')} <NavigateNextIcon fontSize='small'/></Link > 
          </h3>
        </div>
      </CardContent>
      
      <CardActions />
    </Card>
  );
}

export default ProdutoCard;