import AltText from '../../../../components/common/altText';
import { useTranslation } from 'react-i18next';
import header_image from '../../../../assets/header_resp_socioamb.webp'
import image from '../../../../assets/hand_holding_resp_socioamb.webp'
import React from 'react';

function Premios() {
    const { t } = useTranslation("aEmpresa");

    return (
        <>
            <div className='content-header-container'>
                <img alt="" src={header_image} className="content-header-image" />

                <div className='content-header-image-text'>
                    <h1><AltText>{t("RESPONSABILIDADE SOCIOAMBIENTAL")}</AltText></h1>
                </div>
                
                <div className="shape-divider-bottom-header-contato">
                    <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                        <path d="M1200 120L0 16.48 0 0 1200 0 1200 120z" className="shape-fill"></path>
                    </svg>
                </div>
            </div>

            <div className='page-content'>
                {/* Imagem aqui */}

                <p className='page-paragraph'>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam finibus auctor nunc, nec varius ipsum dapibus id. Duis volutpat tempor consectetur. Ut augue magna, elementum vel felis a, molestie molestie lorem. Aliquam ultrices nunc enim, in dictum diam fringilla in. Nam quis consequat massa. Suspendisse eu dignissim risus. Maecenas ac ipsum ante. Maecenas vehicula tempus porta. Quisque condimentum cursus erat sodales varius. Curabitur bibendum turpis ut nisi scelerisque sodales.
                </p>

                <div className="award-container">
                    <img className='certificate-image' src={image} alt="" />

                    <h2 className='sub-page-title award-title'>Prêmio 1</h2>

                    <p className='page-paragraph paragraph-award'>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam finibus auctor nunc, nec varius ipsum dapibus id. Duis volutpat tempor consectetur. Ut augue magna, elementum vel felis a, molestie molestie lorem.
                    </p>
                </div>

                <div className="award-container">
                    <img className='certificate-image' src={image} alt="" />

                    <h2 className='sub-page-title award-title'>Prêmio 2</h2>

                    <p className='page-paragraph paragraph-award'>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam finibus auctor nunc, nec varius ipsum dapibus id. Duis volutpat tempor consectetur. Ut augue magna, elementum vel felis a, molestie molestie lorem.
                    </p>
                </div>

                <div className="award-container">
                    <img className='certificate-image' src={image} alt="" />

                    <div className='award-text-container'>
                        <h2 className='sub-page-title award-title'>Prêmio 3</h2>

                        <p className='page-paragraph paragraph-award'>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam finibus auctor nunc, nec varius ipsum dapibus id. Duis volutpat tempor consectetur. Ut augue magna, elementum vel felis a, molestie molestie lorem.
                        </p>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Premios;
