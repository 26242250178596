import { Typography } from "@mui/material";
import { useState } from "react";
import ModalMensagensGerais from "../contacts-forms/modalMensagensGerais";
import PoliticaDePrivacidade from "../contacts-forms/politicaDePrivacidade";
import React from "react";

function Footer() {
  const [openPrivacyPolicyDialog, setOpenPrivacyPolicyDialog] =
    useState<boolean>(false);
  const [openConsentTermsDialog, setOpenConsentTermsDialog] =
    useState<boolean>(false);

  return (
    <>
      <footer className="editor-footer">
        <Typography
          component="a"
          variant="body2"
          onClick={() => setOpenPrivacyPolicyDialog(true)}
        >
          Termos do Site e Política de Privacidade
        </Typography>

        <Typography
          component="a"
          variant="body2"
          onClick={() => setOpenConsentTermsDialog(true)}
        >
          Termo de Consentimento
        </Typography>

        <Typography component="span" variant="body2">
          © Colacril 2023. Todos os direitos reservados.
        </Typography>
      </footer>

      <ModalMensagensGerais
        scroll="paper"
        handleClose={() => setOpenConsentTermsDialog(false)}
        open={openConsentTermsDialog}
      />

      <PoliticaDePrivacidade
        scroll="paper"
        handleClose={() => setOpenPrivacyPolicyDialog(false)}
        open={openPrivacyPolicyDialog}
      />
    </>
  );
}

export default Footer;
