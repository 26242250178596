import { useEffect } from "react";
import { Fonts, useBoardContext, useKeysCombinationHandler, usePropertyContext } from "@beontag/tageditor";
import { fabric } from 'fabric';
import { IconButton, MenuItem, Select, Stack, TextField } from "@mui/material";

import BoldIcon from '@mui/icons-material/FormatBold';
import ItalicIcon from '@mui/icons-material/FormatItalic';
import UnderlineIcon from '@mui/icons-material/FormatUnderlined';
import OverlineIcon from '@mui/icons-material/FormatOverline';
import StrikeIcon from '@mui/icons-material/StrikethroughS';
import FormatAlignLeftIcon from '@mui/icons-material/FormatAlignLeft';
import FormatAlignRightIcon from '@mui/icons-material/FormatAlignRight';
import FormatAlignJustifyIcon from '@mui/icons-material/FormatAlignJustify';
import FormatAlignCenterIcon from '@mui/icons-material/FormatAlignCenter';

import OpenWithIcon from '@mui/icons-material/OpenWith';
import CloseIcon from '@mui/icons-material/Close';
import React from "react";

const DataFieldText: React.FunctionComponent = () => {
	const { properties, setProperty } = usePropertyContext<fabric.DataFieldText>();

	const keysPressCountBold = useKeysCombinationHandler(["Control", "b"]);
	useEffect(() => {
		if (keysPressCountBold) {
			fontWeightToggle();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [keysPressCountBold]);

	const keysPressCountItalic = useKeysCombinationHandler(["Control", "i"]);
	useEffect(() => {
		if (keysPressCountItalic) {
			fontStyleToggle();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [keysPressCountItalic]);

	const fontWeightToggle = () => {
		setProperty({ fontWeight: properties.fontWeight === "normal" ? "bold" : "normal" });
	}

	const fontStyleToggle = () => {
		setProperty({ fontStyle: properties.fontStyle === "normal" ? "italic" : "normal" });
	}

	const handleOnChange = (key: string, rawValue: string | boolean, type?: 'int') => {
		let value: string | boolean | number = rawValue;

		if (type === 'int' && typeof rawValue === 'string') {
			value = Number.parseInt(rawValue);
		}

		setProperty({ [key]: value });
	}

	const handleOnChangeBooleanProp = (key: string, rawValue: boolean) => {
		setProperty({ [key]: rawValue });
	}

	const { board } = useBoardContext()

	const closeWindow = () => {
		board?.setActiveObjects()
	}

	return (
		<>
			<div className="property-bar-title handle">
				Propriedades do objeto
				<OpenWithIcon className="icon left" />
				<IconButton onClick={() => closeWindow()} className="icon right not-draggable">
					<CloseIcon />
				</IconButton>
			</div>

			<div className="property-bar-itens">
				<span>Nome do campo</span>
				<Select
					value={properties.fieldName || ''}
					onChange={(e) => handleOnChange("fieldName", e.target.value)}
					//variant="filled"
					//className="barcode-legenda-select"
					className="short-select"
				>
					{
						properties.dataSource?.dataSource?.keys?.map((fieldNameOption, index) => {
							return <MenuItem value={fieldNameOption} key={index}>{fieldNameOption} </MenuItem>
						})
					}
				</Select>
			</div>
			<div className="property-bar-itens">
				<span>Fonte</span>
				<Select
					value={properties.fontFamily || "Times New Roman"}
					onChange={(e) => handleOnChange("fontFamily", e.target.value)}
				// variant="filled"
				// className="barcode-legenda-select"
				>
					{
						Fonts.getList().map((font, index) => {
							return <MenuItem value={font} key={index}> {font} </MenuItem>
						})
					}
				</Select>
			</div>
			<div className="property-bar-itens">
				<label htmlFor="cor-do-objeto" className="cursor-pointer">Cor do Objeto</label>
				<input
					id="cor-do-objeto"
					className="property-bar-color-input"
					type="color"
					value={properties.fill?.toString() || "#000"}
					onChange={(e) => handleOnChange("fill", e.currentTarget.value)}
				></input>
			</div>
			<div className="property-bar-itens">
				<label htmlFor="cor-da-borda" className="cursor-pointer">Cor da Borda</label>
				<input
					id="cor-da-borda"
					className="property-bar-color-input"
					type="color"
					value={properties.stroke?.toString() || "#000"}
					onChange={(e) => handleOnChange("stroke", e.currentTarget.value)}
				></input>
			</div>
			<div className="property-bar-itens">
			<label htmlFor="cor-da-borda" className="cursor-pointer">Espessura da borda</label>
				<TextField
					fullWidth
					className="property-box-input-width"
					type="number"
					InputProps={{ inputProps: { min: 0, step: 1 } }}
					value={properties.strokeWidth?.toString() || 0}
					onChange={(e) => handleOnChange("strokeWidth", e.currentTarget.value, 'int')}
					variant="outlined"
				/>

			</div>

			<div className="property-bar-itens">
				<label htmlFor="tamanho-da-fonte" className="cursor-pointer">Tamanho da fonte</label>
				<TextField
					fullWidth
					className="property-box-input-width"
					type="number"
					InputProps={{ inputProps: { min: 0, step: 1 } }}
					value={properties.fontSize || 15}
					onChange={(e) => handleOnChange("fontSize", e.currentTarget.value)}
					variant="outlined"
				/>
			</div>

			<div className="property-bar-itens">
				<label htmlFor="tamanho-da-fonte" className="cursor-pointer">Transparencia</label>
				<TextField
					fullWidth
					className="property-box-input-width"
					type="number"
					InputProps={{ inputProps: { min: 0, max: 1, step: 0.1 } }}
					value={properties.opacity || 1}
					onChange={(e) => handleOnChange("opacity", e.currentTarget.value)}
					variant="outlined"
				/>
			</div>
			{/* Text alignment. Possible values: "left", "center", "right", "justify", "justify-left", "justify-center" or "justify-right". */}
			<Stack direction='row' className="property-bar-text-options">
				<IconButton
					aria-label="Negrito"
					size="large"
					onClick={fontWeightToggle}
					className={`property-bar-text-options-button ${properties.fontWeight == 'bold' ? 'active' : ''}`}
				>
					<BoldIcon />
				</IconButton>

				<IconButton
					aria-label="Itálico"
					size="large"
					onClick={fontStyleToggle}
					className={`property-bar-text-options-button ${properties.fontStyle == 'italic' ? 'active' : ''}`}
				>
					<ItalicIcon />
				</IconButton>

				<IconButton
					aria-label="Sublinhado"
					size="large"
					onClick={() => {
						if (properties.underline) {
							handleOnChangeBooleanProp("underline", false);
						} else {
							handleOnChangeBooleanProp("underline", true)
						}
					}
					}
					className={`property-bar-text-options-button ${properties.underline ? 'active' : ''}`}>
					<UnderlineIcon />
				</IconButton>

				<IconButton
					aria-label="Sobrelinha"
					size="large"
					onClick={() => {
						if (properties.overline) {
							handleOnChangeBooleanProp("overline", false);
						} else {
							handleOnChangeBooleanProp("overline", true)
						}
					}}
					className={`property-bar-text-options-button ${properties.overline ? 'active' : ''}`}>
					<OverlineIcon />
				</IconButton>

				<IconButton
					aria-label="Tachado"
					size="large"
					onClick={() => {
						if (properties.linethrough) {
							handleOnChangeBooleanProp("linethrough", false);
						} else {
							handleOnChangeBooleanProp("linethrough", true)
						}
					}}
					className={`property-bar-text-options-button ${properties.linethrough ? 'active' : ''}`}>
					<StrikeIcon />
				</IconButton>
			</Stack>
			<Stack direction='row' className="property-bar-text-options">
				<IconButton
					aria-label="Negrito"
					size="large"
					onClick={_ => handleOnChange("textAlign", 'left')}
					className={`property-bar-text-options-button ${properties.textAlign == 'left' ? 'active' : ''}`}
				>
					<FormatAlignLeftIcon />
				</IconButton>

				<IconButton
					aria-label="Itálico"
					size="large"
					onClick={_ => handleOnChange("textAlign", 'center')}
					className={`property-bar-text-options-button ${properties.textAlign == 'center' ? 'active' : ''}`}
				>
					<FormatAlignCenterIcon />
				</IconButton>

				<IconButton
					aria-label="Sublinhado"
					size="large"
					onClick={_ => handleOnChange("textAlign", 'right')}
					className={`property-bar-text-options-button ${properties.textAlign == 'right' ? 'active' : ''}`}
				>
					<FormatAlignRightIcon />
				</IconButton>

				<IconButton
					aria-label="Sobrelinha"
					size="large"
					onClick={_ => handleOnChange("textAlign", 'justify')}
					className={`property-bar-text-options-button ${properties.textAlign == 'justify' ? 'active' : ''}`}
				>
					<FormatAlignJustifyIcon />
				</IconButton>

			</Stack>

		</>
	);
};

export default DataFieldText;
