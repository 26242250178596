import { Button } from '@mui/material'
import { useRef, useState } from 'react'
import { Stepper, Step, StepLabel } from '@mui/material'
import Step1 from './step1'
import Step2 from './step2'
import Step3 from './step3'
import Step3Continuo from './step3_continuo'
import { useTranslation } from 'react-i18next'
import { ICategoria } from '../../interfaces/produto/categoria'

export default function AssistenteMainColacril(props: {categorias: ICategoria[]}) {

    const formRef = useRef<HTMLFormElement>(null);
    
    const [branco, setBranco] = useState<string>();
    const [type, setType] = useState<string>();
    const [format, setFormat] = useState<string>();
    const [rolos, setRolos] = useState<number>(0);

    const handleSelectColor = (isWhite: string) => {
        setBranco(isWhite)
        handleNext()
    }

    const handleSelectType = (selected: string) => {
        setType(selected)
        handleNext()
    }

    const handleSelectFormat = (selected: string) => {
        setFormat(selected)
        setRolos(0) 
    }

    const handleSelectRolos = (selected: number) => {
        setRolos(selected)
        setFormat(undefined)
    }

    const steps = [
        {
            label: 'Selecione a cor que deseja',
            component: 
                <Step1
                    handleSelect={handleSelectColor}
                />,
        }, {
            label: 'Escolha o tipo desejado',
            component:
                <Step2
                    handleSelect={handleSelectType}

                />,
        }, {
            label: 'Escolha a forma desejada',
            component: type !== 'Continuo' ?
                <Step3
                    handleSelect={handleSelectFormat}

                /> :
                <Step3Continuo
                    handleSelect={handleSelectRolos}
                />
                ,
        }
    ]

    const [activeStep, setActiveStep] = useState<number>(0);
    const maxSteps = steps.length;

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const { t } = useTranslation('assistenteBusca')

    return (
        <div className='assistant-container'>
            <br />

            <Stepper
                activeStep={activeStep}
                alternativeLabel
                className='stepper'
            >
                {steps.length !== 0 ? steps.map((step, index) => (
                    <Step key={index}>
                        <StepLabel></StepLabel>
                    </Step>
                )) : ''}
            </Stepper>
            
            <form action="/pesquisa" method='get' ref={formRef}>
                {steps.length !== 0 ? steps[activeStep].component : ''}
                <input type='hidden' value={branco} name="branco" />
                <input type='hidden' value={type} name="categoria" />
                {rolos === 0 ? 
                    <input type='hidden' value={format} name="formato" />
                :
                    <input type='hidden' value={rolos} name="rolos" />
                }
                
                
            </form>
            
            {activeStep !== 0 ?
            <div className="assistant-main-button-container">
                <Button
                    variant="outlined"
                    className="btn btn-outlined-secondary btn-assistant"
                    onClick={handleBack}
                    disabled={activeStep === 0}
                >
                    {t("VOLTAR")}
                </Button>

                <Button
                    variant='outlined'
                    className="btn btn-outlined-secondary btn-assistant"
                    onClick={handleNext}
                    disabled={activeStep === maxSteps - 1}                      
                >
                    {t("PRÓXIMO")}
                </Button>
            </div>
            : "" }
        </div>
    )
}