import { PropertyContext } from "@beontag/tageditor";
import { Popper } from "@mui/material";
import * as properties from "./properties";
import Draggable from 'react-draggable';
import React from "react";

function PropertyBar(props: { handleOpen: null | HTMLElement }) {

    return (
        <div>
            <Draggable cancel=".not-draggable" handle=".handle" defaultClassName="draggable-default-cursor">
                {/* @ts-ignore */}
                <Popper open={props.handleOpen != null} className="property-bar-container">
                    <PropertyContext componentCollection={properties} />
                </Popper>
            </Draggable>
        </div>
    );
}

export default PropertyBar;