import api from "../api";
import { IFormFaleConosco } from "../interfaces/form/form-fale-conosco";
import { IFormTrabalheConosco } from "../interfaces/form/form-trabalhe-conosco";


const submitFaleConosco = async function(dados:IFormFaleConosco, baseURL: string) {
    var formData = new FormData();
    
    if (dados.assunto !== null) {
        formData.append("Assunto", dados.assunto);
    }

    if (dados.nome !== null) {
        formData.append("Nome", dados.nome)
    }

    if (dados.email !== null) {
        formData.append("Email", dados.email);
    }

    if (dados.telefone !== null) {
        formData.append("Telefone", dados.telefone);
    }

    if (dados.pais !== null) {
        formData.append("Pais", dados.pais);
    }

    if (dados.estado !== null) {
        formData.append("Estado", dados.estado);
    }

    if (dados.cidade !== null) {
        formData.append("Cidade", dados.cidade);
    }

    if (dados.mensagem !== null) {
        formData.append("Mensagem", dados.mensagem);
    }

    if (dados.checkboxTermos !== null) {
        formData.append("Termos", dados.checkboxTermos.toString());
    }
    
    if (dados.checkboxContatoTelefone !== null && dados.checkboxContatoEmail !== null) {
        let value: number = 0;
        if (dados.checkboxContatoEmail)
            value = value + 1;
        if (dados.checkboxContatoTelefone)
            value = value + 2;
        formData.append("Contato", value.toString());
    }

    if (dados.area !== null) {
        formData.append("Area", dados.area);
    }

    return api.post(`${baseURL}contato`, formData);
}


const submitTrabalheConosco = async function(dados:IFormTrabalheConosco, baseURL: string) {
    var formData = new FormData();

    if (dados.nome !== null) {
        formData.append("Nome", dados.nome)
    }

    if (dados.email !== null) {
        formData.append("Email", dados.email);
    }

    if (dados.telefone !== null) {
        formData.append("Telefone", dados.telefone);
    }

    if (dados.pais !== null) {
        formData.append("Pais", dados.pais);
    }

    if (dados.estado !== null) {
        formData.append("Estado", dados.estado);
    }

    if (dados.cidade !== null) {
        formData.append("Cidade", dados.cidade);
    }

    if (dados.curriculo != null) {
        formData.append("Curriculo", dados.curriculo);
    }

    if (dados.mensagem !== null) {
        formData.append("Mensagem", dados.mensagem);
    }

    if (dados.checkboxTermos !== null) {
        formData.append("Termos", dados.checkboxTermos.toString());
    }
    
    if (dados.checkboxContatoTelefone !== null && dados.checkboxContatoEmail !== null) {
        let value: number = 0;
        if (dados.checkboxContatoEmail)
            value = value + 1;
        if (dados.checkboxContatoTelefone)
            value = value + 2;
        formData.append("Contato", value.toString());
    }

    if (dados.area !== null) {
        formData.append("AreaTrabalho", dados.area);
    }

    return api.post(`${baseURL}contato`, formData);
}


const contatoService = {
    submitFaleConosco,
    submitTrabalheConosco,
}


export default contatoService;
    