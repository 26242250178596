export const duvidas = [
	{
		tituloCategoria: "Dúvidas a respeito do software de impressão",
		duvidas: [
			{
				titulo: "1 - Quais os requisitos para baixar os software de impressão Colacril Office?",
				subtitulo: "É necessário possuir Microcomputador Padrão PC compatível com Microsoft Windows nas versões: Vista, 7, 8.0/8.1 e 10."
			}, {
				titulo: "2 - Como girar a etiqueta ou texto?",
				subtitulo: "Em alguns casos, o tamanho do papel permite que a etiqueta seja utilizada em outra orientação (paisagem ou retrato) e é possível configurar a orientação através do botão “Inverter Orientação”, que fica na página inicial."
			}, {
				titulo: "3 - Quais são os tipos de arquivos são compatíveis com a opção de mala direta?",
				subtitulo: "O software de impressão da Colacril Office suporta os arquivos criados através do OpenOffice ou do Microsoft Excel e que contenham a extensão XLS (Formato Excel) e XLSX (Formato do Excel 2007 ou superior)."
			}, {
				titulo: "4 - Como fazer mala direta, ou seja, importar uma planilha para o software de impressão?",
				subtitulo: "Para essa opção, clique no botão Mala Direta disponível na barra de ferramentas. Selecione o arquivo, que  será exibido na janela de seleção de arquivos e clique em abrir. Depois, seus campos estarão disponíveis no menu exibido ao clicar no botão Mala Direta. Selecione a área da etiqueta na qual deseja inserir o campo de mala direta. Durante a edição de um trabalho de etiquetas não será exibido o conteúdo do arquivo selecionado e sim um identificador da coluna que foi selecionada."
			}, {
				titulo: "5 - Como fazer número sequencial no software de impressão Colacril Office?",
				subtitulo: "Não há essa opção no Software de Impressão Colacril"
			}, {
				titulo: "6 - Como fazer código de barras?",
				subtitulo: "Não há essa opçãoo no Software de Impressão Colacril."
			}, {
				titulo: "7 - Qual procedimento que devo fazer ao ocorrer o erro a seguir: 'Ocorreu uma exceção sem tratamento no aplicativo. Se você clicar em Continuar, o aplicativo ignorará esse erro e tentará continuar. Se clicar em encerrar, o aplicativo é encerrado imediatamente. Clicando em continuar a etiqueta não abre'?",
				subtitulo: "Um erro de compatibilidade da Microsoft com o sistema operacional é possível que tenha ocorrido algum erro na instalação do software ou algo parecido. Orientamos o usuário a desisntalar e instalar o Software novamente."
			}, {
				titulo: "8 - O Software de impressão é compatível com MAC?",
				subtitulo: "Não é compativel com MAC."
			}
		],
	}, {
		tituloCategoria: "Dúvidas a respeito do software dos produtos",
		duvidas: [
			{
				titulo: "1 - Onde compro os produtos Colacril Office?",
				subtitulo: "Para os varejos, por favor, entrar em contato com a nossa Central de Atendimento ao Cliente.  Para os consumidores, os nossos produtos podem ser encontrados nas papelarias distribuidas pelo Brasil. Caso tenha dúvidas, por favor, entrar em contato via telefone: +55 44 3518-3500 ou colacriloffice.sac@beontag.com"
			}, {
				titulo: "2 - Comprei um produto que apresenta defeito. O que fazer?",
				subtitulo: "Nesse caso, entre em contato conosco e iremos abrir uma ficha de reclamação para análise detalhada do que ocorreu com o produto e, assim, fornecer uma resposta mais precisa a respeito. Entre em contato com nossa central de atendimento por telefone +55 44 3518-3500 ou colacriloffice.sac@beontag.com"
			}, {
				titulo: "3-A tinta da impressora não adere a etiqueta da Colacril Office. O que fazer?",
				subtitulo: "Conforme especificações na embalagem, solicitamos que as etiquetas sejam guardadas em locais específicos, longe de calor e umidade. Caso esteja imprimindo e o toner não esteja aderindo, pode ser que sua etiqueta tenha sido exposta a umidade. Neste caso efetue o seguinte procedimento: Abra um novo documento em Word, deixe-o em branco, sem qualquer conteúdo, posicione uma folha de etiquetas na impressora, e dê o comando para imprimir. Efetue este procedimento duas vezes com a mesma folha, assim ocorrerá o processo de aquecimento da mesma. Após, na folha de etiquetas que efetuamos o aquecimento imprima o seu conteúdo."
			}, {
				titulo: "4-Porque minha impressão sempre sai errada com a margem cortada ou uma área em branco?",
				subtitulo: "No momento da impressão é necessário verificar o tamanho do papel utilizado; No botão 'Imprimir', clique em  'Propriedades ou Propriedades da Impressão'; Selecione a guia 'Papel'; Verifique o tamanho do papel que aparece; Modifique o tamanho de acordo com a sua folha de etiquetas A5, A4 ou Carta (verificar na embalagem da etiqueta); Clique em 'ok' para fechar as janelas e imprima seu arquivo."
			}, {
				titulo: "5-Quais os fatores que influenciam a impressão?",
				subtitulo: "Tamanho do papel. Caso esteja com dúvidas em relação ao tamanho comprado, verifique a embalagem. Se tiver dúvidas de como alterar o tamanho do papel, faça o seguinte procedimento: No momento da impressão é necessário verificar o tamanho do papel utilizado; No botão 'Imprimir', clique em  'Propriedades ou Propriedades da Impressão'; Selecione a guia 'Papel'; Verifique o tamanho do papel que aparece; Modifique o tamanho de acordo com a sua folha de etiquetas A5, A4 ou Carta (verificar na embalagem da etiqueta); Clique em 'ok' para fechar as janelas e imprima seu arquivo."
			}, {
				titulo: "6 - Onde localizo no site os gabaritos das etiquetas?",
				subtitulo: "A Colacril Office no momento não disponibiliza os gabaritos. Disponibiliza o Software de Impressão para elaboração das etiquetas."
			}, {
				titulo: "7-Minha etiqueta está descolando o que fazer?",
				subtitulo: "Nesse caso, entre em contato conosco e iremos abrir uma ficha de reclamação para análise detalhada do que ocorreu com o produto e, assim, fornecer uma resposta mais precisa a respeito. Entre em contato com nossa central de atendimento por telefone +55 44 3518-3500 ou colacriloffice.sac@beontag.com"
			}, {
				titulo: "8-Onde imprimir etiquetas E-commerce?",
				subtitulo: "Geralmente, o próprio marketplace envia as informações formatadas no tamanho da etiqueta comprada."
			}, {
				titulo: "9 -As etiquetas Multiuso possui gabarito ou podem ser impressas?",
				subtitulo: "Não. As Etiquetas Multiuso não são adequadas para utilização em impressoras. São indicadas para uso manual, e por esse motivo não foi desenvolvido um programa de impressão para as mesmas."
			}
		]
	}
]