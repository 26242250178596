import { Trans, useTranslation } from "react-i18next";
import AltText from "../../components/common/altText";
import React from "react";

export default function TelaAgradecimento() {
	const { t } = useTranslation('contato')

    return (
        <div className="tela-agradecimento-container">
            <Trans t={t} i18nKey="Sua mensagem foi enviada com sucesso!">
                <h1 className="tela-agradecimento-titulo">
                    Sua <AltText>mensagem</AltText> foi enviada com sucesso!
                </h1>
            </Trans>

            <p className="tela-agradecimento-text">
                {t("Agradecemos sua mensagem! Caso tenha alguma dúvida sobre o uso do software e/ou produtos, acesse nossa aba de dúvidas frequentes.")}
            </p>
        </div>
    )
}