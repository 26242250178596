import { Button } from "@mui/material";

import { ISubCategoria } from "../../interfaces/produto/subCategoria";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

export default function CategoriaProdutos(props: {
    subCategorias:ISubCategoria[], 
    modifySelectedCategory:Function, 
    selectedSubcategory:number, 
    handleInputChange: any,
    
}) {

    const navigate = useNavigate();

    return (
        <>  
            {props.subCategorias.map((subCategoria) => {
                return (
                    <label key={subCategoria.idCategoria}>
                        <Button
                            className={`btn btn-product-categories ${props.selectedSubcategory === subCategoria.idCategoria ? 'btn-contained-primary' : 'btn-outlined-secondary'}`}
                            variant={`${props.selectedSubcategory === subCategoria.idCategoria ? 'contained' : 'outlined'}`}
                            onClick={() => {
                                props.modifySelectedCategory(subCategoria.idCategoria)
                                navigate(`/categoria/${subCategoria.alias}`)
                            }}
                            disableElevation
                        >
                            {subCategoria.nome}
                        </Button>
                    </label>     
                )
            })}
        </>
    )
}