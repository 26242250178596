import { IContentHeaderImage } from '../../interfaces/contentHeaderImage';
import AltText from './altText';
import React from 'react';

function ContentHeaderImage(contentHeader:IContentHeaderImage) {
  return (
      <div className='content-header-container'>
        <img
          src={contentHeader.image}
          className={ `content-header-image ${contentHeader.extraImageClass ? contentHeader.extraImageClass : ''}`}
        />
        
        <div className='content-header-image-text'>
            {contentHeader.titulo ? <h1><AltText>{contentHeader.titulo}</AltText></h1> : ''}
            <h3>{contentHeader.subtitulo}</h3>
        </div>
        
        { contentHeader.children ? contentHeader.children : '' }
      </div>  
  );
}
export default ContentHeaderImage;