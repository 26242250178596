import { AddBarCodeFeature, AddBarCodeFeatures } from "@beontag/tageditor";
import { KeyboardArrowDown, QrCode } from "@mui/icons-material";
import { Button, Menu, MenuItem } from "@mui/material";
import React from "react";
import { useCallback, useState } from "react";

export default function QRBarcode(props: { setMenuAnchorEl: Function }) {

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [barCodeFeature, setBarCodeFeature] = useState<AddBarCodeFeatures>();

    const addQrCode = useCallback((e: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
        barCodeFeature?.add({
            barCodeType: 'qrcode',
            barCodeText: 'Valor do QRCode'
        });
        props.setMenuAnchorEl(e.target)
    }, [barCodeFeature]);


    return (
        <>
            <div className="menu-item">
                <Button
                    className='btn'
                    startIcon={<QrCode />}
                    endIcon={<KeyboardArrowDown />}
                    onClick={e => setAnchorEl(e.currentTarget)}
                > QR e Código de Barras
                </Button>

                <Menu
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={() => setAnchorEl(null)}
                    PaperProps={{
                        className: 'menu-dropdown'
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left'
                    }}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left'
                    }}
                    className="editor-tb-menu"
                >
                    <AddBarCodeFeature featureUpdate={(f) => setBarCodeFeature(f)}>
                        <MenuItem disableRipple className="tb-qr-barcode-menuitem" onClick={(e) => { setAnchorEl(null); addQrCode(e) }}>

                            QR e Código de Barras

                        </MenuItem>
                    </AddBarCodeFeature>
                </Menu>
            </div>
        </>
    )
}