import api from "../api";

const listFormas = async function() {

    return api.get('editor/formas');
}

const editorService = {
    listFormas,
}

export default editorService;