
import { Trans, useTranslation } from 'react-i18next';
import LiteYouTubeEmbed from 'react-lite-youtube-embed';

import logo_beontag from '../../../../assets/logo_beontag.webp'

import CarouselQuemSomos from '../../../../components/carousel-quem-somos'

import linhaSeparator from '../../../../assets/separadores/separador-pagina-duvidas.webp'
import React from 'react';

const videos = {
    "pt": "l7wsTP0_OVg",
    en: "kPptnbUYJLk",
    es: "17-KXgea1XA"
}

function VideoLoader() {
    const { i18n } = useTranslation()
    const lang = i18n.language

    if (lang == "es")
        return videos["es"]
    if (lang == "en")
        return videos["en"]
    if (lang == "pt")
        return videos["pt"]
}


function QuemSomos() {
    const { t } = useTranslation("aEmpresa")

    return (
        <>
            <CarouselQuemSomos />

            <div className='page-content empresa-content'>
                <p className='page-paragraph'>
                    {t('Somos uma marca que oferece um portfólio completo para ajudar a organizar e identificar os materiais do seu dia a dia. Dentre nossos principais produtos, destacam-se etiquetas para impressora à jato de tinta e laser, etiquetas multiuso e etiquetas para impressoras matriciais.')}
                </p>

                <p className='page-paragraph'>
                    {t('Além das etiquetas, oferecemos também itens versáteis que complementam nossa linha de produtos: filmes adesivos transparentes e coloridos, fitas adesivas, blocos adesivos e marcadores de páginas.')}
                </p>
                
                <p className='page-paragraph'>
                    {t('Com foco no mercado consumidor, contamos com uma equipe especializada que desenvolve constantemente novos produtos.')}
                </p>

                <h2 className='sub-page-title'>
                    <Trans t={t} i18nKey="Tour virtual pela nossa fábrica">
                        Tour virtual pela nossa fábrica
                    </Trans>
                </h2>

                <LiteYouTubeEmbed
                    // id="0MlDq8Tovy0"
                    id={VideoLoader() as string}
                    title="Tour pela fábrica da Colacril"
                    thumbnail='https://img.youtube.com/vi/0MlDq8Tovy0/hqdefault.jpg'
                />

                <h2 className='sub-page-title'>{t('Pesquisa e desenvolvimento')}</h2>

                <p className='page-paragraph'>
                    {t('Primando pela qualidade e pela diferenciação de tudo o que oferece ao mercado de autoadesivos, a Colacril mantém um Centro de Desenvolvimento dedicado exclusivamente a criação de produtos e novas tecnologias. De adesivos especiais a frontais diferenciados, ele permite a produção de materiais para atender com propriedade demandas específicas dos clientes.')}
                </p>

                <p className='page-paragraph'>
                    {t('Para chegar a um nível máximo de exclusividade de soluções, todos os processos são rigorosamente acompanhados por técnicos altamente treinados, dentro de um moderno laboratório, e que sempre estão preparados para oferecer assistência a essas novas aplicações.')}
                </p>
            </div>

            <img aria-hidden="true" src={linhaSeparator} className="content-separator-quem-somos"/>
            
            <div className='page-content empresa-content'>
                <img src={logo_beontag} alt="" className='quem-somos-beontag-logo'/>

                <p className='page-paragraph'>
                    {t('A holding Beontag teve início em 2011 com a fusão de duas grandes empresas com mais de 50 anos de atuação: a Colacril, indústria de autoadesivos com forte atuação de mercado na América Latina, e a RR Etiquetas, uma das maiores empresas brasileiras de conversão de etiquetas autoadesivas para varejo, indústria e serviços.')}
                </p>

                <p className='page-paragraph'>
                    {t('Nesse mesmo ano, a Beontag passou a integrar um fundo de Private Equity.')}
                </p>
                
                <p className='page-paragraph'>
                    {t('No ano seguinte, foi criada uma nova unidade de negócios do grupo responsável pela gestão de produtos com tecnologia RFID, de conversão e identificação por radiofrequência, hoje representada pela marca Beontag.')}
                </p>
                
                <p className='page-paragraph'>
                    {t('Como resultado, formou-se um grupo forte que contribui para o desenvolvimento de todo o setor ao aliar amplo portfólio de produtos a competência técnica e um modelo de gestão eficiente, contando também com espírito empreendedor e capacidade de investimento.')}
                </p>
            </div>
        </>
    );
}

export default QuemSomos;
