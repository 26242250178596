import { Box } from "@mui/material";

import Editor from "../../components/editor";
import EditorContextProvider, { useEditorContext } from "../../context/EditorContext";
import { ICategoria } from "../../interfaces/produto/categoria";
import sProduto from '../../services/produtoService'
import i18n from 'i18next'

import "../../styles/editor/main.css";

import sEditor from '../../services/editorService';
import UserContextProvider from "../../context/userContext";
import React from "react";
import { useTranslation } from "react-i18next";

import { useSearchParams } from 'react-router-dom'


function EditorPage() {
    const { i18n } = useTranslation();

    const [searchParams] = useSearchParams();
    const installPWAstring = searchParams.get('installPWA');
    const installPWA = installPWAstring !== undefined && installPWAstring === 'true';

    const editorFileExtension = process.env.REACT_APP_EDITOR_FILE_EXTENSION;

    return (
        <>
            <Box className="main-container">
                <UserContextProvider>
                    <EditorContextProvider
                        fileExtension={editorFileExtension as string}
                    >
                        <Editor
                            installPWA={installPWA}
                        />
                    </EditorContextProvider>
                </UserContextProvider>
            </Box>
        </>
    );
}

export default EditorPage;