import React from "react";
import { Route, Routes } from "react-router-dom";
import EditorPage from "./editor";
import Health from "./health";
import ColacrilSite from "./site"
import LandingPage from "./site/home";
import Faq from "./site/faq";
import Contato from "./site/contato";
import Assistente from "./site/assistente";
import PesquisaProdutos from "./site/pesquisa";
import Produto from "./site/produtos/$productId";
import Categoria from "./site/categoria";
import Downloads from "./site/downloads";
import DownloadSoftware from "./site/downloads/download_software";
import Certificados from "./site/empresa/certificados";
import Premios from "./site/empresa/premios";
import QuemSomos from "./site/empresa/quemsomos";
import RespSocioamb from "./site/empresa/resp_socioamb";
import Valores from "./site/empresa/valores";

export default function ColacrilRoutes() {
	return (
		<Routes>
			<Route path="/" element={<ColacrilSite />}>
				<Route
					index
					element={<LandingPage />}
				/>

				<Route
					path="/faq"
					element={<Faq />}
				/>

				<Route
					path="/contato"
					element={<Contato />}
				/>

				<Route
					path="/assistente"
					element={<Assistente />}
				/>

				<Route
					path="/pesquisa"
					element={<PesquisaProdutos />}
				/>

				<Route
					path="/produto/:productId"
					element={<Produto />}
				/>

				<Route
					path="/categoria/:categoria"
					element={<Categoria />}
				/>

				<Route
					path="/downloads"
					element={<Downloads />}
				/>

				<Route
					path="/downloads/download_software"
					element={<DownloadSoftware />}
				/>

				<Route
					path="/empresa/certificados"
					element={<Certificados />}
				/>

				<Route
					path="/empresa/premios"
					element={<Premios />}
				/>

				<Route
					path="/empresa/quemsomos"
					element={<QuemSomos />}
				/>

				<Route
					path="/empresa/resp_socioamb"
					element={<RespSocioamb />}
				/>

				<Route
					path="/empresa/valores"
					element={<Valores />}
				/>
			</Route>

			<Route path="/editor" element={<EditorPage />} />
			<Route path="/health" element={<Health />} />
		</Routes>
	)
}