import { useState } from "react";
import { MirrorFeature, MirrorFeatures } from "@beontag/tageditor";
import MirrorHorizontal from "./MirrorHorizontal";
import MirrorVertical from "./MirrorVertical";
import React from "react";

const Mirror: React.FunctionComponent = (props) => {

    const [mirrorFeature, setMirrorFeature] = useState<MirrorFeatures>();

    return (
        <MirrorFeature featureUpdate={(f) => setMirrorFeature(f)}>
            <MirrorHorizontal mirrorFeature={mirrorFeature} />
            <MirrorVertical mirrorFeature={mirrorFeature} />
        </MirrorFeature>
    );
};

export default Mirror;
