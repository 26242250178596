import { Box } from "@mui/material";
import { Canvas, useBoardContext } from '@beontag/tageditor';
import ToolsBar from "./toolsBar";
import ActionBar from "./actionBar";
import StatusBar from "./statusBar";
import GlobalHandlers from "./globalHandlers";
import { useEffect, useState } from "react";
import EtiquetaPaginator from "./previewBox/EtiquetaEditorPages";
import { useEditorContext } from "../../../../context/EditorContext";
import DadosEtiqueta from "./DadosEtiqueta";
import React from "react";

function TagEditor() {

    const { etiquetaData } = useEditorContext();
    const { boardFile } = useBoardContext();

    useEffect(() => {
        if (etiquetaData) {
            boardFile?.import(etiquetaData).catch(error => {
                alert(error);
            });
        }
        
    }, [boardFile])

    useEffect(() => {
        if (etiquetaData) {
            boardFile?.import(etiquetaData).catch(error => {
                alert(error);
            })
        }
    }, [etiquetaData])

    return (
        <>
            <GlobalHandlers />
            <ActionBar />

            <Box className='editor-container'>
                <ToolsBar />
                <EtiquetaPaginator />
                <Canvas />
            </Box>

            <StatusBar />
            <DadosEtiqueta />
        </>
    );
}


export default TagEditor;