import { useBoardContext, usePropertyContext } from "@beontag/tageditor";
import { Checkbox, FormControlLabel, IconButton, TextField } from "@mui/material";
import { useState } from "react";

import OpenWithIcon from '@mui/icons-material/OpenWith';
import CloseIcon from '@mui/icons-material/Close';
import React from "react";

const Image: React.FunctionComponent = () => {
  const { properties, setProperty } = usePropertyContext<fabric.Image>();
  const [keepScale, setKeepScale] = useState<boolean>(true);

  const handleOnChange = (key: string, rawValue: number | string) => {
    setProperty({ [key]: rawValue });
  }

  const { board } = useBoardContext()

    const closeWindow = () => {
		board?.setActiveObjects()
	}

  return (
    <>
      <div className="property-bar-title handle">
                            Propriedades do objeto
                            <OpenWithIcon className="icon left" />
                            <IconButton onClick={() => closeWindow()} className="icon right not-draggable">
                                <CloseIcon />
                            </IconButton>
                        </div>

      <div className="property-bar-itens">
        <span>Transparência do Objeto</span>
        <TextField
          fullWidth
          className="property-box-input-width"
          type="number"
          InputProps={{ inputProps: { min: 0.1, max: 1, step: 0.1 } }}
          value={properties.opacity || 1}
          onChange={(e) => handleOnChange("opacity", e.currentTarget.value)}
          variant="outlined"
        />
      </div>

      <div className="property-bar-itens">
        <span>Altura</span>
        <TextField
          fullWidth
          className="property-box-input-width"
          type="number"
          InputProps={{ inputProps: { min: 1, max: 100, step: 1 } }}
							value={(properties.scaleY ?? 1) * 100}
							onChange={(e) => {
								const value = (+e.currentTarget.value / 100).toString();

								if (keepScale) {
									handleOnChange("scaleX", value);
								}
								handleOnChange("scaleY", value);
							}}
          variant="outlined"
        />
      </div>

      <div className="property-bar-itens">
        <span>Largura</span>
        <TextField
          fullWidth
          className="property-box-input-width"
          type="number"
          InputProps={{ inputProps: { min: 1, max: 100, step: 1 } }}
							value={(properties.scaleX ?? 1) * 100}
							onChange={(e) => {
								const value = (+e.currentTarget.value / 100).toString();

								if (keepScale) {
									handleOnChange("scaleY", value);
								}
								handleOnChange("scaleX", value);
							}}
          variant="outlined"
        />
      </div>

      <div className="property-bar-itens">
        <span>Manter proporções</span>
        <FormControlLabel
          value={properties.scaleY || 1}
          control={<Checkbox
            onChange={e => setKeepScale(e.currentTarget.checked)}
            checked={keepScale}
          />}
          label={""}
        />
      </div>
    </>
  );
};

export default Image;
