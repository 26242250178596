import { Box, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import React from "react";

export default function TabelaProdutosEditor(props: {
    handleProductFiltering: Function,
    selecionarProduto: Function
}) {

    const { handleProductFiltering, selecionarProduto } = props;

    return (
        <Box>
            <TableContainer className="table-categoria-produtos" style={{ height: '72vh' }}>
                <Table className='categoria-produtos' stickyHeader>
                    <TableHead>
                        <TableRow>
                            <TableCell>Código</TableCell>
                            <TableCell>Tamanho</TableCell>
                            <TableCell>Nome e Formato</TableCell>
                            <TableCell>Etiquetas por Folha</TableCell>
                            <TableCell align="center"> </TableCell>
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        {
                            handleProductFiltering().map((produto, index) => {
                                return (
                                    <TableRow key={index}>
                                        <TableCell>
                                            {
                                                produto.items.map((item, index) => {
                                                    return <Typography
                                                        key={index}
                                                        variant='body2'
                                                        sx={{
                                                            fontWeight: 'bold'
                                                        }}
                                                    >
                                                        {item.codigo}
                                                    </Typography>;
                                                })
                                            }
                                        </TableCell>

                                        <TableCell> {produto.dimensao} </TableCell>
                                        <TableCell> {produto.nome} - {produto.forma} </TableCell>
                                        <TableCell>
                                            <img
                                                alt={produto.nome}
                                                src={produto.imagens[0]}
                                                className='produto-imagem-etiquetas'
                                            />
                                        </TableCell>
                                        <TableCell align="center">
                                            <Button
                                                className="btn btn-outlined-secondary btn-contained-primary-on-hover"
                                                onClick={() => selecionarProduto(produto)}
                                            >
                                                Criar Etiqueta
                                            </Button>
                                        </TableCell>
                                    </TableRow>
                                );
                            })
                        }
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    )
}