import { useState } from 'react'

import Navbar from './navbar'
import { Link } from 'react-router-dom';

import colacril_logo from '../../assets/logo_colacril_horizontal.webp';
import SearchBar from '../pesquisa/search-bar';

import MenuRoundedIcon from '@mui/icons-material/MenuRounded';
import MenuProdutos from './menu-produtos'
import { ClickAwayListener } from '@mui/material'
import NavbarMobileColacril from './navbarMobile'
import { ICategoria } from '../../interfaces/produto/categoria'

import CloseIcon from '@mui/icons-material/Close';
import LanguageSelector from './language-selector'

import useMediaQuery from '@mui/material/useMediaQuery';
import { createTheme } from '@mui/material/styles'
import React from 'react';


export default function Header(props: {setHasOpenMenu:Function, categorias:ICategoria[]}) {


    const theme = createTheme();

    const [showBottomBar, setShowBottomBar] = useState<boolean>(false);
    const [showMenuProdutos, setShowMenuProdutos] = useState<boolean>(false);
    const [showMenuEmpresa, setShowMenuEmpresa] = useState<boolean>(false);

    const [openNavbar, setOpenNavbar] = useState<boolean>(false);

    const matches = useMediaQuery(theme.breakpoints.down('lg'));

    const handleMenuProdutos = () => {
        setShowMenuProdutos(!showMenuProdutos)
        setShowBottomBar(!showMenuProdutos)
        props.setHasOpenMenu(!showMenuProdutos)
        setShowMenuEmpresa(false)
    }

    const handleMenuEmpresa = () => {
        setShowMenuEmpresa(!showMenuEmpresa)
        setShowBottomBar(!showMenuEmpresa)
        props.setHasOpenMenu(!showMenuEmpresa)
        setShowMenuProdutos(false)
    }

    const handleCloseMenus = () => {
        setShowMenuProdutos(false)
        setShowMenuEmpresa(false)
        setShowBottomBar(false)
        props.setHasOpenMenu(false)
        setOpenNavbar(false)
    }

    const handleOpenNavbar = () => {
        setOpenNavbar(!openNavbar)

    }

    return (
        <ClickAwayListener onClickAway={() => matches ? '' : handleCloseMenus()}>
            <header>
                <div className={`header header-dropdown-${showBottomBar ? 'active': 'inactive'} ${openNavbar ? 'header-mobile-navbar' : ''}`}>
                    <div className='header-menu-logo-container'>
                        <Link to="/">
                            <img alt="" src={colacril_logo} />
                        </Link>

                        {matches ?
                            openNavbar ? 
                                <CloseIcon
                                    className='header-menu-icon'
                                    onClick={() => handleOpenNavbar()}
                                />
                            :
                                <MenuRoundedIcon
                                    className='header-menu-icon'
                                    onClick={() => handleOpenNavbar()}
                                />
                        : ''}
                    </div>              

                    <Navbar
                        handleMenuEmpresa={handleMenuEmpresa}
                        handleMenuProdutos={handleMenuProdutos}
                        showMenuEmpresa={showMenuEmpresa}
                        handleCloseMenus={handleCloseMenus}
                    />

                    {openNavbar ? 
                        <NavbarMobileColacril
                            active={openNavbar}
                            categorias={props.categorias}
                            handleCloseMenus={handleCloseMenus}
                        />
                    : <SearchBar />}

                    <LanguageSelector />
                </div>

                {showMenuProdutos && 
                    <MenuProdutos
                        handleCloseMenus={handleCloseMenus}
                        categorias={props.categorias}
                    />
                }
            </header>
        </ClickAwayListener>
    )
}