import React, { useState } from 'react';
import { Button, Checkbox, DialogProps, FormControlLabel, FormGroup, Grid, InputAdornment, TextField } from '@mui/material';
import InputMask from "react-input-mask";
import { useConfigContext } from '../../context/configContext';
import { IFormFaleConosco } from '../../interfaces/form/form-fale-conosco';
import { Trans, useTranslation } from 'react-i18next';
import ModalMensagensGerais from './modalMensagensGerais';
import PoliticaDePrivacidade from './politicaDePrivacidade';
import contatoService from '../../services/contatoService';

function FaleConosco(props: { modifyActiveComponent: Function }) {

	const { t } = useTranslation("contato")

	const [validEmail, setValidEmail] = useState<boolean>(true);
	const [validTelefone, setValidTelefone] = useState<boolean>(true);

	const [open, setOpen] = React.useState(false);
	const [openTP, setOpenTP] = React.useState(false);

	const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

	const clientConfig = useConfigContext();

	const handleOpen = () => setOpen(true);
	const handleClose = () => setOpen(false);

	const handleOpenTP = () => setOpenTP(true);
	const handleCloseTP = () => setOpenTP(false);

	const [scroll, setScroll] = React.useState<DialogProps['scroll']>('paper');

	const [form, setForm] = useState<IFormFaleConosco>({
		assunto: '',
		nome: '',
		email: '',
		telefone: '',
		pais: '',
		estado: '',
		cidade: '',
		mensagem: '',
		checkboxTermos: false,
		checkboxContatoTelefone: false,
		checkboxContatoEmail: false,
		area: '',
	});

	const [errors, setErrors] = useState({
		assunto: false,
		nome: false,
		estado: false,
		cidade: false,
		mensagem: false,
		pais: false,
	})

	

	function validateFields() {
		let errorExists = false;

		setErrors({
			assunto: false,
			nome: false,
			estado: false,
			cidade: false,
			mensagem: false,
			pais: false,
		})

		if (!form.assunto) {
			setErrors(prevErrors => ({ ...prevErrors, assunto: true }))
			errorExists = true;
		}

		if (!form.nome) {
			setErrors(prevErrors => ({ ...prevErrors, nome: true }))
			errorExists = true;
		}

		if (!form.estado) {
			setErrors(prevErrors => ({ ...prevErrors, estado: true }))
			errorExists = true;
		}

		if (!form.cidade) {
			setErrors(prevErrors => ({ ...prevErrors, cidade: true }))
			errorExists = true;
		}

		if (!form.pais) {
			setErrors(prevErrors => ({ ...prevErrors, pais: true }))
			errorExists = true;
		}

		if (!form.mensagem) {
			setErrors(prevErrors => ({ ...prevErrors, mensagem: true }))
			errorExists = true;
		}

		return errorExists;
	}

	function validation(fieldName: string, value: string) {
		if (fieldName == 'email') {
			//Responsável por verificar se o e-mail é válido
			var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

			if (value.match(validRegex)) {
				setValidEmail(true)
				return true;
			} else {
				setValidEmail(false)
				return false;
			}
		} else if (fieldName == 'telefone') {
			//Responsável por verificar se o telefone é válido
			var validRegex = /(?:\(([1-9][0-9])\)?\s?)?(?:((?:9\d|[2-9])\d{3})\-?(\d{4}))$/;

			if (value.match(validRegex)) {
				setValidTelefone(true)
				return true;
			} else {
				setValidTelefone(false)
				return false;
			}
		}
	}

	const handleInputChange = (e: any) => {
		setForm({ ...form, [e.target.name]: e.target.value });
	};

	const handleCheckboxChange = (e: any) => {
		setForm({ ...form, [e.target.name]: e.target.checked });
	};

	function eventoSubmit(e: React.FormEvent<HTMLFormElement>) {
		e.preventDefault()
		let validationError = validateFields()
		if (!validationError) {
			setIsSubmitting(true)
			contatoService.submitTrabalheConosco(form, clientConfig.apiUrl).then((resp) => {
				setIsSubmitting(false)
				props.modifyActiveComponent("submited")
			}).catch((err) => {
			})
		}
	}

	return (
		<div className='form-contato-container'>
			{/*Parágrafo principal*/}
			<p className='form-contato-initial-paragraph'>
				{t("Este espaço é um canal de contato direto entre vocês e a Colacril Office para envio de comentários, sugestões ou dúvidas sobre nosso portfólio. Preencha o formulário e aguarde o retorno da nossa equipe de atendimento.")}
			</p>

			<form onSubmit={(e) => eventoSubmit(e)}>
				{/*Início dos inputs do formulário*/}
				<Grid container spacing={2}>
					{/*Nome*/}
					<Grid item xs={12}>
						<TextField
							fullWidth
							id="nome"
							type="text"
							name="nome"
							aria-label="Nome: "
							variant="outlined"
							onChange={handleInputChange}
							error={errors.nome}
							//required
							InputProps={{
								startAdornment: (
									<InputAdornment position="start" disablePointerEvents>
										<span className='form-label'>
											{t("Nome: ")}
										</span>

									</InputAdornment>
								),
							}}
						/>
					</Grid>

					{/*E-mail e telefone*/}
					<Grid item container spacing={1}>
						<Grid item xs={12} sm={8}>
							<TextField
								fullWidth
								id="email"
								type="email"
								name="email"
								aria-label="E-mail: "
								variant="outlined"
								onChange={handleInputChange}
								error={!validEmail}
								//required
								onBlur={(e) => validation("email", form.email)}
								InputProps={{
									startAdornment: (
										<InputAdornment position="start" disablePointerEvents>
											<span className='form-label'>
												{t("E-mail: ")}
											</span>

										</InputAdornment>
									),
								}}
							/>
						</Grid>

						<Grid item xs={12} sm={4}>
							<InputMask
								mask={"(99) 99999-9999"}
								onBlur={(e) => validation("telefone", form.telefone)}
								onChange={handleInputChange}
							>
								{() => (
									<TextField
										fullWidth
										id="telefone"
										type="tel"
										name="telefone"
										aria-label="Telefone: "
										variant="outlined"
										//required
										error={!validTelefone}
										InputProps={{
											startAdornment: (
												<InputAdornment position="start" disablePointerEvents>
													<span className='form-label'>
														{t("Telefone: ")}
													</span>

												</InputAdornment>
											),
										}}
									/>
								)}
							</InputMask>
						</Grid>
					</Grid>

					{/*País, estado e cidade --> Grid*/}
					<Grid item container spacing={1}>
						<Grid item xs={12} sm={4}>
							<TextField
								fullWidth
								id="pais"
								type="text"
								name="pais"
								aria-label="País: "
								variant="outlined"
								onChange={handleInputChange}
								error={errors.pais}
								//required
								InputProps={{
									startAdornment: (
										<InputAdornment position="start" disablePointerEvents>
											<span className='form-label'>
												{t("País: ")}
											</span>

										</InputAdornment>
									),
								}}
							/>
						</Grid>

						<Grid item xs={12} sm={4}>
							<TextField
								fullWidth
								id="estado"
								type="text"
								name="estado"
								aria-label="Estado: "
								variant="outlined"
								onChange={handleInputChange}
								error={errors.estado}
								//required
								InputProps={{
									startAdornment: (
										<InputAdornment position="start" disablePointerEvents>
											<span className='form-label'>
												{t("Estado: ")}
											</span>

										</InputAdornment>
									),
								}}
							/>
						</Grid>

						<Grid item xs={12} sm={4}>
							<TextField
								fullWidth
								id="cidade"
								type="text"
								name="cidade"
								aria-label="Cidade: "
								variant="outlined"
								onChange={handleInputChange}
								error={errors.cidade}
								//required
								InputProps={{
									startAdornment: (
										<InputAdornment position="start" disablePointerEvents>
											<span className='form-label'>
												{t("Cidade: ")}
											</span>

										</InputAdornment>
									),
								}}
							/>
						</Grid>
					</Grid>

					{/*Assunto*/}
					<Grid item xs={12}>
						<TextField
							fullWidth
							id="assunto"
							type="text"
							name="assunto"
							aria-label="Assunto: "
							variant="outlined"
							error={errors.assunto}
							//required
							onChange={handleInputChange}
							InputProps={{
								startAdornment: (
									<InputAdornment position="start" disablePointerEvents>
										<span className='form-label'>
											{t("Assunto: ")}
										</span>

									</InputAdornment>
								),
							}}
						/>
					</Grid>

					{/*Mensagem*/}
					<Grid item xs={12}>
						<TextField
							fullWidth
							id="mensagem"
							type="text"
							name="mensagem"
							aria-label="Mensagem: "
							variant="outlined"
							multiline
							//required
							error={errors.mensagem}
							rows={6}
							className="form-contato-text-area"
							onChange={handleInputChange}
							InputProps={{
								startAdornment: (
									<InputAdornment
										className="fale-conosco-mensagem-adornment"
										position='start'
										disablePointerEvents>

										<div>{t("Mensagem: ")}</div>

									</InputAdornment>
								),
							}}
						/>
					</Grid>
					{/*Final dos inputs do formulário*/}
				</Grid>

				{/*Botões para entrar em contato*/}
				<FormGroup className='form-contato-options'>
					<p className='form-contato-options_paragraph'>{t("Podemos entrar em contato com você por: ")}</p>

					<FormControlLabel
						control={
							<Checkbox
								value='telefone'
								name='checkboxContatoTelefone'
								onChange={handleCheckboxChange}
								color='primary'
							/>
						}
						label={
							<Trans t={t} i18nKey="Telefone">
								Telefone
							</Trans>
						}
					/>

					<FormControlLabel
						control={
							<Checkbox
								value='email'
								name='checkboxContatoEmail'
								onChange={handleCheckboxChange}
							/>
						}
						label={
							<Trans t={t} i18nKey="E-mail">
								E-mail
							</Trans>
						}
					/>
				</FormGroup>

				<div className='checkbox-contato-container'>
					{/*Botões para aceitar os termos do site e política de privacidade*/}
					<div className='checkbox-contato-item'>
						<FormGroup>
							<FormControlLabel
								control={
									<Checkbox
										required
										className='fc-final-checkbox'
										name='checkboxTermos'
										onChange={handleCheckboxChange}
									/>
								}
								label={""}
							/>
						</FormGroup>

						<Trans t={t} i18nKey="Declaro aceitar os termos do site e a política de privacidade.">
							<p className='fc-final-checkbox-label'>Declaro aceitar os <b className="contact-modal-pointer" onClick={handleOpenTP}> termos do site e a política de privacidade </b>.</p>
						</Trans>
					</div>

					{/* Botão para aceitar o termo de consentimento */}
					<div className='checkbox-contato-item'>
						<FormGroup>
							<FormControlLabel

								control={
									<Checkbox
										required
										className='fc-final-checkbox'
										name='checkboxTermos'
										onChange={handleCheckboxChange}
									/>
								}
								label={""}
							/>
						</FormGroup>

						<Trans t={t} i18nKey="Declaro aceitar os termos de consentimento para tratamento dos dados.">
							<p className='fc-final-checkbox-label'>Declaro aceitar os <b className="contact-modal-pointer" onClick={handleOpen}>termos de consentimento</b> para tratamento dos dados.</p>
						</Trans>
					</div>
				</div>

				{/*Submit button*/}
				<div className='btn div-form-contato-submit-button'>
					<Button
						variant="outlined"
						className='btn btn-outlined-secondary form-contato-submit-button'
						type="submit"
						disabled={isSubmitting}
					>
						{isSubmitting ? 'Enviando...' : t("Enviar")}
					</Button>
				</div>
			</form>

			<ModalMensagensGerais
				scroll={scroll}
				handleClose={handleClose}
				open={open}
			/>

			<PoliticaDePrivacidade
				scroll={scroll}
				handleClose={handleCloseTP}
				open={openTP}
			/>
		</div>
	);
}
export default FaleConosco;