import { Grid } from '@mui/material'
import React from 'react'

import branca from '../../assets/assistant-colacril/branco_step1.webp'
import colorida from '../../assets/assistant-colacril/colorida-icon.webp'

import { useTranslation } from 'react-i18next'

export default function Step1(props: { handleSelect: Function }) {
	const { t } = useTranslation('assistenteBusca')

	return (
		<>
			<h2>{t("Selecione a cor que você deseja: ")}</h2>
			
			<Grid container className='assistant-grid-container'spacing={1}>
				<Grid item xs={12} sm={6} >
					<div className='assistant-grid-item' onClick={() => props.handleSelect(true)}>
						
						<img alt="" src={branca} />
						<p className="assistant-grid-text">{t("Branca")}</p>
					</div>
				</Grid>
				
				<Grid item xs={12} sm={6} >
					<div className='assistant-grid-item' onClick={() => props.handleSelect(false)}>
						<img alt="" src={colorida}  />
						<p className="assistant-grid-text">{t("Colorida")}</p>
					</div>
				</Grid>
			</Grid>
		</>
	)
}