import { ZoomIn as ZoomInIcon } from "@mui/icons-material";
import { Button } from "@mui/material";
import { useBoardContext } from "@beontag/tageditor";
import React from "react";

export interface ZoomInProps {
    increaseStep: number
}

const ZoomIn: React.FunctionComponent<ZoomInProps> = (props) => {
    const { boardZoom } = useBoardContext();

    const zoomIn = () => boardZoom?.set(round(boardZoom?.value + props.increaseStep));

    const highestValue = () => boardZoom?.value === boardZoom?.max;

    const round = (value: number) => {
        return Math.round((value + Number.EPSILON) * 100) / 100;
    };

    return (
        <Button 
            className='btn' 
            startIcon={<ZoomInIcon />}
            onClick={zoomIn} 
            disabled={highestValue()}
        >
            Zoom In
        </Button>
    );
};

export default ZoomIn;
