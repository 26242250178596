import AltText from '../../../components/common/altText';
import CaixaTexto from '../../../components/common/conteudo-caixa-texto';
import DetalhesProduto from '../../../components/produtos/dados-produto';
import { IProduto } from '../../../interfaces/produto/produto';

import linhaSeparator from '../../../assets/separadores/separador-pagina-duvidas.webp'
import CarouselProduto from '../../../components/produtos/carousel-produto';
import { useTranslation } from 'react-i18next';

import sProduto from '../../../services/produtoService'
import { splitter } from '../../../helpers/splitter';
import { useEffect, useState } from 'react';
import React from 'react';
import { useLocation, useParams } from 'react-router-dom';


function Produto() {

    const [produto, setProduto] = useState<IProduto | undefined>(undefined)
    let { productId } = useParams();
    const { i18n } = useTranslation();

    useEffect(() => {
        sProduto.obtemProduto(i18n.language, productId).then((resp) => {
            setProduto(resp.data)
        }).catch((err) => {
    
        })
    }, [productId])

    const { t } = useTranslation('produtos')
    const name = splitter(produto?.nome ?? '');

    return (
        <>
            {produto && 
                <>
                    <div className='product-page-header'>
                        {name ? <h1>{name[0]} <AltText>{name[1]} {produto.dimensao} { produto.espessura ? ` - ${produto.espessura}` : '' }</AltText></h1> : ''}
                        
                        
                        <div className="shape-divider-product-details-header-bottom">
                            <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                                <path d="M 741 116.23 C 291 117.43 0 27.57 -3 33 V 120 H 1200 V 59 C 1200 27.93 1186.4 119.83 741 116.23 Z" className="shape-fill"></path>
                            </svg>
                        </div>
                        
                        <img src={linhaSeparator} className="separator-linha-bottom" aria-hidden="true" />
                    </div>
                
                    <div className='page-content product-page-content'>
                        <CarouselProduto images={produto.imagens}/>
                        <h2 className='sub-page-title'>{t("Detalhes do produto: ")}</h2>
                        <p className='page-paragraph'>{produto.descricao}</p>

                        <DetalhesProduto>
                            <CaixaTexto title={t("Aplicação")}>
                                <>
                                    <p>{produto.aplicacao}</p>
                                </>
                            </CaixaTexto>
                            <CaixaTexto title={t("Dimensão")}>
                                <>
                                    {produto.dimensao}
                                </>
                            </CaixaTexto>
                            <CaixaTexto title={t("Código")}>
                                <>
                                    { produto.items?.map((item, index) => {
                                        return (
                                            <>
                                                <p key={index}><b>{item.codigo}{item.folhas ? ':' : ''} </b>{item.folhas}</p>
                                            </>
                                        )
                                    })}
                                        {/* <p> <b>{produto.codigo}{produto.folhas ? ':' : ''}</b> {produto.folhas ? produto.folhas : ''}</p> */}
                                </>
                            </CaixaTexto>
                        </DetalhesProduto>
                        
                        {/* TODO: Ajuste de margens para remover break */}
                        <br />
                    </div>
                </>
            }
        </>
        
    );
}

export default Produto;