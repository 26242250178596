import { useCallback, useState } from "react";
import { useBoardContext } from "@beontag/tageditor";
import { Button, ListItemIcon, Menu, MenuItem } from "@mui/material";
import { CloudDownload, InsertDriveFile, FileUpload } from "@mui/icons-material";
import { useEditorContext } from "../../../context/EditorContext";
import { useMessage } from "../../../context/alertContext/context";

interface IExtra {
	tagData: {
		idProduto: number
	}
}

const Open = (props: { setOpenModalMinhasEtiquetas: Function }) => {
  const { boardFile } = useBoardContext();
  const { fileExtension, setEtiqueta, subCategorias, selecionarProduto } = useEditorContext();

  const { showAlertApp } = useMessage()

  const upload = useCallback(() => {
    const inputEl = document.createElement("input");
    inputEl.accept = fileExtension;
    inputEl.type = "file";
    inputEl.hidden = true;
    inputEl.onchange = (e) => {
      const element = e.target as HTMLInputElement;
      if (element?.files?.length) {
        const reader = new FileReader();
        reader.onload = (ev) => {
          const json = ev?.target?.result as string;
          if (json) {
            try {
              const data = JSON.parse(json)
              const produtos = subCategorias.flatMap((item) => item.produtos)
              const produtoSelecionado = produtos.find(x => x.idProduto == (data.extra! as IExtra).tagData.idProduto)
              setEtiqueta(json)
              if (produtoSelecionado) {
                selecionarProduto(produtoSelecionado)
              } else {
                throw new Error('Produto não existente')
              }
            } catch (err) {
              showAlertApp('Produto não existente', 'error', 5000)
            }
          } 
        };
        reader.readAsText(element.files[0]);
      }
    };
    document.body.appendChild(inputEl); // required for firefox
    inputEl.click();
    inputEl.remove();
  }, [boardFile]);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

	const handleClose = () => {
		setAnchorEl(null)
	}

  return (
    <>
  <Button 
    className='btn'
    startIcon={
      <InsertDriveFile fontSize='inherit' />
    }
    onClick={handleClick}
  >
    Abrir
  </Button>
  <Menu
			elevation={1}
			anchorEl={anchorEl}
			open={open}
			onClose={() => setAnchorEl(null)}
			MenuListProps={{ onMouseLeave: handleClose }}
			anchorOrigin={{
				vertical: 'bottom',
				horizontal: 'right',
			}}
				transformOrigin={{
				vertical: 'top',
				horizontal: 'right',
			}}
			>
				<MenuItem onClick={upload}>
					<ListItemIcon>
						<FileUpload />
					</ListItemIcon>
					Abrir do computador
				</MenuItem>
				<MenuItem onClick={() => props.setOpenModalMinhasEtiquetas(true)}>
					<ListItemIcon>
						<CloudDownload />
					</ListItemIcon>
					Abrir da nuvem
				</MenuItem>
				
			</Menu>
  </>
  )
  ;
};

export default Open;
