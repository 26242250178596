import React, { useState } from 'react'
import { Accordion, AccordionItem, AccordionItemButton, AccordionItemHeading, AccordionItemPanel } from 'react-accessible-accordion'
import { useTranslation } from 'react-i18next';
import { Link } from "react-router-dom";
import AccordionMenuProdutos from './accordion-menu-produtos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import LanguageSelector from './language-selector';
import { ISubCategoria } from '../../interfaces/produto/subCategoria';
import { Button, Slide } from '@mui/material';

import { removeAcento } from '../../helpers/removeAcento'

export default function NavbarMobileColacril(props: {active: boolean, categorias:any, handleCloseMenus:Function}) {
    // @ts-ignore
    const [selectedSubcategoria, setSelectedSubcategoria] = useState<ISubCategoria>({idCategoria: 0, nome: '', filtros: [], produtos: []});
    const [isExpanded, setIsExpanded] = useState<boolean>(false);

    const { t } = useTranslation('header')

    return (
        <div className={`navbar-mobile-overlay-container ${props.active ? 'navbar-mobile-active' : ''}`}>
            <span className={`accordion-menu ${selectedSubcategoria.idCategoria == 0 ? 'accordion-menu-selected' : ''}`} >
                <Accordion allowZeroExpanded={true}>    
                    <AccordionItem>
                        <AccordionItemHeading>
                            <AccordionItemButton>
                                {t("Produtos")}

                                <ArrowForwardIosIcon
                                    fontSize="small"
                                    className="accordion-icon"
                                />

                            </AccordionItemButton>
                        </AccordionItemHeading>

                        <AccordionItemPanel>
                            <AccordionMenuProdutos
                                categorias={props.categorias}
                                isExpanded={isExpanded}
                                handleCloseMenus={props.handleCloseMenus}
                                setIsExpanded={setIsExpanded}
                                setSelectedSubcategoria={setSelectedSubcategoria}
                                IdSelectedSubcategoria={selectedSubcategoria.idCategoria?.valueOf()}
                            /> 
                        </AccordionItemPanel>
                    </AccordionItem>

                    <AccordionItem>
                        <AccordionItemHeading>
                            <AccordionItemButton>
                                <Link to="/downloads/download_software" onClick={() => props.handleCloseMenus()}>
                                    {t("Software de Impressão")}
                                </Link>
                            </AccordionItemButton>
                        </AccordionItemHeading>

                        <AccordionItemPanel>
                        
                        </AccordionItemPanel>
                    </AccordionItem>
                    
                    <AccordionItem>
                        <AccordionItemHeading>
                            <AccordionItemButton>
                                <Link to="/downloads" onClick={() => props.handleCloseMenus()}>
                                    {t("Downloads")}
                                </Link>
                            </AccordionItemButton>
                        </AccordionItemHeading>
                    </AccordionItem>

                    <AccordionItem>
                        <AccordionItemHeading>
                            <AccordionItemButton>
                                {t("A Empresa")}

                                <ArrowForwardIosIcon
                                    fontSize="small"
                                    className="accordion-icon"
                                />
                            </AccordionItemButton>
                        </AccordionItemHeading>
                        
                        <AccordionItemPanel>
                            <ul>
                                <li>
                                    <Link to="/empresa/quemsomos" onClick={() => props.handleCloseMenus()}>
                                        {t("Quem somos")}
                                    </Link>
                                </li>

                                <li>
                                    <Link to="/empresa/valores" onClick={() => props.handleCloseMenus()}>
                                        {t("Valores")}
                                    </Link>
                                </li>

                                <li>
                                    <Link to="/empresa/resp_socioamb" onClick={() => props.handleCloseMenus()}>
                                        {t("Responsabilidade Ambiental")}
                                    </Link>
                                </li>

                                <li>
                                    <Link to="/empresa/certificados" onClick={() => props.handleCloseMenus()}>
                                        {t("Certificados")}
                                    </Link>
                                </li>
                            </ul>
                        </AccordionItemPanel>
                    </AccordionItem>

                    <AccordionItem>
                        <AccordionItemHeading>
                            <AccordionItemButton>
                                <Link to="/faq" onClick={() => props.handleCloseMenus()}>
                                    {t("Dúvidas frequentes")}
                                </Link>
                            </AccordionItemButton>
                        </AccordionItemHeading>
                    </AccordionItem>

                    <AccordionItem>
                        <AccordionItemHeading>
                            <AccordionItemButton >
                                <Link to="/contato" onClick={() => props.handleCloseMenus()}>
                                    {t("Contato")}
                                </Link>
                            </AccordionItemButton>
                        </AccordionItemHeading>
                    </AccordionItem>
                </Accordion>

                <LanguageSelector />
            </span>
            
            {selectedSubcategoria.idCategoria != 0 && selectedSubcategoria.filtros && selectedSubcategoria.filtros.length != 0 && 
                <span className={`accordion-filtros ${selectedSubcategoria.idCategoria == 0 ? 'accordion-filtros-selected' : ''}`}>
                    <Accordion>
                        {selectedSubcategoria.filtros.map((filtro, index) => {
                            return (
                                <AccordionItem key={index}>
                                    <AccordionItemHeading>
                                        <AccordionItemButton>
                                            POR {filtro.typeName}
                                            
                                            <ArrowForwardIosIcon
                                                fontSize="small"
                                                className="accordion-icon"
                                            />
                                        </AccordionItemButton>
                                    </AccordionItemHeading>

                                    <AccordionItemPanel>
                                        <ul>
                                            {filtro.listEnum.map((item, index2) => {
                                                return(
                                                    <li
                                                        key={index2}
                                                    >   
                                                        <Link
                                                            to={`categoria/${selectedSubcategoria.idCategoria}?${removeAcento(filtro.typeName)}=${item.value}`}
                                                            onClick={() => props.handleCloseMenus()}
                                                        >
                                                            {item.text}
                                                        </Link>
                            
                                                    </li>
                                                )
                                            })}
                                        </ul>
                                    </AccordionItemPanel>
                                </AccordionItem>
                            )
                        })}
                    </Accordion>

                    <Button
                        variant='outlined'
                        className="btn btn-outlined-primary"
                        // @ts-ignore
                        onClick={() => setSelectedSubcategoria({idCategoria: 0, nome: '', filtros: [], produtos: []})}
                    >
                        VOLTAR
                    </Button>
                </span>
            }
        </div>
    )
}