import { IconButton, InputAdornment } from "@mui/material"
import { Visibility, VisibilityOff } from "@mui/icons-material";

export default function ShowPasswordInputAdornment(props: {
    inputVisible: boolean,
    setInputVisible: Function
}) {
    
    return (
        <InputAdornment position="end">
            <IconButton
                onClick={() => props.setInputVisible(!props.inputVisible)}
            >
                {props.inputVisible ? <VisibilityOff /> : <Visibility />}
            </IconButton>
        </InputAdornment>
    )
}