import { useCallback, useEffect, useState } from "react";
import { MirrorFeature, MirrorFeatures, objectFactory, useBoardContext, useKeysCombinationHandler } from "@beontag/tageditor";
import { useClipboardContext } from "./ClipboardContext";
import { Button } from "@mui/material";
import { Clipboard2Fill, SymmetryHorizontal } from "react-bootstrap-icons";

import FlipIcon from '@mui/icons-material/Flip';
import React from "react";

const MirrorVertical = (props: {
    mirrorFeature: MirrorFeatures | undefined
}) => {

    const { board } = useBoardContext();

    return (
            <Button 
                className='btn' 
                startIcon={<FlipIcon />}
                onClick={() => props.mirrorFeature?.mirror('vertical')}
                disabled={!board?.activeObjects.length}
            > 
                Espelhamento vertical
            </Button>
    );
};

export default MirrorVertical;
