import { useEffect } from "react";
import { useBoardContext, useKeysCombinationHandler } from "@beontag/tageditor";
import { Button } from "@mui/material";
import { Redo as RedoIcon } from "@mui/icons-material";
import React from "react";

const Redo = () => {
    const { boardHistory } = useBoardContext();

    const ctrlYCount = useKeysCombinationHandler(["Control", "y"]);
    useEffect(() => {
        if (boardHistory && ctrlYCount) {
            boardHistory.redo();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ctrlYCount]);

    return (
        <Button
            className='btn' 
            startIcon={<RedoIcon />}
            onClick={boardHistory?.redo}
            disabled={!boardHistory?.canRedo}
        > 
            Refazer
        </Button>
    );
};

export default Redo;
