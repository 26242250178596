import React from 'react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next'
import { NavLink, useLocation } from 'react-router-dom'

export default function Navbar(props: { 
    handleMenuEmpresa:Function, 
    handleMenuProdutos:Function, 
    showMenuEmpresa: boolean, 
    handleCloseMenus:Function
}) {
    const { t } = useTranslation('header')

    let location = useLocation();

    const [isRouteProduto, setIsRouteProduto] = useState<boolean>(false);
    const [isRouteEmpresa, setIsRouteEmpresa] = useState<boolean>(false);


    useEffect(() => {
        if (location.pathname.includes('produtos') || location.pathname.includes('categoria')) {
            setIsRouteProduto(true)
        } else {
            setIsRouteProduto(false)
        }
        
        if (location.pathname.includes('empresa')) {
            setIsRouteEmpresa(true)
        } else {
            setIsRouteEmpresa(false)
        }
    }, [location])
    

    return (
        <div className='navbar'>
            <nav>
                <ul>
                    <li
                        onClick={() => props.handleMenuProdutos()}
                        className={isRouteProduto ? 'activeLink' : ''}
                    >
                        {t("Produtos")}
                    </li>  
                        
                    <li>
                        <NavLink
                            to ="downloads/download_software"
                            className={({isActive}) => isActive ? 'activeLink' : ''}
                            onClick={() => props.handleCloseMenus()}
                        >
                            {t("Software de Impressão")}
                        </NavLink>
                    </li>

                    <li>
                        <NavLink
                            to ="downloads"
                            className={({isActive}) => isActive ? 'activeLink' : ''}
                            onClick={() => props.handleCloseMenus()}
                            end
                        >
                            {t("Downloads")}
                        </NavLink>
                    </li>
                    
                    <div className="empresa-group">
                        <li
                            onClick={() => props.handleMenuEmpresa()}
                            className={isRouteEmpresa ? 'activeLink' : ''}
                        >
                            {t("A Empresa")}
                        </li>
                        
                        {props.showMenuEmpresa && (
                            <div className='empresa-selector'>
                                <ul>
                                    <li>
                                        <NavLink
                                            to="empresa/quemsomos"
                                            className={({isActive}) => isActive ? 'activeLink' : ''}
                                            onClick={() => props.handleMenuEmpresa()}
                                        >
                                            {t("Quem Somos")}
                                        </NavLink>
                                    </li>
                                    
                                    <li>
                                        <NavLink
                                            to="empresa/Certificados"
                                            className={({isActive}) => isActive ? 'activeLink' : ''}
                                            onClick={() => props.handleMenuEmpresa()}
                                        >
                                            {t("Certificados")}
                                        </NavLink>
                                    </li>
                                    
                                    <li>
                                        <NavLink
                                            to="empresa/resp_socioamb"
                                            className={({isActive}) => isActive ? 'activeLink' : ''}
                                            onClick={() => props.handleMenuEmpresa()}
                                        >
                                            {t("Responsabilidade Socioambiental")}
                                        </NavLink>
                                    </li>
                                    
                                    <li>
                                        <NavLink
                                            to="empresa/valores"
                                            className={({isActive}) => isActive ? 'activeLink' : ''}
                                            onClick={() => props.handleMenuEmpresa()}
                                        >
                                            {t("Valores")}
                                        </NavLink>
                                    </li>
                                </ul>
                            </div>
                        )}
                    </div>

                    <li>
                        <NavLink
                            to ="faq"
                            className={({isActive}) => isActive ? 'activeLink' : ''}
                            onClick={() => props.handleCloseMenus()}
                        >
                            {t("Dúvidas")}
                        </NavLink>
                    </li>

                    <li>
                        <NavLink
                            to ="contato"
                            className={({isActive}) => isActive ? 'activeLink' : ''}
                            onClick={() => props.handleCloseMenus()}
                        >
                            {t("Contato")}
                        </NavLink>
                    </li>
                </ul>
            </nav>            
        </div>
    )
}