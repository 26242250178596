import { useBoardContext, usePropertyContext } from "@beontag/tageditor";
import { IconButton, TextField } from "@mui/material";
import OpenWithIcon from '@mui/icons-material/OpenWith';
import CloseIcon from '@mui/icons-material/Close';
import React from "react";

const Line: React.FunctionComponent = (props) => {
  const { properties, setProperty } = usePropertyContext<fabric.Line>();

  const handleOnChange = (key: string, rawValue: string) => {
    setProperty({ [key]: rawValue });
  }

  const { board } = useBoardContext()

    const closeWindow = () => {
		board?.setActiveObjects()
	}

  return (
    <>
      <div className="property-bar-title handle">
                            Propriedades do objeto
                            <OpenWithIcon className="icon left" />
                            <IconButton onClick={() => closeWindow()} className="icon right not-draggable">
                                <CloseIcon />
                            </IconButton>
                        </div>

      <div className="property-bar-itens">
        <label htmlFor="linha-cor-tracado">Cor da Linha</label>
        <input
          className="property-bar-color-input"
          id="linha-cor-tracado"
          type="color"
          value={properties.stroke?.toString() || "#000"}
          onChange={(e) => handleOnChange("stroke", e.currentTarget.value)}
        ></input>
      </div>

      <div className="property-bar-itens">
        <span>Largura da Linha</span>
        <TextField
          fullWidth
          className="property-box-input-width"
          type="number"
          InputProps={{ inputProps: { min: 1, max: 100, step: 1 } }}
          value={properties.strokeWidth || 1}
          onChange={(e) => handleOnChange("strokeWidth", e.currentTarget.value)}
          variant="outlined"
        />
      </div>

      <div className="property-bar-itens">
        <span>Transparência da Linha</span>
        <TextField
          className="property-box-input-width"
          type="number"
          InputProps={{ inputProps: { min: 0.1, max: 1, step: 0.1 } }}
          value={properties.opacity || 1}
          onChange={(e) => handleOnChange("opacity", e.currentTarget.value)}
          variant="outlined"
        />
      </div>

      <div className="property-bar-itens">
        <span>Altura</span>
        <TextField
          fullWidth
          className="property-box-input-width"
          type="number"
          InputProps={{ inputProps: { min: 0.1, max: 1, step: 0.1 } }}
          value={properties.scaleY || 1}
          onChange={(e) => handleOnChange("scaleY", e.currentTarget.value)}
          variant="outlined"
        />
      </div>

      <div className="property-bar-itens">
        <span>Largura</span>
        <TextField
          fullWidth
          className="property-box-input-width"
          type="number"
          InputProps={{ inputProps: { min: 0.1, max: 1, step: 0.1 } }}
          value={properties.scaleX || 1}
          onChange={(e) => handleOnChange("scaleX", e.currentTarget.value)}
          variant="outlined"
        />
      </div>
    </>
  )
};

export default Line;
