import { useTranslation } from 'react-i18next';
import AltText from '../../../../components/common/altText';
import linhaSeparator from '../../../../assets/separadores/separador-pagina-duvidas.webp'
import image from '../../../../assets/header_resp_socioamb.webp'
import React from 'react';


function Valores() {
  const { t } = useTranslation('aEmpresa')

  return (
    <>
        <div className='empresa-black-bg-header-container'>
            <div className='product-category-header-text valores-header-text'>
                <h1><AltText>{t('Valores')}</AltText></h1>
            </div>

            <div className="shape-divider-product-details-header-bottom">
                <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                    <path d="M 741 116.23 C 291 117.43 0 27.57 -3 33 V 120 H 1200 V 59 C 1200 27.93 1186.4 119.83 741 116.23 Z" className="shape-fill"></path>
                </svg>
            </div>
            
            <img alt="" src={linhaSeparator} className="separator-linha-bottom" />
        </div>

        <div className='page-content empresa-content empresa-valores-content'>
            <img alt="" src={image} className="empresa-valores-image" />

            <h2 className='sub-page-title'>{t("NOSSOS PILARES")}</h2>

            <p className='page-paragraph valores-pilares-paragraph'>
                {t("Sem Fronteiras")}
            </p>

            <p className='page-paragraph valores-pilares-paragraph'>
                {t("Fazemos acontecer")}
            </p>

            <p className='page-paragraph valores-pilares-paragraph'>
                {t("Juntos")}
            </p>

            <h2 className='sub-page-title'>{t("NOSSO PROPÓSITO")}</h2>

            <p className='page-paragraph'>
                {t("Empoderar empresas, marcas e pessoas para que imprimam no mundo sua identidade única.")}
            </p>

            <h2 className='sub-page-title'>{t("NOSSA VISÃO")}</h2>

            <p className='page-paragraph'>
                {t("Seremos líderes no mercado de identificação, atuando sem fronteiras, com pensamento crítico e calor humano.")}
            </p>
        </div>
    </>
  );
}

export default Valores;
