import { IconButton, Tooltip } from "@mui/material";
import { useBoardContext } from "@beontag/tageditor";
import { Cursor, ArrowsMove } from 'react-bootstrap-icons';
import FitScreenOutlinedIcon from '@mui/icons-material/FitScreenOutlined';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';

import { Divider } from "@mui/material";
import React from "react";

const step = 0.2;

function StatusBar() {
    const { boardZoom, boardDragMode, board } = useBoardContext();

    const fitToCenter = () => {
        if (board) {
            const workspace = board.getWorkspace();
            board.fitTo(workspace);
        }
    }

    const zoomOut = () => boardZoom?.set(round(boardZoom?.value - step));

    const lowestValue = () => boardZoom?.value === boardZoom?.min;

    const zoomIn = () => boardZoom?.set(round(boardZoom?.value + step));

    const highestValue = () => boardZoom?.value === boardZoom?.max;

    const round = (value: number) => {
        return Math.round((value + Number.EPSILON) * 100) / 100;
    };

    return (
        <div className="statusbar-container drag-container">
        
            <span className="statusbar-zoom">
                {((boardZoom?.value || 1) * 100).toFixed(0)}%
                <div className="statusbar-zoom-buttons">
                    <IconButton
                        onClick={zoomIn}
                        disabled={highestValue()}
                    >
                        <AddIcon sx={{ fontSize: '10px' }} />
                    </IconButton>

                    <IconButton
                        onClick={zoomOut}
                        disabled={lowestValue()}
                    >
                        <RemoveIcon sx={{ fontSize: '10px' }} />
                    </IconButton>           
                    
                </div>
            </span>
            <Divider orientation='vertical' flexItem={true}/>
            <Tooltip title={'Centralizar na tela'}>
                <IconButton
                    onClick={fitToCenter}
                    size='large'
                    className={`cursor-button`}
                >
                    <FitScreenOutlinedIcon />
                </IconButton>
            </Tooltip>
            <Divider orientation='vertical' flexItem={true}/>

            <Tooltip title={'Mover etiqueta'}>
                <IconButton
                    onClick={() => { if (boardDragMode) { boardDragMode.mode = 'grab' }}}
                    size='large'
                    className={`cursor-button ${boardDragMode?.mode === "grab" ? "active" : ''}`}
                >
                    <ArrowsMove/>
                </IconButton>
            </Tooltip>
            <Tooltip title='Selecionar elementos'>
                <IconButton
                    onClick={() => { if (boardDragMode) { boardDragMode.mode = 'selection' }}}
                    size='large'
                    className={`cursor-button ${boardDragMode?.mode === "selection" ? "active" : ''}`}
                >
                    <Cursor/>
                </IconButton>
            </Tooltip>
        </div>
    );
}

export default StatusBar;