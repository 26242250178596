import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from "@mui/material";
import { ArrowBack, Close } from "@mui/icons-material";
import { useEditorContext } from '../../../context/EditorContext';
import { useState } from "react";

const Back = () => {
  const { limparProdutoSelecionado } = useEditorContext();
  const [openModal, setOpenModal] = useState<boolean>(false);

  return (
    <>
  <Button 
    className='btn'
    startIcon={
      <ArrowBack fontSize='inherit' />
    }
    onClick={() => setOpenModal(true)}
  >
    Voltar
  </Button>
  <Dialog
				open={openModal}
				onClose={() => setOpenModal(false)}
				scroll='paper'
				maxWidth={'md'}
			>
				<DialogTitle>
					<IconButton onClick={() => setOpenModal(false)}>
						<Close />
					</IconButton>
				</DialogTitle>
				<DialogContent dividers>
					<p>Tem certeza que deseja voltar? Seu progresso será perdido</p>
				</DialogContent>
        <DialogActions>
          <Button variant="outlined" className="btn btn-outlined-primary" onClick={() => setOpenModal(false)}>Cancelar</Button>
          <Button disableElevation variant="contained" className="btn btn-contained-primary" onClick={limparProdutoSelecionado} autoFocus>
            Voltar
          </Button>
        </DialogActions>
			</Dialog>

  </>
  )
  ;
};

export default Back;
