import React, { useState } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';

function AccordionFaq({ titulo, subtitulo }: any) {
    const [expanded, setExpanded] = useState<string | false>('');

    const handleChange = (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
        setExpanded(newExpanded ? panel : false);
    };

    return (
        <Accordion
            className="accordion-faq-duvidas"
            expanded={expanded === 'panel1'}
            onChange={handleChange('panel1')}
            elevation={0}
            square
        >
            <AccordionSummary
                expandIcon={expanded === 'panel1' ? <RemoveIcon /> : <AddIcon />}
            >
                {titulo}
            </AccordionSummary>
            
            <AccordionDetails className='accordion-faq-duvidas-details'>         
                {subtitulo}
            </AccordionDetails>
        </Accordion>
    )
}


export default AccordionFaq;