import Slider from 'react-slick'

import { BackArrow, ForwardArrow } from '../../components/common/slideshowArrows'
import IImagem from '../../interfaces/produto/imagem'

import default_image from '../../assets/produto_default.webp'
import React from 'react';

export default function CarouselProduto(props: { images: string[]}) {
	const settings = {
		dots: true,
		infinite: true,
		speed: 500,
		slidesToShow: 1,
		slidesToScroll: 1,
		//autoplay: true,
		autoplaySpeed: 5000,
		cssEase: "linear",
		pauseOnHover: true,
		arrows: true,
		nextArrow: <ForwardArrow type='carousel'/>,
		prevArrow: <BackArrow type='carousel'/>,
		appendDots: (dots: any) => (
			<div className='carousel-produto-settings-div'>
				<ul className="carousel-produto-settings-ul"> {dots} </ul>
			</div>
		),
	};

	return (
		<div className='carousel-product-super-container'>
			<Slider {...settings}>
				{props.images.map((image, index) => {
					return(
						<div className='carousel-landing-page-content-container' key={index}>
							<img alt="" src={image} className="carousel-product" />				
						</div>
					)
				})}
			</Slider>
		</div>
	)
}