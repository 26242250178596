import {
  Grid,
  TextField,
  InputAdornment,
  Button,
  Select,
  MenuItem,
  FormControl,
} from "@mui/material";
import { useState } from "react";

import sUsuario from "../../../services/usuarioService";
import { handleFormErrors } from "../../../helpers/formErrorHandler";
import { useMessage } from "../../../context/alertContext/context";
import { useTranslation } from "react-i18next";
function RequestAccess(props: { modifyActiveComponent: Function }) {
  const [form, setForm] = useState<{ [key: string]: string }>({
    email: "",
    confirmaEmail: "",
    nome: "",
    endereco: "",
    estado: "",
    cidade: "",
  });

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [errors, setErrors] = useState({
    nome: false,
    email: false,
    confirmaEmail: false,
    endereco: false,
    estado: false,
    cidade: false,
  });

  const handleInputChange = (e: any) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };
  const { i18n } = useTranslation();
  const { showAlertApp } = useMessage();

  const handleCreateAccount = async (e: any) => {
    e.preventDefault();
    if (handleFormErrors(form, errors, setErrors)) {
      return;
    }

    if (form.email !== form.confirmaEmail) {
      setErrors({ ...errors, confirmaEmail: true });
      showAlertApp(
        "Email e confirmação de email diferentes, confirme os dados",
        "error",
        5000
      );
      return;
    }

    setIsLoading(true);

    await sUsuario
      .createAccount(form)
      .then((resp) => {
        setIsLoading(false);
        showAlertApp(
          "Conta criada com sucesso, acesse seu email para obter sua senha temporária",
          "success",
          5000
        );
        props.modifyActiveComponent("login");
      })

      .catch((err) => {
        setIsLoading(false);
        showAlertApp(
          "Houve um erro ao criar a conta, verifique seus dados e tente novamente",
          "error",
          5000
        );
      });
  };

  return (
    <div className="editor-login-container">
      <div className="editor-login-title">
        <h2 className="editor-login-text">Cadastro de Solicitação de Acesso</h2>
      </div>

      <p className="editor-login-secondary-text">
        Após preencher os dados abaixo, você receberá um e-mail com um link e
        uma senha provisória para acesso ao{" "}
        <b
          className="editor-login-software-text"
          onClick={() => props.modifyActiveComponent("login")}
        >
          Software de impressão
        </b>
        .
      </p>

      <Grid container rowSpacing={2}>
        <Grid item xs={12}>
          <TextField
            //className="editor-login-authentication-fields"
            id="nome"
            type="text"
            name="nome"
            aria-label="Nome: "
            value={form.nome}
            onChange={handleInputChange}
            error={errors.nome}
            variant="outlined"
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start" disablePointerEvents>
                  <span className="form-label">Nome:</span>
                </InputAdornment>
              ),
            }}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            //className="editor-login-authentication-fields"
            id="email"
            type="email"
            name="email"
            aria-label="E-mail: "
            variant="outlined"
            value={form.email}
            onChange={handleInputChange}
            error={errors.email}
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start" disablePointerEvents>
                  <span className="form-label">E-mail:</span>
                </InputAdornment>
              ),
            }}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            //className="editor-login-authentication-fields"
            id="confirmaEmail"
            type="email"
            name="confirmaEmail"
            aria-label="Confirma e-mail: "
            value={form.confirmaEmail}
            onChange={handleInputChange}
            error={errors.confirmaEmail}
            variant="outlined"
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start" disablePointerEvents>
                  <span className="form-label">Confirme seu E-mail:</span>
                </InputAdornment>
              ),
            }}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            className="editor-request-access-endereco"
            name="endereco"
            variant="outlined"
            value={form.endereco}
            onChange={handleInputChange}
            error={errors.endereco}
            multiline
            fullWidth
            rows={3}
            InputProps={{
              startAdornment: (
                <InputAdornment
                  position="start"
                  disablePointerEvents
                  className="editor-request-access-endereco-adornment"
                >
                  Endereço (Rua, Número e Bairro):
                </InputAdornment>
              ),
            }}
          />
        </Grid>

        <Grid container item xs={12}>
          <Grid item xs={6}>
            <FormControl fullWidth>
              <Select
                className="editor-request-access-grid-itens"
                type="text"
                name="estado"
                variant="outlined"
                onChange={handleInputChange}
                value={form.estado}
                startAdornment={
                  <InputAdornment position="start" disablePointerEvents>
                    <span className="form-label">Estado:</span>
                  </InputAdornment>
                }
              >
                <MenuItem value="Acre">Acre</MenuItem>
                <MenuItem value="Alagoas">Alagoas</MenuItem>
                <MenuItem value="Amapa">Amapá</MenuItem>
                <MenuItem value="Aazonas">Amazonas</MenuItem>
                <MenuItem value="Bahia">Bahia</MenuItem>
                <MenuItem value="Ceara">Ceará</MenuItem>
                <MenuItem value="DistritoFederal">Distrito Federal</MenuItem>
                <MenuItem value="EspiritoSanto">Espírito Santo</MenuItem>
                <MenuItem value="Goias">Goiás</MenuItem>
                <MenuItem value="Maranhao">Maranhão</MenuItem>
                <MenuItem value="MatoGrosso">Mato Grosso</MenuItem>
                <MenuItem value="MatoGrossoSul">Mato Grosso do Sul</MenuItem>
                <MenuItem value="MinasGerais">Minas Gerais</MenuItem>
                <MenuItem value="Para">Pará</MenuItem>
                <MenuItem value="Paraiba">Paraíba</MenuItem>
                <MenuItem value="Parana">Paraná</MenuItem>
                <MenuItem value="Pernambuco">Pernambuco</MenuItem>
                <MenuItem value="Piaui">Piauí</MenuItem>
                <MenuItem value="RioJaneiro">Rio de Janeiro</MenuItem>
                <MenuItem value="RioGrandeNorte">Rio Grande do Norte</MenuItem>
                <MenuItem value="RioGrandeSul">Rio Grande do Sul</MenuItem>
                <MenuItem value="Rondonia">Rondônia</MenuItem>
                <MenuItem value="Roraima">Roraima</MenuItem>
                <MenuItem value="SantaCatarina">Santa Catarina</MenuItem>
                <MenuItem value="SaoPaulo">São Paulo</MenuItem>
                <MenuItem value="Sergipe">Sergipe</MenuItem>
                <MenuItem value="Tocantis">Tocantins</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={6}>
            <TextField
              //className="editor-login-cadastro-estado-cidade"
              id="cidade"
              type="text"
              name="cidade"
              aria-label="cidade: "
              value={form.cidade}
              onChange={handleInputChange}
              error={errors.cidade}
              variant="outlined"
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start" disablePointerEvents>
                    <span className="form-label">Cidade:</span>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        </Grid>

        {/* <Grid item xs={12}>
                        <div className="editor-login-cadastro-checkbox">
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        required
                                        name='checkboxTermos'
                                        checkedIcon={<CloseIcon />}
                                        value={form.checkboxTermosPrivacidade}
                                        onChange={handleCheckboxChange}
                                    />
                                }
                                label={""}
                            />
                            <p className='fc-final-checkbox-label'>Declaro aceitar os <b className="contact-modal-pointer"> termos do site e a política de privacidade </b>.</p>
                        </div>

                        <div className="editor-login-cadastro-checkbox">
                            <FormControlLabel

                                control={
                                    <Checkbox
                                        required
                                        name='checkboxTermos'
                                        checkedIcon={<CloseIcon />}
                                        value={form.checkboxTermosUsoDados}
                                        onChange={handleCheckboxChange}
                                    />
                                }
                                label={""}
                            />
                            <p className='fc-final-checkbox-label'>Declaro aceitar os <b className="contact-modal-pointer">termos de consentimento</b> para tratamento dos dados.</p>
                        </div>
                    </Grid> */}

        <Grid item xs={12}>
          <Button
            type="submit"
            variant="contained"
            className="btn btn-contained-primary"
            disableElevation
            onClick={handleCreateAccount}
          >
            Cadastrar
          </Button>
          <br />
          <br />
          <a
            href={`/documents/Eula/Termos e Condições Gerais de Uso de Software Colacril Office_${i18n.language}.pdf`}
            className="editor-login-tertiary-text"
            download
          >
            Download do contrato de licença
          </a>
          <br />
          <br />
        </Grid>
      </Grid>
    </div>
  );
}

export default RequestAccess;
