import { Paper } from "@mui/material";
import Text from "./Text";
import DirectMail from "./DirectMail";
import Numeration from "./Numeration";
import QRBarcode from "./qrbarcode";
import Images from "./Images";
import Shapes from "./Shapes";
import { createElement, useState } from "react";
import PropertyBar from "../propertyBar";
import React from "react";

function ToolsBar() {

    const [anchorEls, setAnchorEls] = useState<null[] | HTMLElement[]>(Array(8).fill(null));
    const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement | Element>(document.getElementsByClassName('canvas-container')[0] || createElement('div'));


    const handleOpenMenu = (index: number, value: HTMLElement) => {
        const anchors = Array(8).fill(null)
        anchors[index] = value;
        setAnchorEls(anchors)
    }

    return (
        <>
            <Paper className='editor-components editor-menu darken-green-color background-white' elevation={2}>

                <Shapes setMenuAnchorEl={setMenuAnchorEl} />

                <Text setMenuAnchorEl={setMenuAnchorEl} />

                <Images setMenuAnchorEl={setMenuAnchorEl} />

                <DirectMail setMenuAnchorEl={setMenuAnchorEl} />

                <Numeration setMenuAnchorEl={setMenuAnchorEl} />

                <QRBarcode setMenuAnchorEl={setMenuAnchorEl} />

            </Paper>

            {/* @ts-ignore */}
            <PropertyBar handleOpen={menuAnchorEl} />
        </>
    );
}


export default ToolsBar;