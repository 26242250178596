import { useCallback, useEffect } from "react";
import { useBoardContext, objectFactory, useKeysCombinationHandler } from "@beontag/tageditor";
import { useClipboardContext } from "./ClipboardContext";
import { Button } from "@mui/material";
import { ContentCopy } from "@mui/icons-material";
import React from "react";

const Copy = () => {
    const { board } = useBoardContext();
    const { setClipboard } = useClipboardContext();

    const copy = useCallback(() => {
        const activeObjects = board?.activeObjects;
        const canCopy = (
            activeObjects?.length
            && activeObjects.every(obj => !objectFactory.Object.isEditing(obj))
        );
        if (canCopy) {
            objectFactory.Object.clone(activeObjects, board?.activeSelection).then(clones => setClipboard(clones));
        }
    }, [board?.activeObjects, board?.activeSelection, setClipboard]);

    const CtrlCPressCount = useKeysCombinationHandler(["Control", "C"]);
    useEffect(() => {
        if (CtrlCPressCount) {
            copy();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [CtrlCPressCount]);

    return (
        <Button 
            className='btn' 
            startIcon={<ContentCopy />}
            onClick={copy}
            disabled={!board?.activeObjects.length}
        > 
            Copiar
        </Button>
    );
};

export default Copy;
