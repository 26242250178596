import { AddLineFeature, AddLineFeatures, AddRectFeature, AddRectFeatures, useBoardContext, ObjectPreviewCanvas, AddPolylineFeature, AddPolylineFeatures } from "@beontag/tageditor";
import { Category, KeyboardArrowDown } from "@mui/icons-material";
import { Button, Grid, Menu, MenuItem, Tooltip } from "@mui/material";
import React from "react";
import { useCallback, useState } from "react";
import filledLine from "~/assets/editor-colacril/filled-diagonal-line.png";
import filledRect from "~/assets/editor-colacril/filled-rect.png";
import { useEditorContext } from "../../../../../context/EditorContext";

export default function Shapes(props: { setMenuAnchorEl: Function }) {

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [polylineFeature, setPolylineFeature] = useState<AddPolylineFeatures>();

    const [newPolylineFeature, setNewPolylineFeature] = useState<AddPolylineFeatures>();

    const { formas } = useEditorContext();

    const { board, boardHistory } = useBoardContext();

    const [filterValue, setFilterValue] = useState<string>('');

    function onClickButton(e: any, dadosForma: string) {
        importObject(dadosForma)

        props.setMenuAnchorEl(e.target)

    }

    const importObject = useCallback((object: string) => {
        boardHistory?.runPaused(() => {
            const content = JSON.parse(object)
            board?.importObjects(content)
        })
    }, [board, boardHistory])

    const handleFiltering = () => {
        if (filterValue !== '') {
            return formas.filter(x => x.descricao.toLowerCase().includes(filterValue.toLowerCase()))
        }
        return formas
    }

    return (
        <>
            <div className="menu-item">

                <Button
                    className='btn'
                    startIcon={<Category />}
                    endIcon={<KeyboardArrowDown />}
                    // @ts-ignore
                    onClick={e => setAnchorEl(e.target)}
                > Formas
                </Button>

                <Menu
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={() => setAnchorEl(null)}
                    PaperProps={{
                        className: 'menu-dropdown'
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left'
                    }}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left'
                    }}
                    className="editor-tb-menu"
                >
                    <MenuItem disableRipple className="editor-tb-menu-item shapes-menu">
                        <Grid container>

                            { formas && formas.map((forma, index) => {
                                return (
                                    <AddPolylineFeature featureUpdate={(f) => setPolylineFeature(f)}>
                                        <Grid
                                            item
                                            
                                            className="editor-shapes-grid-item editor-menu-item-hover"
                                            xs={4}
                                            key={index}
                                        >
                                            <Tooltip disableInteractive title={forma.descricao}>
                                                <MenuItem>
                                                    <div className="editor-shapes-item-container" onPointerUp={(e) => onClickButton(e, forma.dadosForma)}>
                                                    <ObjectPreviewCanvas json={forma.dadosForma} scale={0.65}/>
                                                    </div>
                                                </MenuItem>
                                            </Tooltip>
                                        </Grid>
                                    </AddPolylineFeature>
                                )
                            })}

                            
                        </Grid>
                    </MenuItem>
                
                </Menu>
            </div >
        </>
    )
}