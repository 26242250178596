import { InputAdornment, TextField } from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';
import { useState } from "react";
import { useTranslation } from "react-i18next";
import React from "react";


function SearchBar() {
    const [text, setText] = useState<string>("");

    const { t } = useTranslation('header')

    return (
        <>
            <form method="get" action="/pesquisa" className="search-bar-form">
                <TextField
                    className="search-bar"
                    //placeholder={t("O que você procura?")}
                    name="pesquisa"
                    size="small"
                    value={text}
                    onChange = {(e) => setText(e.target.value)}
                    required
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchIcon />
                            </InputAdornment>
                        ),
                        endAdornment: (
                            <InputAdornment position="end">
                                <button type="submit">
                                    <span
                                        className={`button_pesquisar ${text !== "" ? 'has_text': ''}`}
                                    >
                                        OK
                                    </span>
                                </button>
                                
                            </InputAdornment>
                            
                        )
                    }}
                />
            </form>
        </>
    )
}

export default SearchBar;