import axios, { AxiosError, AxiosInstance, CancelTokenSource } from 'axios';

export type { CancelTokenSource, AxiosError };

export interface IApi extends AxiosInstance {
    getCancelationToken: () => CancelTokenSource;
}

const api: IApi = axios.create({ baseURL: process.env.REACT_APP_PROJECT_BACKEND_URL }) as any;
api.getCancelationToken = () => axios.CancelToken.source();

export default api;