import { useBoardContext } from "@beontag/tageditor";
import { Button } from "@mui/material";
import { GridOff, GridOn } from "@mui/icons-material";
import { useEffect, useState } from "react";
import React from "react";


const GridLines = () => {
    const { board } = useBoardContext();
    const [visible, setVisible] = useState<boolean>(true);

    useEffect(() => {
        if (board) {
            const workspace = board.getWorkspace();
            const gridlines = workspace.getChild("gridlines");
            setVisible(gridlines.visible === true);
        }
    }, [board]);

    const toggleVisible = () => {
        if (board) {
            const workspace = board.getWorkspace();
            const gridlines = workspace.getChild("gridlines");
            const newValue = !gridlines.visible;
            gridlines.set({ visible: newValue });
            board.requestRenderAll();
            setVisible(newValue);
        }
    }

    const buttonProps = visible ? {
        icon: <GridOff />,
        text: "Ocultar Grade"
    } : {
        icon: <GridOn />,
        text: "Mostrar Grade"
    };

    return (
        <>
            <Button
                className='btn'
                startIcon={buttonProps.icon}
                onClick={toggleVisible}
            >
                {buttonProps.text}
            </Button>
        </>
    );
};

export default GridLines;
