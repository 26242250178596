import { useCallback, useEffect, useState } from "react";
import { BoardPage, PageCanvas, useBoardContext, useKeysCombinationHandler } from "@beontag/tageditor";
import { Alert, Button, Collapse, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";

import CloseIcon from '@mui/icons-material/Close';
import PrintIcon from '@mui/icons-material/Print'
import { useEditorContext } from "../../../context/EditorContext";
import { useMessage } from "../../../context/alertContext/context";

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

const Print = () => {
	const [boardPage, setBoardPage] = useState<BoardPage>();
	const [boardPagePrint, setBoardPagePrint] = useState<BoardPage>();

	const { numberOfPages, produtoSelecionado } = useEditorContext();
	const { showAlertApp } = useMessage();

	const print = useCallback(() => {

		showAlertApp("Gerando arquivo...", 'info')
		setTimeout(() => {
			if (boardPagePrint) {	
				boardPagePrint.render()	   
				boardPagePrint.readonly = true;
				boardPagePrint.toPdf({filename: 'page'}).then(() => {
					showAlertApp("Arquivo gerado", 'success', 5000)
				});
			}
		}, 500)
    }, [boardPagePrint]);

	const printAll = useCallback(() => {

		const pages = Array.from({ length: (numberOfPages)}, (v, k) => k + 1)

		showAlertApp("Gerando arquivo...", 'info')
		setTimeout(() => {
			if (boardPagePrint) {	
				boardPagePrint.render()	   
				boardPagePrint.readonly = true;
				boardPagePrint.toPdf({filename: 'page', pages: pages }).then(() => {
					showAlertApp("Arquivo gerado", 'success', 5000)
				});;
			}
		}, 500)
	}, [boardPagePrint])

	useEffect(() => {
		if (boardPage) {
			boardPage.render();
			// Colocamos o modal em readonly para fecharmos a impressão
			setTimeout(() => {
				if (openModal) {
					boardPage.readonly = true;
				}
			}, 500)

		}
	}, [boardPage, boardPage?.readonly]);

	const ctrlPCount = useKeysCombinationHandler(["Control", "p"]);

	useEffect(() => {
		if (ctrlPCount) {
			print();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [ctrlPCount]);

	const [openModal, setOpenModal] = useState<boolean>(false);
	const [openTable, setOpenTable] = useState<boolean>(false);

	useEffect(() => {
		if (boardPage && !openModal) {
			boardPage.readonly = false;
		}
	}, [openModal])

	return (
		<>
			<Button
				className='btn'
				startIcon={
					<PrintIcon fontSize='inherit' />
				}
				onClick={() => setOpenModal(true)}
			>
				Imprimir
			</Button>
			<Dialog
				open={openModal}
				onClose={() => setOpenModal(false)}
				scroll='paper'
				maxWidth={'lg'}
				fullWidth
			>
				<DialogTitle>
					<IconButton onClick={() => setOpenModal(false)}>
						<CloseIcon />
					</IconButton>
				</DialogTitle>
				<DialogContent dividers>
					<h1 className="editor-login-authentication-text">Prévia</h1>
					{produtoSelecionado?.embalagem == "A5" &&
						<>
							<Alert
								severity='warning'
								variant="filled"
							>
								Antes de imprimir, confira as instruções da sua impressora para folhas A5
							</Alert>

							<br />

						</>
					}

					<div className="canvas-preview">
						
						{/* Temos dois canvas: Um para a prévia da etiqueta gerada, e a segunda para a que geramos o PDF de impressão */}
						<PageCanvas
							featureUpdate={f => setBoardPage(f)}
							showEdges={true}
							readonly
						/>

						<PageCanvas
							featureUpdate={f => setBoardPagePrint(f)}
							showEdges={false}
							renderless
							readonly
						/>
					</div>

					<br />

					<div className="dados-etiqueta-print-modal"> Dados da etiqueta &nbsp; <IconButton
						aria-label="expand row"
						size="small"
						onClick={() => setOpenTable(!openTable)}
					>
						{openTable ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
					</IconButton></div>
					

					<Collapse in={openTable} timeout="auto" unmountOnExit>
						<TableContainer>
							<Table className='categoria-produtos' size="small">
								<TableHead>
									<TableRow>
										<TableCell align="center" width={'50%'}>Dado</TableCell>
										<TableCell align="center" width={'50%'}>Valor (mm)</TableCell>
									</TableRow>
								</TableHead>

								<TableBody>

									<TableRow>
										<TableCell sx={{ fontWeight: 'bold' }} align="center">
											Altura da etiqueta
										</TableCell>
										<TableCell align="center">
											{produtoSelecionado?.alturaEtiqueta}
										</TableCell>
									</TableRow>
									<TableRow>
										<TableCell sx={{ fontWeight: 'bold' }} align="center">
											Largura da etiqueta
										</TableCell>
										<TableCell align="center">
											{produtoSelecionado?.larguraEtiqueta}
										</TableCell>
									</TableRow>
									<TableRow>
										<TableCell sx={{ fontWeight: 'bold' }} align="center">
											Margem inferior etiqueta
										</TableCell>
										<TableCell align="center">
											{produtoSelecionado?.margemInferiorEtiqueta}
										</TableCell>
									</TableRow>
									<TableRow>
										<TableCell sx={{ fontWeight: 'bold' }} align="center">
											Margem direita etiqueta
										</TableCell>
										<TableCell align="center">
											{produtoSelecionado?.margemDireitaEtiqueta}
										</TableCell>
									</TableRow>
									
									<TableRow>
										<TableCell sx={{ fontWeight: 'bold' }} align="center">
											Margem esquerda pagina
										</TableCell>
										<TableCell align="center">
											{produtoSelecionado?.margemEsquerdaPagina}
										</TableCell>
									</TableRow>
									<TableRow>
										<TableCell sx={{ fontWeight: 'bold' }} align="center">
											Margem superior pagina
										</TableCell>
										<TableCell align="center">
											{produtoSelecionado?.margemSuperiorPagina}
										</TableCell>
									</TableRow>
								</TableBody>
							</Table>
						</TableContainer>
					</Collapse>

					<br />
				</DialogContent>
				<DialogActions>
					<Button
						className='btn'
						startIcon={
							<PrintIcon fontSize='inherit' />
						}
						onClick={print}
					>
						Imprimir atual
					</Button>
					<Button
						className='btn'
						startIcon={
							<PrintIcon fontSize='inherit' />
						}
						onClick={printAll}
					>
						Imprimir todas
					</Button>
				</DialogActions>
			</Dialog>

		</>
	);
};

export default Print;
