import { BoardContextProvider } from "@beontag/tageditor";
import { Box, Button } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import Footer from "../../components/editor/footer";
import Header from "../../components/editor/header";
import Login from "../../components/editor/login/login";
import Steps from "../../components/editor/steps";
import { useEditorContext } from "../../context/EditorContext";
import PWAInstall from "./pwa";
import { usePWAInstall } from 'react-use-pwa-install'

import AxiosInterceptor from '../../api/interceptor'
import ModalMinhasEtiquetas from "./steps/modalMinhasEtiquetas";
import { useUserContext } from "../../context/userContext";
import React from "react";
import { useSearchParams } from "react-router-dom";

interface IEditorDimensoes {
    widthCm: number,
    heightCm: number,
    margimBottomCm: number,
    margimRightCm: number,
    borderRadiusCm: number | undefined
}

function Editor(props: { installPWA: boolean }) {

    const [searchParams, setSearchParams] = useSearchParams();
    const installPWA = searchParams.get("installPWA") === "true";

    const { produtoSelecionado } = useEditorContext();
    const { usuarioLogado } = useUserContext();
    const [loggedInUser, setLoggedInUser] = useState<boolean>(false);
    const [openPWAModal, setOpenPWAModal] = useState<boolean>(
        installPWA ?? false
      );
    const [openModalMinhasEtiquetas, setOpenModalMinhasEtiquetas] = useState<boolean>(false);

    const handleCloseModal = () => {
        if (openPWAModal) {
            setOpenPWAModal(false)
        }
    }

    useEffect(() => {
        setLoggedInUser(usuarioLogado)
    }, [usuarioLogado])

    let buttonRef = useRef(null)  

    const installPWAApp = usePWAInstall();

    let tags: IEditorDimensoes[] = [];
    let tagRepeater: number[] | undefined = undefined;

    if (produtoSelecionado && produtoSelecionado.dimensoes && produtoSelecionado.dimensoes.length > 0) {
        tags = produtoSelecionado.dimensoes.map((dimensao) => {
            let tag = {
                widthCm: dimensao.larguraEtiqueta / 10,
                heightCm: dimensao.alturaEtiqueta / 10,
                margimBottomCm: dimensao.margemInferiorEtiqueta / 10,
                margimRightCm: dimensao.margemDireitaEtiqueta / 10,
                borderRadiusCm: dimensao.curvaBordaEtiqueta
            }
            return tag
        })
        tagRepeater = [
            0,
            1,
            2,
            2,
            1,
            0
        ]
        
    } else {
        if (produtoSelecionado) {
            tags.push({
                widthCm: (produtoSelecionado?.larguraEtiqueta ?? 25.4) / 10,
                heightCm: (produtoSelecionado?.alturaEtiqueta ?? 66.8) / 10,
                margimRightCm: (produtoSelecionado?.margemDireitaEtiqueta ?? 2.5) / 10,
                margimBottomCm: (produtoSelecionado?.margemInferiorEtiqueta ?? 0) / 10,
                borderRadiusCm: produtoSelecionado.curvaBordaEtiqueta ?? 0.1
            })
        } else {
            tags.push({
                heightCm: 25.4 / 10,
                widthCm: 66.8 / 10,
                margimRightCm: 2.5 / 10,
                margimBottomCm: 0.0 / 10,
                borderRadiusCm: 0.1
            })
        }
    }


    const boardContextProps = {
        page: {
            widthCm: (produtoSelecionado?.larguraPagina ?? 210) / 10,
            heightCm: (produtoSelecionado?.alturaPagina ?? 297) / 10,
            paddingTopCm: (produtoSelecionado?.margemSuperiorPagina ?? 13.0) / 10,
            paddingLeftCm: (produtoSelecionado?.margemEsquerdaPagina ?? 5.3) / 10,
            tagRepeater
        },
        tags,
        editor: {
            initialRatio: 40
        }
    };
    

    return (
        <>
            <BoardContextProvider {...boardContextProps}>
                <AxiosInterceptor>
                    <>
                        <Header
                            setOpenPWAModal={setOpenPWAModal}
                            setOpenModalMinhasEtiquetas={setOpenModalMinhasEtiquetas}
                        />

                        <Box className='editor-content'>
                            {
                                loggedInUser ? (
                                    <Steps />
                                ) : (
                                    <Login />
                                )
                            }
                        </Box>

                        <Footer />
                        <ModalMinhasEtiquetas
                            open={openModalMinhasEtiquetas}
                            handleCloseModal={() => setOpenModalMinhasEtiquetas(false)}
                        />
                    </>
                </AxiosInterceptor>
            </BoardContextProvider>

            <button
                ref={buttonRef}
                className="open-modal-button"
                title="Open modal PWA"
            />

            <PWAInstall
                open={openPWAModal}
                handleCloseModal={handleCloseModal}
                buttonRef={buttonRef}
            >
                <Button
                    variant="contained"
                    className="btn btn-contained-primary"
                    onClick={installPWAApp}
                >
                    INSTALAR
                </Button>
            </PWAInstall>
        </>
    );
}

export default Editor;