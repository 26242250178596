import { useCallback, useEffect } from "react";
import { objectFactory, useBoardContext, useKeydownHandler } from "@beontag/tageditor";
import { Button } from "@mui/material";
import { Delete } from "@mui/icons-material";
import React from "react";

const Remove = () => {
  const { board } = useBoardContext();

  
  const canRemoveActiveObjects = useCallback(() => {
    return (
      board?.activeObjects.length &&
      board.activeObjects.every(obj => !objectFactory.Object.isEditing(obj))
      );
    }, [board?.activeObjects]);

    const removeActiveObjects = useCallback(() => {

      if(canRemoveActiveObjects()) {
        board?.remove(...board.activeObjects);
      }

    }, [board, canRemoveActiveObjects]);

  const keysPressCount = useKeydownHandler(["Delete"]);
  useEffect(() => {
    if (keysPressCount && canRemoveActiveObjects()) {
      board?.remove(...board.activeObjects);
    }
  }, [keysPressCount, canRemoveActiveObjects, board]);

  return (
    <Button 
        className='btn' 
        startIcon={<Delete />}
        onClick={removeActiveObjects}
        disabled={!board?.activeObjects.length}
    > 
        Excluir
    </Button>
  );
};

export default Remove;
