export const handleFormErrors = (
    form: {[key:string]: string | boolean},
    errorObj: {[key:string]: boolean},
    setErrorObj: Function
) => {

    let hasErrors = false;
    let localErrors = Object.assign({}, errorObj);

    Object.keys(errorObj).forEach(v => { localErrors = { ...localErrors, [v]: false} })

    const fields = Object.keys(form);

    fields.forEach(field => {
        if (form[field] == '') {
            localErrors = { ...localErrors, [field]: true }
            hasErrors = true;
        }
    });

    setErrorObj(localErrors)

    return hasErrors;

}