import jwtDecode from "jwt-decode";
import React, { useEffect } from 'react'
import { createContext, useCallback, useContext, useState } from "react"
import IEditorContext, { IProdutoEditor } from "../interfaces/context/editorContext";
import { IEtiquetaUsuario } from "../interfaces/editor/etiquetaUsuario";
import { IFormaEditor } from "../interfaces/editor/formaEditor";
import { IUserData } from "../interfaces/editor/userData";
import { ICategoria } from "../interfaces/produto/categoria";
import { ISubCategoria } from "../interfaces/produto/subCategoria";
import { useTranslation } from "react-i18next";

import sProduto from '../services/produtoService'
import sEditor from '../services/editorService'

const EditorContext = createContext<IEditorContext | undefined>(undefined);

const EditorContextProvider = (props: { children: JSX.Element, fileExtension: string }) => {

    const { i18n } = useTranslation();

    const [produtoSelecionado, setProdutoSelecionado] = useState<IProdutoEditor | undefined>(undefined);
    const [etiquetaData, setEtiquetaData] = useState<string>('');
    const [etiquetaUsuario, setEtiquetaUsuarioState] = useState<IEtiquetaUsuario | undefined>()
    const [subCategorias, setCategorias] = useState<ISubCategoria[]>([])
    const [numberOfPages, setNumberOfPages] = useState<number>(1);
    const [formas, setFormas] = useState<IFormaEditor[]>([]);

    const selecionarProduto = useCallback((produto: IProdutoEditor) => {
        setProdutoSelecionado(produto);
    }, []);

    const limparProdutoSelecionado = useCallback(() => {
        setProdutoSelecionado(undefined);
        setEtiquetaUsuarioState(undefined)
        setEtiquetaData('')
    }, []);

    const setDadosEtiqueta = useCallback((dados?: string) => {
        setEtiquetaData(dados || '')
    }, [])

    const setEtiquetaUsuario = useCallback((etiqueta: IEtiquetaUsuario) => {
        if (!etiqueta?.idProduto && etiqueta?.produto) {
            etiqueta.idProduto = etiqueta.produto.idProduto
        }
        setEtiquetaUsuarioState(etiqueta)
        setEtiquetaData(etiqueta.dadosEtiqueta)
    }, [])

    const setterNumberOfPages = useCallback((pages: number) => {
        setNumberOfPages(pages)
    }, [])

    useEffect(() => {
        let locale = i18n.language;
        sProduto.obtemCategorias(locale, true, true).then((resp) => {
            setCategorias((resp.data.categorias && resp.data.categorias.length > 0) ? resp.data.categorias[0].subCategorias : [])
        }).catch((err) => {
        });
    
        sEditor.listFormas().then((resp) => {
            setFormas(resp.data)
        }).catch((err) => {
    
        })
    }, [i18n.language])

    const data: IEditorContext = {
        produtoSelecionado,
        selecionarProduto,
        limparProdutoSelecionado,
        setEtiqueta: setDadosEtiqueta,
        etiquetaData,
        etiquetaUsuario,
        setEtiquetaUsuario,
        subCategorias,
        formas,
        numberOfPages,
        setNumberOfPages: setterNumberOfPages,
        fileExtension: props.fileExtension
    };
    
    return (
        <EditorContext.Provider value={data}>
            {props.children}
        </EditorContext.Provider>
    );
}


const useEditorContext = () => {
    const context = useContext(EditorContext);

    if (context === undefined) {
        throw new Error('useEditorContext must be used within a EditorContextProvider');
    }

    return context;
}


export {
    useEditorContext
};

export default EditorContextProvider;