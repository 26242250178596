import { Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import AltText from '../../../../components/common/altText';
import linhaSeparator from '../../../../assets/separadores/separador-pagina-duvidas.webp'

import logo_fsc from '../../../../assets/logo_fsc.webp'
import logo_iso from '../../../../assets/logo_iso.webp'

import React from 'react';


function Certificados() {
    const { t } = useTranslation('aEmpresa')

    return (
        <>
            <div className='empresa-black-bg-header-container'>
                <div className='product-category-header-text'>
                    <h1><AltText>{t('Certificados')}</AltText></h1>
                    <h3>{t('Como resultado de todo o cuidado e profissionalismo da companhia, conquistamos certificados que refletem a qualidade dos nossos produtos e a preocupação com a preservação do meio ambiente, como o ISO 9001 E ISO 14001.')}</h3>
                </div>

                <div className="shape-divider-product-details-header-bottom">
                    <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                        <path d="M 741 116.23 C 291 117.43 0 27.57 -3 33 V 120 H 1200 V 59 C 1200 27.93 1186.4 119.83 741 116.23 Z" className="shape-fill"></path>
                    </svg>
                </div>

                <img src={linhaSeparator} className="separator-linha-bottom" aria-hidden="true" />
            </div>


            <div className='page-content empresa-content'>
                <h2 className='sub-page-title'>{t('FSC')}</h2>

                <p className='page-paragraph paragraph-certificate'>
                    <div className='certificate-image-div'>
                        <img className='certificate-image' alt="" src={logo_fsc} />
                    </div>

                    <p>
                        {t('A dedicação direcionada o tempo todo à alta qualidade dos produtos e serviços, a Colacril Office é chancelada por importantes certificados. Um deles é o FSC® - Forest Stewardship Council®️.')}
                    </p>

                    <p>
                        {t('A certificação FSC® apoia o manejo florestal responsável, dessa forma a matéria-prima é extraída legalmente de florestas manejadas de modo responsável. Visando a conservação e o uso sustentável dos recursos naturais, a empresa possui a certificação FSC® de Cadeia de Custódia que garante a rastreabilidade, desde a origem com a produção da matéria-prima que sai das florestas até chegar ao consumidor final.')}
                    </p>

                    <p>
                        {t('Ao escolher um produto certificado FSC®, o consumidor está escolhendo uma empresa que respeita o meio ambiente e obedece aos padrões estabelecidos pelas normas aplicáveis. Procure por produtos certificados FSC®️.')}
                    </p>

                    <a href='/documents/certificados/certificado-fsc-portugues.pdf' download className='btn-link'>       
                        <Button
                            className='btn btn-contained-secondary'
                            variant='contained'
                            disableElevation
                        >
                            Baixar certificado FSC
                        </Button>
                    </a>
                </p>
            </div>

            <div className="second-empresa-content">
                <div className='page-content empresa-content'>
                    <h2 className='sub-page-title'>{t('ISO 9001 E ISO 14001')}</h2>

                    {/* TODO: ALTERNATIVE TEXTS */}
                    <p className='page-paragraph paragraph-certificate'>
                        <div className='certificate-image-div'>
                            <img className='certificate-image' alt="" src={logo_iso} />
                        </div>

                        <p>
                            {t('A Beontag possui certificações ISO, que demonstram o compromisso com a qualidade de nossos produtos e a preocupação com a preservação e proteção do meio ambiente.')}
                        </p>

                        <p>
                            {t('Contamos com a certificação ISO 9001, pelos programas de gestão de qualidade e melhoria contínua dos processos e a certificação ISO 14001, que define as ações para estabelecer um sistema de gestão ambiental efetivo. Ambas certificações validam a produção e comercialização dos nossos produtos, sendo estes papéis, filmes, etiquetas autoadesivas.')}
                        </p>

                        <a href='/documents/certificados/ISO9001PT2022.pdf' download className='btn-link'>       
                            <Button
                                className='btn btn-contained-secondary'
                                variant='contained'
                                disableElevation
                            >
                                Baixar certificado ISO9001
                            </Button>
                        </a>
                        <br/>
                        <br/>
                        <a href='/documents/certificados/ISO14001PT2022.pdf' download className='btn-link'>       
                            <Button
                                className='btn btn-contained-secondary'
                                variant='contained'
                                disableElevation
                            >
                                Baixar certificado ISO14001
                            </Button>
                        </a>
                    </p>
                </div>

                <div className="certificados-shape-divider-top">
                    <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                        <path d="M1200 120L0 16.48 0 0 1200 0 1200 120z" className="shape-fill"></path>
                    </svg>
                </div>
            </div>
        </>
    );
}

export default Certificados;
