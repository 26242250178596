import Button from '@mui/material/Button'
import InfoIcon from "../../assets/info-icon.webp";
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next'
import React from 'react';

export default function VisitFaq() {
    const { t } = useTranslation('homepage')

    // TODO: COLOCAR TEXTO ALT NAS IMGS
    return (
        <>
            <div className="visit-faq-container">
                <div className='visit-faq-img-container'>
                    <img alt="" className="visit-faq-image" src={InfoIcon} />
                </div>

                <div className='visit-faq-text-container'>
                    <h1 className='faq-precisando-ajuda'>
                        <b>{t("Precisando de ajuda?")}</b>
                    </h1>
                    
                    <h2 className="faq-duvidas-frequentes">{t("Visite a sessão de dúvidas frequentes")}</h2>

                    <Link to="faq" className="btn-link">
                        <Button variant='outlined' className='btn btn-outlined-primary btn-main btn-visit-faq'>{t("Saiba mais")}</Button>
                    </Link>
                </div>
            </div>
        </>
    )
}