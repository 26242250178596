import {
  Grid,
  TextField,
  InputAdornment,
  Button,
} from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import sUsuario from "../../../services/usuarioService";
import { handleFormErrors } from "../../../helpers/formErrorHandler";
import { useMessage } from "../../../context/alertContext/context";
import jwtDecode from "jwt-decode";
import { IUserData } from "../../../interfaces/editor/userData";
import { useUserContext } from "../../../context/userContext";
import ShowPasswordInputAdornment from "./showPasswordInputAdornment";

const { dependencies } = require('../../../../package.json')

function UserLogin(props: { modifyActiveComponent: Function }) {
  const [inputPasswordVisible, setInputPasswordVisible] =
    useState<boolean>(false);
  const [form, setForm] = useState({
    email: "",
    senha: "",
  });

  const appVersion = dependencies['@beontag/tageditor']

  const { showAlertApp } = useMessage();
  const { login } = useUserContext();
  const { i18n } = useTranslation();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [errors, setErrors] = useState({
    email: false,
    senha: false,
  });

  const handleInputChange = (e: any) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const handleLogin = async () => {
    if (handleFormErrors(form, errors, setErrors)) {
      return;
    }

    setIsLoading(true);
    showAlertApp("Logando...", "info");

    await sUsuario
      .login(form)
      .then((resp) => {
        const jwtPayload = jwtDecode<IUserData>(resp.headers["x-access-token"]);

        if (jwtPayload.atualizar_senha === "True") {
          setIsLoading(false);
          showAlertApp(
            "Por questões de segurança, por favor atualize sua senha",
            "warning",
            5000
          );
          props.modifyActiveComponent("criar-nova-senha");
        } else {
          setIsLoading(false);
          showAlertApp("Logado com sucesso!", "success", 5000);
          login(jwtPayload);
        }
      })

      .catch((err) => {
        setIsLoading(false);
        console.error(err.response);

        if (!err.response) {
          showAlertApp(
            "Não foi possível se conectar, tente novamente mais tarde",
            "error",
            5000
          );
          return;
        }

        if (err.response.status == 401 || err.response.status == 403) {
          showAlertApp("Dados inválidos, tente novamente", "error", 5000);
          setForm({ ...form, senha: "" });
        }
      });
  };

  return (
    <div className="editor-login-container">
      <div className="editor-login-title">
        <h2 className="editor-login-text">Software de impressão</h2>
      </div>

      <p className="editor-login-secondary-text">
        Acesse o <b>software de impressão</b> Colacril com seu e-mail e senha.
        Caso ainda não tenha realizado o cadastro,
        <b
          className="editor-login-software-text"
          role="button"
          onKeyDown={() => props.modifyActiveComponent("solicitar-acesso")}
          onClick={() => props.modifyActiveComponent("solicitar-acesso")}
        >
          clique aqui
        </b>
        para solicitar o acesso.
      </p>

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            id="email"
            value={form.email}
            type="email"
            name="email"
            onChange={handleInputChange}
            onKeyDown={(e) => {
              if (e.key == "Enter") handleLogin();
            }}
            aria-label="Email: "
            variant="outlined"
            error={errors.email}
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start" disablePointerEvents>
                  <span className="form-label">E-mail:</span>
                </InputAdornment>
              ),
            }}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            id="password"
            type={inputPasswordVisible ? "text" : "password"}
            value={form.senha}
            name="senha"
            aria-label="Senha: "
            onChange={handleInputChange}
            onKeyDown={(e) => {
              if (e.key == "Enter") handleLogin();
            }}
            onPaste={(e) => {
              e.preventDefault();
              setForm({
                ...form,
                senha: e.clipboardData.getData("Text").trim(),
              });
            }}
            variant="outlined"
            error={errors.senha}
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start" disablePointerEvents>
                  <span className="form-label">Senha:</span>
                </InputAdornment>
              ),
              endAdornment: (
                <ShowPasswordInputAdornment
                  inputVisible={inputPasswordVisible}
                  setInputVisible={setInputPasswordVisible}
                />
              ),
            }}
          />
        </Grid>

        <Grid item xs={12}>
          <Button
            disabled={isLoading}
            type="submit"
            variant="contained"
            className="btn btn-contained-secondary"
            disableElevation
            onClick={handleLogin}
            size="large"
          >
            Entrar
          </Button>

          <br />
          <br />

          <p
            role="button"
            className="editor-login-tertiary-text"
            onKeyDown={() => props.modifyActiveComponent("solicitar-acesso")}
            onClick={() => props.modifyActiveComponent("solicitar-acesso")}
          >
            Cadastrar
          </p>

          <p
            role="button"
            className="editor-login-tertiary-text"
            onKeyDown={() => props.modifyActiveComponent("esqueci-senha")}
            onClick={() => props.modifyActiveComponent("esqueci-senha")}
          >
            Esqueci minha senha
          </p>
          <a
            href={`/documents/Eula/Termos e Condições Gerais de Uso de Software Colacril Office_${i18n.language}.pdf`}
            className="editor-login-tertiary-text"
            download
          >
            Download do contrato de licença
          </a>

          <br />
          <br />
          <p style={{ fontSize: 10 }}>
              Editor version: {appVersion.substring(1)}
            </p>
          
        </Grid>
      </Grid>
    </div>
  );
}

export default UserLogin;
