import { useConfigContext } from "../context/configContext";

const Health = () => {
  const configContext = useConfigContext();

  return (
    <div>
      <p>
        <b>Backend URL:</b>&nbsp;<span>{configContext.apiUrl}</span>
      </p>
      <p>
        <b>App Version:</b>&nbsp;<span>{configContext.appVersion}</span>
      </p>
    </div>
  );
}

export default Health;