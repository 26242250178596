import api from "../api";

const login = async function(userData: { [key:string]: string}) {

    const authData = `${userData.email}:${userData.senha}`
    const base64string = `Custom ${btoa(authData)}`;

    return api.post('usuarios/autenticacao', {}, {
        headers: {
            'Authorization': base64string
        }
    });
}

const createAccount = async function(userData: { [key:string]: string | boolean}) {

    return api.post('usuarios/requestAccess', userData);
}

const recoverPassword = async function(userData: { [key:string]: string}) {

    return api.post('usuarios/resetPassword', userData)
}

const updatePassword = async function (userData: { [key:string]: string | boolean}) {

    return api.put('usuarios/passwordChange', {
        'Senha': userData.oldPassword,
        'NovaSenha': userData.newPassword,
        'ConfirmacaoNovaSenha': userData.newPasswordConfirmation
    });
}

const getUserData = async function () {

    return api.get('usuarios')
}

const updateUserData = async function (userData: { [key:string]: string | boolean}) {

    return api.put('/usuarios', userData);
}

const usuarioService = {
    login,
    createAccount,
    recoverPassword,
    updatePassword,
    getUserData,
    updateUserData
}

export default usuarioService;