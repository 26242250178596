
import api, { CancelTokenSource, IApi, AxiosError } from './api';
//import { requestAuthorization } from './interceptors/requestAuthorization';
//import { requestLog } from './interceptors/requestLog';

export type { CancelTokenSource, AxiosError };

const instance: IApi = api;

//instance.interceptors.request.use(requestAuthorization);

export default instance;